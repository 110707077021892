/* eslint-disable */
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import React from "react";
import EnhancedTableHead from "./TablaEncabezado";
import TablaToolbar from "./TablaToolBar";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Create from "@material-ui/icons/Update";
import Update from "@material-ui/icons/Create";
import { connect } from "react-redux";
import {
  SetSpinnerModal,
  SetConfirmacionModalState,
} from "reducers/actions/facturaActions";
import {
  SetProductos,
  SetProducto,
  SetOpenModalAddProducto,
  SetOpenModalUpdateProducto,
} from "reducers/actions/maestrosActions";
import { consultarProductosPorEmisorYDescripcion } from "network/Api/Producto";
import ModalAddProducto from "./ModalAddProducto";
import ModalUpdateProducto from "./ModalUpdateProducto";
import { numberFormat } from "utils/funcionesUtiles";
import { actualizarEstadoProducto } from "network/Api/Producto";

function ccyFormat(num) {
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return numberFormat(value);
}

function createData(
  nmId,
  nmIdEmisor,
  dsCodigo,
  dsDescripcion,
  nmValorUnitario,
  nmPorcentajeIva,
  nmPorcentajeInc,
  cdEstado,
  nmUnidadMedida,
  nmMarca,
  nmModelo,
  dsPrecioReferencia,
  dsTipoPrecioref,
  snManejoInventario,
  snIvaIncluido,
  cdCategoria
) {
  return {
    nmId,
    nmIdEmisor,
    dsCodigo,
    dsDescripcion,
    nmValorUnitario,
    nmPorcentajeIva,
    nmPorcentajeInc,
    cdEstado,
    nmUnidadMedida,
    nmMarca,
    nmModelo,
    dsPrecioReferencia,
    dsTipoPrecioref,
    snManejoInventario,
    snIvaIncluido,
    cdCategoria,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  footerTabla: {
    backgroundColor: "#9FA1D3",
    color: "white",
  },
});

let modal = {};

class EnhancedTable extends React.Component {
  state = {
    order: "desc",
    orderBy: "dsNombre",
    page: 0,
    rowsPerPage: 5,
    idProducto: "",
    filter: "",
  };

  componentDidMount() {
    setTimeout(() => this.Consultar(), 1000);
  }

  Consultar = () => {
    const {
      userAttributes,
      setSpinnerModal,
      setConfirmacionModalState,
    } = this.props;
    const { filter } = this.state;
    setSpinnerModal(true);
    consultarProductosPorEmisorYDescripcion({
      idEmisor: userAttributes.codigoEmisor,
      filtro: filter,
    }).then((result) => {
      if (result.data) {
        setSpinnerModal(false);
        this.llenarLista(result.data);
      } else {
        modal = {
          open: true,
          text: `Ocurrió un error consultando los productos, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.`,
          onClick: () => {
            setConfirmacionModalState({ open: false });
          },
        };
        setConfirmacionModalState(modal);
      }
    });
  };

  llenarLista = (data) => {
    const { setProductos } = this.props;
    let productos = [];
    data.map((item) => {
      productos.push(
        createData(
          item.nmId,
          item.nmIdEmisor,
          item.dsCodigo,
          item.dsDescripcion,
          item.nmValorUnitario,
          item.nmPorcentajeIva,
          item.nmPorcentajeInc,
          item.cdEstado,
          item.nmUnidadMedida,
          item.nmMarca,
          item.nmModelo,
          item.dsPrecioReferencia,
          item.dsTipoPrecioref,
          item.snManejoInventario,
          item.snIvaIncluido,
          item.cdCategoria
        )
      );
    });
    setProductos(productos);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc")
      order = "asc";
    this.setState({ order, orderBy });
  };

  handleClickDelete = (event, idProducto, state) => {
    this.setState({ idProducto: idProducto }, () => {
      modal = {
        open: true,
        text: `¿Seguro desea modificar el estado del producto?`,
        onClick: () => this.handleClickConfirmarDelete(state),
      };
      this.props.setConfirmacionModalState(modal);
    });
  };

  handleClickConfirmarDelete = (state) => {
    const { idProducto } = this.state;
    this.props.setSpinnerModal(true);
    actualizarEstadoProducto({
      nmId: idProducto,
      cdEstado: state,
    }).then((result) => {
      this.props.setSpinnerModal(false);
      if (result.data === true) {
        this.props.setConfirmacionModalState({ open: false });
        this.Consultar();
      } else {
        modal = {
          open: true,
          text: `Ocurrió un error eliminando el producto, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.`,
          onClick: () => {
            this.props.setConfirmacionModalState({ open: false });
          },
        };
        this.props.setConfirmacionModalState(modal);
      }
    });
  };

  handleClickUpdate = (event, id) => {
    const { productos, setProducto, setOpenModalUpdateProducto } = this.props;
    let producto = productos.filter((item) => item.nmId == id);
    setProducto(producto[0]);
    setOpenModalUpdateProducto(true);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleConsultarFiltro = (e) =>
    this.setState({ filter: e.target.value }, () => this.Consultar());

  render() {
    const {
      classes,
      productos,
      openModalAddProducto,
      openModalUpdateProducto,
    } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    return (
      <Paper className={classes.root}>
        <TablaToolbar handleChangeFilter={this.handleConsultarFiltro} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={productos.length}
            />
            <TableBody>
              {stableSort(productos, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={n.nmId}>
                      <TableCell>
                        <div className="form-row">
                          <div className="form-row col-md-12 d-flex justify-content-between">
                            <div className="form-group col-xl-3 mr-4">
                              {n.cdEstado === "A" ? (
                                <DeleteForever
                                  onClick={(event) =>
                                    this.handleClickDelete(event, n.nmId, "I")
                                  }
                                />
                              ) : (
                                <Create
                                  onClick={(event) =>
                                    this.handleClickDelete(event, n.nmId, "A")
                                  }
                                />
                              )}
                            </div>
                            <div className="form-group col-xl-3">
                              {n.cdEstado === "A" && (
                                <Update
                                  onClick={(event) =>
                                    this.handleClickUpdate(event, n.nmId)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {n.dsCodigo}
                      </TableCell>
                      <TableCell align="left">{n.dsDescripcion}</TableCell>
                      <TableCell align="left">{`${ccyFormat(
                        n.nmValorUnitario
                      )}`}</TableCell>
                      <TableCell align="left">{n.nmPorcentajeIva}</TableCell>
                      <TableCell align="left">{n.nmPorcentajeInc}</TableCell>
                      <TableCell align="left">
                        {n.cdEstado == "A" ? "Activo" : "Inactivo"}
                      </TableCell>
                      <TableCell align="left">{n.nmUnidadMedida}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          className={classes.footerTabla}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={productos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Página siguiente",
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        {openModalAddProducto && (
          <ModalAddProducto consultar={this.Consultar} />
        )}

        {openModalUpdateProducto && (
          <ModalUpdateProducto consultar={this.Consultar} />
        )}
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    productos: state.maestros.productos,
    userAttributes: state.factura.userAttributes,
    producto: state.maestros.producto,
    openModalAddProducto: state.maestros.openModalAddProducto,
    openModalUpdateProducto: state.maestros.openModalUpdateProducto,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setProductos: (item) => dispatch(SetProductos(item)),
  setConfirmacionModalState: (item) =>
    dispatch(SetConfirmacionModalState(item)),
  setProducto: (item) => dispatch(SetProducto(item)),
  setOpenModalAddProducto: (item) => dispatch(SetOpenModalAddProducto(item)),
  setOpenModalUpdateProducto: (item) =>
    dispatch(SetOpenModalUpdateProducto(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EnhancedTable));
