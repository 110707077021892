import React, { useMemo } from 'react';
import RSelect from 'react-select';
import RAsync from 'react-select/async';

function Select({ options = [], value, async, multi, ...rest }) {
  const objectValue = useMemo(() => {
    if (multi) {
      return options.filter((o) => {
        const valueArray = value.split(';');
        return valueArray.some((va) => va === o.value);
      });
    } else {
      return options.filter((o) => o.value === value);
    }
  }, [value]);

  return async ? (
    <RAsync value={objectValue} options={options} {...rest} />
  ) : (
    <RSelect value={objectValue} options={options} {...rest} />
  );
}

export default Select;
