/* eslint-disable */
import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { SetDatosCliente } from 'reducers/actions/facturaActions';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  handleKeyPressTextoNumeroGuion,
  handleKeyPressTexto,
  handleKeyPressTextoNumeros,
  handleKeyPressTextoDireccion,
  handleKeyPressTextoEmail,
  handleKeyPressNumeros,
} from 'utils/funcionesUtiles';
import { consultarAdquirentesPorEmisorYNombre } from 'network/Api/Adquirente';
import Async from 'react-select/async';
import SelectCiudades from 'components/Inputs/SelectCiudades';

let regEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const styles = (theme) => ({
  root: { flexGrow: 2, maxWidth: '100%' },
  fontFamily: { whiteSpace: 'nowrap', textAlign: 'left' },
  show: {
    display: 'block',
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
});

class DatosCliente extends Component {
  state = {
    tiposIdentificacion: [],
    disableControl: true,
    formControlIsError: false,
    Ciudades: [],
    tipoPersonaAdquiriente: '',
    tipoIdentificacionAdquiriente: {},
    identificacionAdquiriente: '',
    digitoVerificacionAdquirente: '',
    nombresAdquiriente: '',
    segundoNombre: '',
    primerApellido: '',
    segundoApellido: '',
    ciudadAdquiriente: {},
    direccionAdquiriente: '',
    telefonoAdquiriente: '',
    emailAdquiriente: '',
    codigoPostalAdquirente: '',
    regimenAdquirente: null,
    adquirenteResponsable: null,
    tipoOperacion: 'ESTANDAR',
    responsabilidadesFiscales: '',
    multiValue: [],
    nombreCiudad: '',
    nombreRegimen: '',
    cambiarCiudad: false,
    finalObjeto: {
      tipoPersonaAdquiriente: '',
      tipoIdentificacionAdquiriente: {},
      identificacionAdquiriente: '',
      digitoVerificacionAdquirente: '',
      nombresAdquiriente: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      ciudadAdquiriente: {},
      direccionAdquiriente: '',
      telefonoAdquiriente: '',
      emailAdquiriente: '',
      codigoPostalAdquirente: '',
      regimenAdquirente: null,
      adquirenteResponsable: null,
      tipoOperacion: 'ESTANDAR',
      responsabilidadesFiscales: '',
    },
  };

  filterAdquirente = (inputValue) => {
    return consultarAdquirentesPorEmisorYNombre({
      idEmisor: this.props.userAttributes.codigoEmisor,
      filtro: inputValue,
      estado: 'A',
    }).then((res) => {
      this.setState({ adquirentes: res.data });
      return res.data.map((i) => ({
        value: i.nmId,
        label: `${i.cdTipoIdentificacion}: ${i.dsIdentificacion}-${
          i.dsDigitoVerif
        } ${i.dsNombre} ${i.dsSegundoNombre || ''} ${i.dsPrimerApellido || ''} ${
          i.dsSegundoApellido || ''
        }`,
      }));
    });
  };

  loadOptions = (inputValue, callback) => {
    if (this.props.userAttributes.codigoEmisor) {
      this.filterAdquirente(inputValue).then((res) => callback(res));
    }
  };

  handleMultiChange = (option) => {
    let multiValue = this.state.multiValue;
    let isExistente = multiValue.filter((m) => m.value === option.value);
    let nuevasResponsabilidades =
      isExistente.length > 0
        ? multiValue.filter((m) => m.value !== option.value)
        : [...multiValue, option];
    let responsabilidadesString = nuevasResponsabilidades.map((nr) => nr.value).join(';');
    this.setState(
      {
        multiValue: nuevasResponsabilidades,
        responsabilidadesFiscales: responsabilidadesString,
        finalObjeto: {
          ...this.state.finalObjeto,
          responsabilidadesFiscales: responsabilidadesString,
        },
      },
      () => this.props.dispatch(SetDatosCliente({ ...this.state })),
    );
  };

  componentDidMount() {
    this.setState(
      {
        tiposIdentificacion: this.props.tiposIdentificacion,
        finalObjeto: { ...this.state.finalObjeto },
      },
      () => {
        this.props.dispatch(SetDatosCliente({ ...this.state }));
      },
    );
  }

  // handleChangeAdquirenteResponsable = (event) => {
  //   this.setState(
  //     {
  //       adquirenteResponsable: event.target.checked,
  //       finalObjeto: {
  //         ...this.state.finalObjeto,
  //         adquirenteResponsable: event.target.checked,
  //       },
  //     },
  //     () => {
  //       this.props.dispatch(SetDatosCliente({ ...this.state }));
  //     }
  //   );
  // };

  handleChange = (event) => {
    let value = event.target.value;
    const { identificacionAdquiriente, tipoIdentificacionAdquiriente } = this.state;
    if (
      event.target.id == 'digitoVerificacionAdquirente' &&
      identificacionAdquiriente != '' &&
      tipoIdentificacionAdquiriente.value == 'NIT'
    ) {
      let identificacion = identificacionAdquiriente.split('-', 2);
      let newIdentificacion = `${identificacion[0]}`;
      if (value != '') {
        newIdentificacion = `${identificacion[0]}-${value}`;
      }

      this.setState(
        {
          [event.target.id]: value,
          identificacionAdquiriente: newIdentificacion,
          finalObjeto: {
            ...this.state.finalObjeto,
            [event.target.id]: value,
            identificacionAdquiriente: newIdentificacion,
          },
        },
        () => {
          this.props.dispatch(SetDatosCliente({ ...this.state }));
        },
      );
      return;
    }
    this.setState(
      {
        [event.target.id]: value,
        finalObjeto: { ...this.state.finalObjeto, [event.target.id]: value },
      },
      () => {
        this.props.dispatch(SetDatosCliente({ ...this.state }));
      },
    );
  };

  handleKeyPressTexto = (event) => {
    if (!handleKeyPressTexto(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoNumeros = (event) => {
    if (!handleKeyPressTextoNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressNumeros = (event) => {
    if (!handleKeyPressNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoNumeroGuion = (event) => {
    if (!handleKeyPressTextoNumeroGuion(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoDireccion = (event) => {
    if (!handleKeyPressTextoDireccion(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoEmail = (event) => {
    if (!handleKeyPressTextoEmail(event)) {
      event.preventDefault();
    }
  };

  handleChangeEmail = (event) => {
    this.setState(
      {
        emailAdquiriente: event.target.value,
        formControlIsError: false,
        finalObjeto: {
          ...this.state.finalObjeto,
          emailAdquiriente: event.target.value,
        },
      },
      () => {
        const { emailAdquiriente } = this.state;
        let sw = false;
        emailAdquiriente.split(',').map((result) => {
          if (!regEmail.test(result)) {
            sw = true;
          }
        });
        if (sw) {
          this.setState({ formControlIsError: true }, () => {
            this.props.dispatch(SetDatosCliente({ ...this.state }));
          });
        } else {
          this.props.dispatch(SetDatosCliente({ ...this.state }));
        }
      },
    );
  };

  handleChangeCliente = async (selectedOption) => {
    let codigo = selectedOption.value;
    const { tiposResponsabilidadesFiscales, addConfigUsuarioSalud } = this.props;
    const { adquirentes } = this.state;
    let adquirenteSeleccionado = adquirentes.filter((item) => item.nmId == codigo);
    adquirenteSeleccionado = adquirenteSeleccionado[0];
    let seleccionados = adquirenteSeleccionado.nmResponFiscales.split(';');
    let arrayMultiValue = seleccionados.map((itemSeleccionados) => {
      if (itemSeleccionados === '') return;
      let result = tiposResponsabilidadesFiscales.filter(
        (item) => itemSeleccionados == item.codigo,
      );
      return {
        value: result[0].codigo,
        label: result[0].nombre,
      };
    });

    let ciudad = adquirenteSeleccionado.ciudad;
    let nombreCiudad = ciudad
      ? `${ciudad.dsNombre}, ${ciudad.departamento.dsNombre}, ${ciudad.departamento.pais.dsNombre}`
      : '';
    addConfigUsuarioSalud(adquirenteSeleccionado);
    this.setState(
      {
        tipoPersonaAdquiriente: adquirenteSeleccionado.cdTipoPersona,
        tipoIdentificacionAdquiriente: adquirenteSeleccionado.cdTipoIdentificacion,
        identificacionAdquiriente: adquirenteSeleccionado.dsIdentificacion,
        digitoVerificacionAdquirente: adquirenteSeleccionado.dsDigitoVerif,
        nombresAdquiriente: adquirenteSeleccionado.dsNombre,
        segundoNombre: adquirenteSeleccionado.dsSegundoNombre,
        primerApellido: adquirenteSeleccionado.dsPrimerApellido,
        segundoApellido: adquirenteSeleccionado.dsSegundoApellido,
        direccionAdquiriente: adquirenteSeleccionado.dsDireccion,
        ciudadAdquiriente: ciudad,
        telefonoAdquiriente: adquirenteSeleccionado.dsTelefono,
        emailAdquiriente: adquirenteSeleccionado.dsEmail,
        regimenAdquirente: adquirenteSeleccionado.cdTipoRegimen,
        // adquirenteResponsable:
        //   adquirenteSeleccionado.cdAdqResponsable === "1" ? true : false,
        responsabilidadesFiscales: adquirenteSeleccionado.nmResponFiscales,
        multiValue: arrayMultiValue,
        nombreCiudad: nombreCiudad,
        nombreRegimen: adquirenteSeleccionado.cdTipoRegimen,
        finalObjeto: {
          ...this.state.finalObjeto,
          tipoPersonaAdquiriente: adquirenteSeleccionado.cdTipoPersona,
          tipoIdentificacionAdquiriente: adquirenteSeleccionado.cdTipoIdentificacion,
          identificacionAdquiriente:
            adquirenteSeleccionado.cdTipoIdentificacion === 'NIT'
              ? `${adquirenteSeleccionado.dsIdentificacion}-${adquirenteSeleccionado.dsDigitoVerif}`
              : adquirenteSeleccionado.dsIdentificacion,
          digitoVerificacionAdquirente: adquirenteSeleccionado.dsDigitoVerif,
          nombresAdquiriente: adquirenteSeleccionado.dsNombre,
          segundoNombre: adquirenteSeleccionado.dsSegundoNombre,
          primerApellido: adquirenteSeleccionado.dsPrimerApellido,
          segundoApellido: adquirenteSeleccionado.dsSegundoApellido,
          ciudadAdquiriente: ciudad,
          direccionAdquiriente: adquirenteSeleccionado.dsDireccion,
          telefonoAdquiriente: adquirenteSeleccionado.dsTelefono,
          emailAdquiriente: adquirenteSeleccionado.dsEmail,
          codigoPostalAdquirente: '',
          regimenAdquirente: adquirenteSeleccionado.cdTipoRegimen,
          tipoOperacion: 'ESTANDAR',
          responsabilidadesFiscales: adquirenteSeleccionado.nmResponFiscales,
        },
      },
      () => this.props.dispatch(SetDatosCliente({ ...this.state })),
    );
  };

  handleChangeCiudad = (selectedOption) => {
    this.setState({
      ciudadAdquiriente: { nmId: selectedOption.value },
    });
  };

  handleConsultaCliente = (newValue) => {
    return consultarAdquirentesPorEmisorYNombre({
      idEmisor: this.props.userAttributes.codigoEmisor,
      filtro: newValue,
      estado: 'A',
    }).then((res) => {
      this.setState({ adquirentes: res.data });
      return newValue;
    });
  };

  render() {
    const {
      formControlIsError,
      identificacionAdquiriente,
      nombresAdquiriente,
      segundoNombre,
      primerApellido,
      segundoApellido,
      direccionAdquiriente,
      telefonoAdquiriente,
      emailAdquiriente,
      codigoPostalAdquirente,
      adquirenteResponsable,
      responsabilidadesFiscales,
      digitoVerificacionAdquirente,
      nombreCiudad,
      nombreRegimen,
      cambiarCiudad,
    } = this.state;
    const { tiposResponsabilidadesFiscales } = this.props;

    let tiposResponsabilidadesFiscalesSelect = [];
    tiposResponsabilidadesFiscales.map((item) => {
      tiposResponsabilidadesFiscalesSelect.push({
        value: item.codigo,
        label: item.nombre,
      });
    });

    return (
      <div className='row justify-content-center align-items-center' style={{ marginTop: '30px' }}>
        <div className='form-group col-md-12 mt-0 mb-0'>
          <h4 className='titulo_principal'>Información cliente</h4>
        </div>

        <div
          className='row justify-content-center align-items-center col-lg-6 col-xs-12'
          style={{ marginTop: '30px' }}
        >
          <div className='col-12'>
            <label style={styles.fontFamily} className='titulos_label'>
              Buscar Cliente *
            </label>
          </div>
          <div className='col-lg-12 col-xs-12'>
            <Async
              defaultOptions={true}
              loadOptions={this.loadOptions}
              onInputChange={this.handleConsultaCliente}
              onChange={this.handleChangeCliente}
            />
          </div>
        </div>

        <div className='form-row col-md-12' style={{ marginTop: '30px' }}>
          <div className='form-group col-md-12 col-xl-2'>
            <label style={styles.fontFamily} className='titulos_label'>
              Número Documento*
            </label>
            <div className='form-group'>
              <input
                maxLength='35'
                autoComplete='off'
                type='text'
                id='identificacionAdquiriente'
                className='form-control form-control-md'
                value={identificacionAdquiriente}
                onKeyPress={this.handleKeyPressTextoNumeros}
                required
                disabled={true}
              />
            </div>
          </div>

          <div className='form-group col-md-5 mb-0'>
            <label className='titulos_label'>Nombre o Razón Social*</label>
            <div className='form-group'>
              <input
                maxLength='200'
                autoComplete='off'
                type='text'
                id='nombresAdquiriente'
                className='form-control form-control-md'
                value={nombresAdquiriente}
                onKeyPress={this.handleKeyPressTextoNumeros}
                disabled={true}
                required
              />
            </div>
          </div>

          <div className='form-group col-md-5 mb-0'>
            <label className='titulos_label'>Apellidos*</label>
            <div className='form-group'>
              <input
                maxLength='200'
                autoComplete='off'
                type='text'
                id='primerApellido'
                className='form-control form-control-md'
                value={primerApellido}
                onKeyPress={this.handleKeyPressTexto}
                disabled={true}
              />
            </div>
          </div>
        </div>

        <div className='form-row col-md-12' style={{ marginTop: '20px' }}>
          {cambiarCiudad && (
            <div className='form-group col-md-12 col-xl-4'>
              <label className='titulos_label'>Ciudad*</label>
              <SelectCiudades
                onChange={this.handleChangeCiudad}
                placeholder='Seleccióne una ciudad'
              />
            </div>
          )}
          {!cambiarCiudad && (
            <div className='form-group col-md-12 col-xl-3'>
              <label className='titulos_label'>Ciudad*</label>
              <input
                maxLength='200'
                autoComplete='off'
                type='text'
                id='nombreCiudad'
                className='form-control form-control-md'
                value={nombreCiudad}
                disabled={true}
              />
            </div>
          )}
          <div className='form-group col-xl-4 mb-0'>
            <label className='titulos_label'>Télefono *</label>
            <div className='form-group'>
              <input
                maxLength='255'
                autoComplete='off'
                type='text'
                id='telefonoAdquiriente'
                className='form-control form-control-md'
                value={telefonoAdquiriente}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div className='form-group col-xl-5 mb-0'>
            <label className='titulos_label'>Email*</label>
            <div className='form-group'>
              <input
                maxLength='100'
                style={formControlIsError ? { border: '2px solid red' } : null}
                autoComplete='off'
                type='text'
                id='emailAdquiriente'
                className='form-control form-control-md'
                value={emailAdquiriente}
                onKeyPress={this.handleKeyPressTextoEmail}
                onChange={this.handleChangeEmail}
                required
              />
            </div>
          </div>
        </div>

        <div
          className='form-row col-md-10 justify-content-center align-items-center'
          style={{ marginTop: '20px' }}
        >
          <div className='form-group col-md-12 col-xl-4'>
            <div>
              <label className='titulos_label'>Responsabilidades fiscales *</label>
              <Select
                name='responsabilidadesFiscales'
                placeholder='Responsabilidades fiscales'
                value={this.state.multiValue}
                options={tiposResponsabilidadesFiscalesSelect}
                onChange={this.handleMultiChange.bind(this)}
                multi
              />
            </div>
          </div>
          <div className='form-group col-md-12 col-xl-4'>
            <div>
              <label className='titulos_label'>Responsabilidades fiscales</label>
              <input
                type='text'
                id='nombreRegimen'
                className='form-control form-control-md'
                value={this.state.multiValue.map(({ value }) => value)}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DatosCliente.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    ciudadesArray: state.factura.ciudadesArray,
    tiposIdentificacion: state.factura.tiposIdentificacion,
    tiposPersona: state.factura.tiposPersona,
    regimenAdquirente: state.factura.regimenAdquirente,
    listaDetalleFactura: state.factura.listaDetalleFactura,
    tiposResponsabilidadesFiscales: state.factura.tiposResponsabilidadesFiscales,
    adquirentes: state.maestros.adquirentes,
    userAttributes: state.factura.userAttributes,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DatosCliente));
