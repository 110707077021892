import * as React from 'react';
import Button from 'components/atoms/Button';
import EnviarFactura from '@material-ui/icons/AssignmentTurnedIn';
import { generarTokenRegistroDoc as generarToken } from 'network/Api/Generic';
import Loader from 'components/Loaders/Loader';
import { enviarDocumentoSoporte } from 'network/Api/DocumentoSoporte';
import { crearActualizarVendedor } from 'network/Api/Vendedor';
import { getTime, now, dateString } from 'utils/DateUtils';
import { TIPOS_DATOS, validarString } from 'utils/ValidarDatos';

const ButtonEnviarSoporte = ({ openModal, limpiarFormulario, userAttributes, documento }) => {
  const [loading, setLoading] = React.useState(false);

  const emviarDocumento = async () => {
    setLoading(true);
    const { codigoEmisor, identificacionEmisor } = userAttributes;
    const body = await completarDocumento(codigoEmisor, identificacionEmisor);
    enviarDocumentoSoporte({ body });
    openModal({
      content: 'Enviando documento, podra ver el resultado en la pantalla de documentos enviados',
      onAccept: limpiarFormulario,
      onCancel: limpiarFormulario
    });
    setLoading(false);
  };

  const validarEnvio = () => {
    setLoading(true);
    const errores = validarDocumento();
    if (errores.length === 0) {
      const emisorSaldo = validarSaldo();
      setLoading(false);
      openModal(
        emisorSaldo
          ? {
              content: '¿Enviar Documento Soporte?',
              onAccept: emviarDocumento
            }
          : { content: 'Se encuentra con saldo insuficiente' }
      );
    } else {
      setLoading(false);
      openModal({
        content: `Documento Soporte con errores: ${errores.join(' - ')}`
      });
    }
  };

  const validarSaldo = () => {
    const { saldoEmisor, cdTarifaDte } = userAttributes;
    return saldoEmisor >= cdTarifaDte;
  };

  const registrarActualizarVendedor = () => {
    openModal({
      content: '¿Desea actualizar o registrar vendedor?',
      onAccept: () => {
        crearActualizarVendedor({
          codigoEmisor: userAttributes.codigoEmisor,
          ...documento
        });
        validarEnvio();
      },
      onCancel: validarEnvio
    });
  };

  const validarDocumento = () => {
    const { tipoDocumentoElectronico, dsNumeroFactura, dsPrefijo } = documento;
    const errores = [];
    const addError = (error) => errores.push(error);
    validarVendedor(addError);
    if (!documento.dsNumeroFactura && documento.snConsecutivoAutomatico === 'N')
      addError('El Nro. Documento es obligatorio');
    if (!documento.detalles || !documento.detalles.length)
      addError('No se a cargado ningun detalle');
    if (!documento.pago || !documento.pago.formaPago)
      addError('No se a seleccionado una forma de pago');
    if (!documento.pago || !documento.pago.medioPago)
      addError('No se a seleccionado un medio de pago');
    if (!documento.dsResolucionDian) addError('La Resolucion es obligatoria');
    if (!documento.tipoOperacion) addError('El tipo operacion es obligatorio');
    if (!documento.tipoPersonaVendedor) addError('El tipo persona es obligatorio');
    if (documento.tipoOperacion === 'NO_RESIDENTE' && !documento.codigoPostalVendedor)
      addError('El código postal del vendedor es obligatorio si no es residente');
    if (documento.codigoPostalVendedor && documento.codigoPostalVendedor.length > 6)
      addError('El código postal del vendedor debe ser inferior o igual a 6 caracteres');
    if (tipoDocumentoElectronico === 'NOTA_CREDITO_SOPORTE_ADQUISICION') {
      if (!dsNumeroFactura) addError('El consecutivo del documento es obligatorio');
      if (!dsPrefijo || !validarString(dsPrefijo, TIPOS_DATOS.texto))
        addError('El prefijo es obligatorio y no puede tener caracteres numericos');
    }
    if (documento.moneda !== 'COP') {
      const { fechaTipoCambio, valorTipoCambio } = documento.tipoCambio || {};
      if (!valorTipoCambio) addError('El valor del cambio es obligatorio');
      if (!fechaTipoCambio) addError('La fecha acuerdo es obligatoria');
    }
    return errores;
  };

  const validarVendedor = (addError) => {
    if (!documento.ciudadVendedor) addError('La ciudad del vendedor es obligatoria');
    if (!documento.emailVendedor) addError('El email del vendedor es obligatorio');
    if (!documento.direccionVendedor) addError('La direccion del vendedor es obligatorio');
    if (!documento.telefonoVendedor) addError('El telefono del vendedor es obligatorio');
    if (!documento.nombresVendedor) addError('El nombre del vendedor es obligatorio');
    if (documento.tipoPersonaVendedor === 'NATURAL' && !documento.primerApellido)
      addError('El primer apellido del vendedor es obligatorio cuando no es persona natural');
    if (!documento.tipoOperacion) addError('El tipo de operacion es obligatoria');
    if (!documento.regimenVendedor) addError('El regimen del vendedor es obligatorio');
    if (!documento.responsabilidadesFiscales)
      addError('Las responsabilidades fiscales son obligatorias');
  };

  const completarDocumento = async (id, identificacion) => {
    const { data } = await generarToken({ id, identificacion });
    const {
      dsPrefijo,
      fechaVencimiento,
      ciudadVendedor,
      pago,
      snConsecutivoAutomatico,
      fechaEmision
    } = documento;
    const time = getTime();
    const actual = now().format('YYYY-MM-DD');
    const feEmi = dateString(documento.fechaEmision).format('YYYY-MM-DD');
    return {
      ...documento,
      detalles: documento.detalles.map((detalle) => ({
        ...detalle,
        periodoFactura: {
          fechaInicio: fechaEmision,
          codigoGeneracion: actual === feEmi ? 'POR_OPERACION' : 'ACUMULADO_SEMANAL'
        }
      })),
      dsPrefijo: dsPrefijo !== 'SIN_PREFIJO' ? dsPrefijo : '',
      emisorId: data,
      fechaEmision: fechaEmision + ' ' + time,
      fechaVencimiento: fechaVencimiento + ' ' + time,
      pago: {
        ...pago,
        fechaVencimientoPago: `${fechaVencimiento + ' ' + time}`
      },
      ciudadVendedor: {
        cdDane: ciudadVendedor.departamento.cdDane + ciudadVendedor.cdDane
      },
      snConsecutivoAutomatico: snConsecutivoAutomatico === 'S' ? 'true' : 'false'
    };
  };

  return (
    <div className='form-label-group'>
      <Button
        style={{ backgroundColor: '#92C63E' }}
        onClick={registrarActualizarVendedor}
        disabled={loading}
      >
        {loading && <Loader type='spinner-border-sm' />}
        <EnviarFactura /> Enviar Documento
      </Button>
    </div>
  );
};

export default ButtonEnviarSoporte;
