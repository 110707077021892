import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux';
import { SetSpinnerModal, SetConfirmacionModalState } from 'reducers/actions/facturaActions';
import { SetOpenModalUpdateProducto, SetProductos } from 'reducers/actions/maestrosActions';
import LabelTitulos from 'components/layout/labelTitulos';
import { registrarAjusteInventario } from 'network/Api/AjusteInventario';
import { actualizarProducto } from 'network/Api/Producto';
import Form from './Formulario';

const ACTUALIZADO_EXITOSO = 'El producto se actualizo exitosamente!';

class ModalUpdateProducto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { userAttributes } = this.props;
    this.setState({ nmIdEmisor: userAttributes.codigoEmisor });
  }

  HandleClose = () => {
    const { setOpenModalUpdateProducto } = this.props;
    setOpenModalUpdateProducto(false);
  };

  handleSaveMovimiento = async (producto) => {
    const { setSpinnerModal, setConfirmacionModalState } = this.props;
    const { nmIdEmisor: nmId } = this.state;
    await this.handleUpdate(producto);
    setSpinnerModal(true);
    const res = await registrarAjusteInventario({ emisor: { nmId }, producto });
    let text = '';
    setSpinnerModal(false);
    if (res.ok) {
      this.setState({ valorAjuste: 0 });
      text = 'Registro creado correctamente';
    } else {
      text = res.mensaje.includes('ERROR:')
        ? 'Producto sin manejo de inventario disponible, actualizarlo y vuelve a intentarlo'
        : 'No fue posible crear el registro';
    }
    setConfirmacionModalState({
      open: true,
      text,
      onClick: () => setConfirmacionModalState({ open: false }),
    });
  };

  handleUpdate = async (producto) => {
    const { setSpinnerModal, setConfirmacionModalState, consultar } = this.props;
    setSpinnerModal(true);
    const result = await actualizarProducto(producto);
    setSpinnerModal(false);
    if (result.data === true) {
      setConfirmacionModalState({
        open: true,
        text: ACTUALIZADO_EXITOSO,
        onClick: () => setConfirmacionModalState({ open: false }),
      });
      consultar();
    } else
      setConfirmacionModalState({
        open: true,
        text: `Ocurrió un error guardando el producto, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.`,
        onClick: () => {
          setConfirmacionModalState({ open: false });
        },
      });
  };

  render() {
    const { openModalUpdateProducto, userAttributes, producto, setConfirmacionModalState } =
      this.props;

    return (
      <div>
        <Dialog
          open={openModalUpdateProducto}
          onClose={this.HandleClose}
          aria-labelledby='form-dialog-title'
          scroll='paper'
          fullScreen={false}
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle id='form-dialog-title'>
            <LabelTitulos texto='Actualizar producto' />
          </DialogTitle>
          <DialogContent className='mb-5'>
            <Form
              handleClose={this.HandleClose}
              nmIdEmisor={userAttributes.codigoEmisor}
              productoUpdate={producto}
              handleSaveMovimiento={this.handleSaveMovimiento}
              handleSave={this.handleUpdate}
              openModal={setConfirmacionModalState}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  openModalUpdateProducto: state.maestros.openModalUpdateProducto,
  userAttributes: state.factura.userAttributes,
  producto: state.maestros.producto,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setOpenModalUpdateProducto: (item) => dispatch(SetOpenModalUpdateProducto(item)),
  setProductos: (item) => dispatch(SetProductos(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateProducto);
