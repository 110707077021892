import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TablaGenerica from 'components/Tablas/GenericaOrdenadaColumna';
import TablaExcel from 'components/Tablas/TablaGenerica';
import Button from 'components/atoms/Button';
import {
  consultarSoportesEnviados,
  reenviarEmail,
  remplazarSoporteNomina,
  regenerarDocumentoNomina,
} from 'network/Api/Nomina';
import sendIcon from 'icons/send.png';
import ModalFormulario from 'components/modal/ModalConfirmacion';
import Input from 'components/Inputs/Input';
import { TIPOS_DATOS, validarString } from 'utils/ValidarDatos';
import { dateString, getLastDayOfMonth, now } from 'utils/DateUtils';
import { moneyFormat } from 'utils/TextFormattingUtils';
import { withStyles } from '@material-ui/core/styles';
import { consultarDocumentoNominaPorId } from 'network/Api/Nomina';
import ModalAjuste from './ModalAjuste';
import getEnvironment from 'environments';
import { exportTableToExcel } from 'utils/ExportarExcelUtil';
import ExportExcelIco from 'static/images/excel.png';
import ButtonDescargaArchivos from 'components/organisms/ButtonDescargaArchivos';
import Icon from 'components/molecules/Icon';

const urlBucket = getEnvironment().urlS3;
const styles = (theme) => ({
  titleHeader: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#000',
  },
  headerRow: {
    backgroundColor: 'rgba(0, 0, 0, .1)',
  },
});

function DocumentosEnviados(props) {
  const { classes } = props;
  const [state, setState] = useState({
    fechaDefault: now().format('YYYY-MM-DD'),
    identificacionEmpleado: '',
    enviandoRemplazo: false,
    type: 'month',
    openAjusteRemplazo: true,
    isLoading: true,
    idRegenear: 0,
  });

  useEffect(
    () =>
      setState((state) => ({
        ...state,
        type: window.navigator.userAgent.includes('Chrome/') ? 'month' : 'date',
      })),
    [],
  );

  useEffect(() => consultarSoportes(), [props.userAttributes, state.fechaDefault]);

  const consultarSoportes = () => {
    setState((state) => ({ ...state, documentos: [] }));
    if (props.userAttributes.codigoEmisor) {
      consultarSoportesEnviados({
        emisor: props.userAttributes.codigoEmisor,
        feInicio: dateString(state.fechaDefault).format('YYYY-MM-01'),
        feFin: getLastDayOfMonth(dateString(state.fechaDefault)).format('YYYY-MM-DD'),
        empleadoId: state.identificacionEmpleado,
      }).then((res) =>
        setState((state) => ({
          ...state,
          documentos: res.data,
          isLoading: false,
        })),
      );
    }
  };

  const regenerarDocumentos = (documento) => {
    setState((state) => ({ ...state, idRegenear: documento.idDocumento }));
    try {
      let errores = documento.errores
        ? JSON.parse(documento.errores).estado.errores.join(' - ')
        : '';
      if (errores.length > 0) {
        openMensajeReenvio(
          `El documento tiene los siguientes errores: ${errores} Por favor validarlos en la pantalla de empleados y reenviar este documento en la pantalla de consulta`,
        );
      } else {
        openMensaje('Reenviando documento');
        reenviarDocumentoNomina(documento.idDocumento);
      }
    } catch (ex) {
      openMensaje('Fallando intentando leer el error, por favor comuniquese con soporte');
    }
  };

  const reenviarDocumentoNomina = (nmId) => {
    setState((s) => ({ ...s, openReenvio: false }));
    openMensaje('Reenviando documento...');
    regenerarDocumentoNomina([nmId]).then((res) => {
      consultarSoportes();
      openMensaje(
        res.data[nmId] !== 'EXITOSO'
          ? 'Documento no se regenero correctamente, intenta denuevo mas tarde, si el error persiste contactar con soporte'
          : 'Documento regenerado correctamente',
      );
    });
  };

  function reenviarDocumento() {
    let isEmail = validarString(state.email || '', TIPOS_DATOS.email);
    if (isEmail) {
      setState((state) => ({
        ...state,
        errorEmail: '',
        openFormulario: false,
      }));
      reenviarEmail({
        rutaPdf: state.rutaPdf,
        documentoId: state.documentoId,
        email: state.email,
      }).then((res) => openMensaje('Enviando email'));
    } else setState((state) => ({ ...state, errorEmail: 'El email es invalido' }));
  }

  function handleChange(event) {
    event.persist();
    setState((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  }

  const enviarNominaAjuste = ({ nmId, tipoAjuste, devengados = null, deducciones = null }) => {
    setState((state) => ({ ...state, enviandoRemplazo: true }));
    remplazarSoporteNomina({
      nmId,
      tipoAjuste,
      devengados:
        devengados &&
        devengados.map((d) => ({
          ...d,
          emisor: null,
          empleado: null,
        })),
      deducciones:
        deducciones &&
        deducciones.map((d) => ({
          ...d,
          emisor: null,
          empleado: null,
        })),
    }).then((res) => {
      consultarSoportes();
      setState((state) => ({ ...state, enviandoRemplazo: false }));
      if (res.data === 'EXITOSO') openMensaje('Documento enviado exitosamente');
      else openMensaje('Error procesando el documento');
    });
  };

  const openMensaje = (textoModalMensaje) =>
    setState((s) => ({
      ...s,
      textoModalMensaje: textoModalMensaje,
      openMensaje: true,
    }));

  const openMensajeReenvio = (textoModalMensaje) =>
    setState((s) => ({
      ...s,
      textoModalReenvio: textoModalMensaje,
      openReenvio: true,
    }));

  const openModalAjuste = ({ idDocumento }) => {
    consultarDocumentoNominaPorId({ idDocumento }).then((res) => {
      if (res.status === 200) {
        setState((s) => ({
          ...s,
          openModalAjuste: true,
          jsonDocumento: {
            ...JSON.parse(res.data.dsJsonDocumento),
            nmId: idDocumento,
          },
        }));
      }
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', margin: '0px 0px 35px 0px' }}>
        <Input
          label='Seleccione el mes que desea consultar'
          type={state.type}
          name='fechaDefault'
          onChange={handleChange}
          value={state.fechaDefault || ''}
          margin='5px'
        />
        <Input
          label='Identificación del empleado'
          name='identificacionEmpleado'
          onChange={handleChange}
          value={state.identificacionEmpleado || ''}
          margin='5px'
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            margin: '6px',
          }}
        >
          <Button onClick={consultarSoportes}>Consultar</Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            margin: '6px',
          }}
        >
          <Button variant='text' onClick={() => exportTableToExcel('excel', 'nominaEmitida')}>
            <img alt='exportarExcel' src={ExportExcelIco} />
          </Button>
        </div>
      </div>
      <TablaGenerica
        classnames={classes}
        loading={state.isLoading}
        headers={[
          { label: '', id: 'actionsBtn', disablePadding: true },
          { label: 'Tipo de documento', id: 'tipoDoc', disablePadding: true },
          { label: 'Documento', id: 'numDoc', disablePadding: true },
          {
            label: 'Identificación empleado',
            id: 'idEmpleado',
            disablePadding: true,
          },
          {
            label: 'Nombre empleado',
            id: 'nombreEmpleado',
            disablePadding: true,
          },
          { label: 'Mes nómina', id: 'mesNomina', disablePadding: true },
          {
            label: 'Total devengado',
            id: 'totalDevengado',
            disablePadding: true,
          },
          {
            label: 'Total deducción',
            id: 'totalDeduccion',
            disablePadding: true,
          },
          { label: 'Total nómina', id: 'totalNomina', disablePadding: true },
          { label: 'Estado', id: 'estado', disablePadding: true },
          {
            label: 'Acciones',
            id: 'acciones',
            disablePadding: true,
          },
        ]}
        keys={[
          'actionsBtn',
          'tipoDocumento',
          'numDocumento',
          'idEmpleado',
          'nombreEmpleado',
          'mesNomina',
          'totalDevengado',
          'totalDeduccion',
          'totalNomina',
          'estado',
          'acciones',
        ]}
        detalles={
          state.documentos &&
          state.documentos.map((documento) => ({
            ...documento,
            mesNomina: dateString(documento.mesNomina).format('MMMM / YYYY'),
            totalDevengado: moneyFormat(documento.totalDevengado),
            totalDeduccion: moneyFormat(documento.totalDeduccion),
            totalNomina: moneyFormat(documento.totalNomina),
            actionsBtn: (
              <div
                style={{
                  display: documento.rutaPdf ? 'flex' : 'none',
                }}
              >
                <ButtonDescargaArchivos
                  fileName={documento.rutaPdf}
                  icon={<Icon icon='pdf' height='50' width='50' />}
                />
                <Button
                  variant='text'
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      openFormulario: true,
                      rutaPdf: `${urlBucket}/${documento.rutaPdf}`,
                      documentoId: documento.idDocumento,
                    }))
                  }
                >
                  <img src={sendIcon} alt='' width='50' height='50' />
                </Button>
              </div>
            ),
            acciones: (
              <div style={{ display: 'flex' }}>
                <Button
                  style={{
                    margin: '10px',
                    display: documento.estado !== 'EXITOSO' ? 'none' : 'block',
                  }}
                  disabled={
                    documento.tipoDocumento !== 'NOMINA_INDIVIDUAL' || state.enviandoRemplazo
                  }
                  onClick={() => openModalAjuste(documento)}
                >
                  Ajustar
                </Button>
                <Button
                  style={{
                    margin: '10px',
                    display: documento.estado !== 'EXITOSO' ? 'none' : 'block',
                  }}
                  onClick={() =>
                    enviarNominaAjuste({
                      nmId: documento.idDocumento,
                      tipoAjuste: 'ELIMINAR',
                    })
                  }
                  disabled={
                    documento.tipoDocumento !== 'NOMINA_INDIVIDUAL' || state.enviandoRemplazo
                  }
                >
                  Eliminar
                </Button>
                <Button
                  style={{
                    margin: '10px',
                    display: documento.estado !== 'EXITOSO' ? 'block' : 'none',
                  }}
                  onClick={() => regenerarDocumentos(documento)}
                >
                  Reenviar
                </Button>
              </div>
            ),
          }))
        }
      />
      <TablaExcel
        id='excel'
        tableStyle={{ display: 'none' }}
        withPagination={false}
        headers={[
          'Tipo de Documento',
          'Documento',
          'Identificacion Empleado',
          'Nombre Empleado',
          'Mes Nomina',
          'Total Devengado',
          'Total Deduccion',
          'Total Nomina',
          'Estado',
        ]}
        keys={[
          'tipoDocumento',
          'numDocumento',
          'idEmpleado',
          'nombreEmpleado',
          'mesNomina',
          'totalDevengado',
          'totalDeduccion',
          'totalNomina',
          'estado',
        ]}
        rowsPerPage={(state.documentos || []).length}
        detalles={(state.documentos || []).map((documento) => ({
          ...documento,
          mesNomina: dateString(documento.mesNomina).format('MMMM / YYYY'),
        }))}
      />
      <ModalFormulario
        open={state.openFormulario}
        fullWidth={true}
        content={
          <Input
            label='Email'
            name='email'
            error={state.errorEmail || ''}
            onChange={handleChange}
            value={state.email || ''}
          />
        }
        onCancel={() =>
          setState((state) => ({
            ...state,
            openFormulario: false,
            rutaPdf: null,
            documentoId: null,
            errorEmail: '',
            email: '',
          }))
        }
        onAccept={reenviarDocumento}
      />
      <ModalFormulario
        open={state.openMensaje || false}
        content={state.textoModalMensaje || ''}
        onAccept={() => setState((s) => ({ ...s, openMensaje: false }))}
      />
      <ModalAjuste
        open={state.openModalAjuste}
        setState={setState}
        jsonDocumento={state.jsonDocumento}
        enviarNomina={enviarNominaAjuste}
        openMensaje={openMensaje}
      />
      <ModalFormulario
        open={state.openReenvio || false}
        content={state.textoModalReenvio || ''}
        onAccept={() => setState((s) => ({ ...s, openReenvio: false }))}
        extraButtons={[
          {
            name: 'Reenviar',
            onClick: () => reenviarDocumentoNomina(state.idRegenear),
          },
        ]}
      />
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentosEnviados));
