import { serviceRoutes, get, post, postFormData, getBlob } from './index';

export const consultarDocumentosEnviados = async ({
  feInicio,
  feFin,
  emisor,
  adquirente,
  prefijo = '',
  numero = '',
  isPos,
}) =>
  await get(
    serviceRoutes.consultarDocumentosEnviados,
    `?feInicio=${feInicio}&feFin=${feFin}&emisor=${emisor}&adquirente=${adquirente}&prefijo=${prefijo}&numero=${numero}&isPos=${isPos}`,
  ).then(
    (res) =>
      Object.keys(res)
        .filter((key) => key !== 'status')
        .map((key) => res[key])[0],
  );

export const consultarDocumentosErroneos = async ({
  emisor = 0,
  prefijo = '',
  numero = '',
  inicio,
  fin,
}) =>
  await get(
    serviceRoutes.consultarDocumentosErroneos,
    `?emisor=${emisor}&prefijo=${prefijo}&numero=${numero}&inicio=${inicio}&fin=${fin}`,
  );

export const consultarUltimosDiezDocumentos = async ({ nmId }) =>
  await get(serviceRoutes.consultarUltimosDiezDocumentos, `?emisor=${nmId}`);

export const enviarDocumento = async (body) => await post(serviceRoutes.enviarDocumento, body);

export const reenviarDocumento = async (body) => await post(serviceRoutes.reenviarDocumento, body);

export const consultarDocumentoExistente = async ({ emisorId, prefijo, numDoc }) =>
  await get(
    serviceRoutes.consultarDocumentoExistente,
    `?emisorId=${emisorId}&prefijo=${prefijo}&numDoc=${numDoc}`,
  );

export const registrarDocumento = async (body) =>
  await post(serviceRoutes.registrarDocumento, body);

export const calcularDetalleExcel = async (body) =>
  await postFormData(serviceRoutes.calcularDetalleExcel, body);

export const reenviarDocumentosErroneos = async () =>
  await post(serviceRoutes.reenviarDocumentosErroneos, null);

export const consultarDocumentoDomina = async (id) =>
  await get(serviceRoutes.consultarDocumentoDomina, `?id=${id}`);

export const generarReporteExcel = async ({
  feInicio,
  feFin,
  emisor,
  adquirente,
  prefijo = '',
  numero = '',
}) =>
  await getBlob(
    serviceRoutes.generarExcelDocumentosEmitidos,
    `?feInicio=${feInicio}&feFin=${feFin}&emisor=${emisor}&adquirente=${adquirente}&prefijo=${prefijo}&numero=${numero}`,
  );

export const reenviarEmailDocumento = async ({ body, emisor }) =>
  await post(`${serviceRoutes.reenviarEmailDocumento}?emisor=${emisor}`, body);

export const generarNotaCreditoDocumento = async (body) =>
  await post(`${serviceRoutes.generarNotaCredito}`, body);
