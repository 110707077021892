import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux';
import { SetSpinnerModal, SetConfirmacionModalState } from 'reducers/actions/facturaActions';
import { SetOpenModalAddProducto, SetProductos } from 'reducers/actions/maestrosActions';
import LabelTitulos from 'components/layout/labelTitulos';
import { registrarProducto } from 'network/Api/Producto';
import Form from './Formulario';

const GUARDADO_EXITOSO = 'El producto se guardo exitosamente!';

class ModalAddProducto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { userAttributes } = this.props;
    this.setState({ nmIdEmisor: userAttributes.codigoEmisor });
  }

  HandleClose = () => {
    const { setOpenModalAddProducto } = this.props;
    setOpenModalAddProducto(false);
  };

  handleSaveNew = async (p) => {
    const { setSpinnerModal, userAttributes, consultar, setConfirmacionModalState } = this.props;
    const { codigoEmisor: nmId } = userAttributes;
    setSpinnerModal(true);
    const body = { ...p, emisor: { nmId } };
    const { status, message } = await registrarProducto(body);
    setSpinnerModal(false);
    if (status === 200) {
      setConfirmacionModalState({
          open: true,
          text: GUARDADO_EXITOSO,
          onClick: () => setConfirmacionModalState({ open: false }),
        });
        consultar();
    } else
      setConfirmacionModalState({
        open: true,
        text: message.includes('Duplicate')
          ? `Codigo del producto registrado anteriormente`
          : `Ocurrió un error guardando el producto, por favor intente de nuevo. Si el error persiste, por favor contacte con soporte.`,
        onClick: () => setConfirmacionModalState({ open: false }),
      });
  };

  render() {
    const { openModalAddProducto, setConfirmacionModalState, userAttributes } = this.props;

    return (
      <div>
        <Dialog
          open={openModalAddProducto}
          onClose={this.HandleClose}
          aria-labelledby='form-dialog-title'
          scroll='paper'
          fullScreen={false}
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle id='form-dialog-title'>
            <LabelTitulos texto='Crear nuevo producto' />
          </DialogTitle>
          <DialogContent className='mb-5'>
            <Form
              handleClose={this.HandleClose}
              handleSave={this.handleSaveNew}
              openModal={setConfirmacionModalState}
              nmIdEmisor={userAttributes.codigoEmisor}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  openModalAddProducto: state.maestros.openModalAddProducto,
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  setConfirmacionModalState: (item) => dispatch(SetConfirmacionModalState(item)),
  setOpenModalAddProducto: (item) => dispatch(SetOpenModalAddProducto(item)),
  setProductos: (item) => dispatch(SetProductos(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddProducto);
