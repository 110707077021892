import React, { useMemo } from 'react';
import Select from 'components/molecules/Select';
import TipoIdentificacion from 'static/enums/tiposIdentificacion';

function SelectTipoIdentificacion({ ...rest }) {
  const options = useMemo(() => {
    return TipoIdentificacion.map(({ tipoIdentificacionAdquiriente, nombre }) => ({
      label: nombre,
      value: tipoIdentificacionAdquiriente,
    }));
  }, []);

  return <Select options={options} {...rest} />;
}

export default SelectTipoIdentificacion;
