import React from 'react';
import MUButton from '@material-ui/core/Button';

const Button = ({ variant = 'contained', color = 'primary', children, ...rest }) => (
  <MUButton variant={variant} color={color} {...rest}>
    {children}
  </MUButton>
);

export default Button;
