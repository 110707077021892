import React, { useState, useEffect } from 'react';
import Select from 'components/Inputs/Select';
import Modal from 'components/molecules/Modal';
import Input from 'components/Inputs/Input';
import Button from 'components/atoms/Button';
import EnviarFactura from '@material-ui/icons/AssignmentTurnedIn';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { consultarAdquirentePorIdentificacion } from 'network/Api/Adquirente';
import { consultarParametroPorNombre } from 'network/Api/Parametro';
import { consultarSaldoEmisor } from 'network/Api/SaldoPos';
import { registrarRecarga } from 'network/Api/Recarga';
import { enviar } from 'network/Api/DocumentoPos';
import CurrencyInput from 'react-currency-input-field';
import { connect } from 'react-redux';
import { printHTML } from 'utils/BlobUtils';
import { now } from 'utils/DateUtils';
import TipoIdentificacion from 'static/enums/tiposIdentificacion';
import MedioPago from 'static/enums/tiposMediosPago';
import FormaPago from 'static/enums/tiposPago';
import Detalles from './Detalles';
import Botonera from './Botonera';
import BeneficiosComprador from 'components/templates/BeneficiosComprador';
import InformacionCaja from 'components/templates/InformacionCajaVenta';
import base from 'static/baseJson/documento-pos.json';
import SelectProductos from 'components/organisms/SelectProductos';
import InputM from 'components/molecules/Input';
import FormularioOtroImpuesto from 'components/templates/FormularioOtroImpuesto';

import { styles } from './styles';

function DocumentoPos({ classes, userAttributes }) {
  const [basePosDocument, setBasePosDocument] = useState(base);

  const [documento, setDocumento] = useState({ netoMostrar: 0, totalPagado: 0 });
  const [producto, setProducto] = useState({});
  const [modal, setModal] = useState({ open: false });
  const [tabs, setDataTab] = useState({ selected: 0, allTabs: [] });
  const [state, setState] = useState({});

  useEffect(() => {
    const { codigoEmisor } = userAttributes;
    if (codigoEmisor) {
      cargaMedioFormaPago();
      handleBlurIdentificacion();
      setDocumento((d) => ({ ...d, codigoEmisor }));
    }
  }, [userAttributes]);

  const cargaMedioFormaPago = () => {
    setState({
      formasPago: FormaPago.map(({ codigo, nombre }) => ({ label: nombre, value: codigo })),
      mediosPago: MedioPago.filter((mp) => mp.pos).map(({ codigo, nombre }) => ({
        label: nombre,
        value: codigo,
      })),
      tipoIdentificacion: TipoIdentificacion.map(({ nombre, tipoIdentificacionAdquiriente }) => ({
        label: nombre,
        value: tipoIdentificacionAdquiriente,
      })),
    });
  };

  useEffect(() => {
    const { totalPagado, netoMostrar = 0 } = documento;
    setProducto({});
    let totalBruto = 0,
      totalDescuento = 0,
      totalIva = 0,
      valorNeto = 0;
    (documento.detalles || []).forEach((detalle) => {
      totalBruto += detalle.valorBruto;
      totalDescuento += detalle.valorTotalDescuento;
      totalIva += detalle.valorTotalImpuestosRetenciones;
      valorNeto = totalBruto + totalIva - totalDescuento;
    });
    setDocumento((d) => ({
      ...d,
      totalBruto,
      totalDescuento,
      totalIva,
      valorNeto,
      netoMostrar: valorNeto,
      totalCambio: (totalPagado - netoMostrar).toFixed(0),
    }));
  }, [documento.detalles]);

  useEffect(() => {
    const { selected } = tabs;
    const oldTabs = JSON.parse(localStorage.getItem('tabs'));
    if (oldTabs) {
      const tab = oldTabs.allTabs.at(selected);
      setDocumento(tab ? tab.doc : basePosDocument);
      setDataTab(oldTabs);
    } else {
      setDocumento(basePosDocument);
    }
  }, []);

  const handleChangeProducto = (_, producto) => {
    const { snIvaIncluido } = producto;
    setProducto({
      nmId: producto.nmId,
      codigoArticulo: producto.dsCodigo,
      precioUnitario: producto.nmValorUnitario,
      cantidad: 1,
      porcentajeIva: producto.nmPorcentajeIva,
      porcentajeConsumo: producto.nmPorcentajeInc,
      valorTotalDescuento: 0,
      descripcionArticulo: producto.dsDescripcion,
      snIvaIncluido,
    });
  };

  const handleChange = (event) => {
    const label = event.target.name;
    const value = event.target.value;
    setDocumento((d) => ({ ...d, [label]: value }));
  };

  const handleChangeNumber = (event) => {
    const value = event.target.value;
    if (!/\D/.test(value)) handleChange(event);
  };

  const handleChangeTotalPagado = (name, value) => {
    const { detalles } = documento;
    if (detalles.length) {
      setDocumento((d) => ({ ...d, [name]: value }));
    }
  };

  const handleChangeDetalle = (event) => {
    const label = event.target.name;
    const value = event.target.value;
    setProducto((d) => ({ ...d, [label]: value }));
  };

  const addDetalle = () => {
    if (producto.cantidad) {
      const {
        precioUnitario: precioUnitarioIva,
        cantidad,
        porcentajeIva,
        valorTotalDescuento,
        porcentajeConsumo,
        otroImpuestoValor,
        otroImpuestoVolumen,
        otroImpuestoPorcentaje,
        otroImpuesto,
        snIvaIncluido,
      } = producto;
      if (otroImpuesto) {
        if (!otroImpuestoPorcentaje) {
          let errores = validarImpuestos(otroImpuestoVolumen, otroImpuestoValor);
          if (errores.length > 0) {
            openModal({
              title: 'Fallo en validaciones',
              content: errores.join(' - '),
              onAccept: closeModal,
            });
            return;
          }
        }
      }
      const precioUnitario = snIvaIncluido
        ? precioUnitarioIva / ((porcentajeIva + 100) / 100)
        : precioUnitarioIva;
      const valorBruto = precioUnitario * cantidad;
      const valorBase = valorBruto - valorTotalDescuento;
      const valorTotalIva = (porcentajeIva * valorBase) / 100;
      const valorTotalInc = (porcentajeConsumo * valorBase) / 100;
      const otroImpuestoTotal = otroImpuestoPorcentaje
        ? (valorBase * otroImpuestoPorcentaje) / 100
        : otroImpuesto === 'IBUA'
          ? (otroImpuestoValor * otroImpuestoVolumen) / 100
          : otroImpuestoValor * otroImpuestoVolumen;
      setDocumento((p) => ({
        ...p,
        detalles: [
          ...p.detalles.map((producto, key) => ({
            ...producto,
            id: key,
          })),
          {
            ...producto,
            id: p.detalles.length + 1,
            valorBruto,
            valorTotalImpuestosRetenciones:
              valorTotalIva + valorTotalInc + (otroImpuestoTotal || 0),
            valorBase,
            otroImpuestoTotal,
            precioUnitario,
          },
        ],
      }));
    }
  };

  const validarSaldoEmisor = async () => {
    const { codigoEmisor } = userAttributes;
    const documentosFaltantes = await consultarParametroPorNombre({
      parametro: 'DOCUMENTOS_FALTANTES_RECARGA',
    });
    const { data } = await consultarSaldoEmisor({ emisor: codigoEmisor });
    return data <= documentosFaltantes.data;
  };

  const comprarDocumento = () => {
    consultarParametroPorNombre({ parametro: 'PLAN_RECARGA_UNO' }).then((plan) => {
      const { codigoEmisor } = userAttributes;
      registrarRecarga({
        emisor: { nmId: codigoEmisor },
        plan: { nmId: plan.data },
        cdCanal: 'PARALELA',
      }).then(({ data, status }) => {
        if (status === 200)
          openModal(
            data
              ? {
                  content: 'Compra realizada correctamente',
                  onAccept: enviarDocumento,
                  onCancel: enviarDocumento,
                }
              : {
                  content: 'Saldo insuficiente',
                  onCancel: closeModal,
                },
          );
      });
    });
  };

  const validarEnvioDocumento = () => {
    let errores = validarDocumento();
    if (errores.length === 0) {
      validarSaldoEmisor().then((isEnvioValido) => {
        openModal(
          !isEnvioValido
            ? {
                content: '¿Enviar documento?',
                onAccept: enviarDocumento,
                onCancel: closeModal,
              }
            : {
                content: '¿Comprar 1 documento?',
                onAccept: comprarDocumento,
                onCancel: closeModal,
              },
        );
      });
    } else
      openModal({
        title: 'Fallo en validaciones',
        content: errores.join(' - '),
        onAccept: closeModal,
      });
  };

  const validarImpuestos = (otroImpuestoVolumen, otroImpuestoValor) => {
    let errores = [];
    if (!otroImpuestoValor) errores.push('Es obligatorio diligenciar el campo Valor unitario');
    if (!otroImpuestoVolumen) errores.push('Es obligatorio diligenciar el campo Volumen');
    return errores;
  };

  const validarDocumento = () => {
    let errores = [];
    if (documento.detalles.length === 0)
      errores.push('Es obligatorio enviar detalles en el documento');
    if (!documento.nombresAdquiriente) errores.push('El nombre del adquirente es obligatorio');
    if (!documento.identificacionAdquiriente)
      errores.push('La identificacion del adquirente es obligatoria');
    if (!documento.beneficiosComprador.codigo)
      errores.push('El código del comprador es obligatorio');
    if (!documento.beneficiosComprador.puntos)
      errores.push('Los puntos del comprador son obligatorios');
    if (!documento.informacionCajaVenta.placaCaja)
      errores.push('La placa de la caja es obligatoria');
    if (!documento.informacionCajaVenta.ubicacionCaja)
      errores.push('La ubicación de la caja es obligatoria');
    if (!documento.informacionCajaVenta.cajero)
      errores.push('El código del empleado es obligatorio');
    if (!documento.informacionCajaVenta.tipoCaja) errores.push('El tipo de caja es obligatorio');
    if (!documento.informacionCajaVenta.codigoVenta)
      errores.push('El código de la venta es obligatorio');
    return errores;
  };

  const enviarDocumento = () => {
    enviar({
      ...documento,
      fechaEmision: now().format('YYYY-MM-DD HH:MM:ss'),
    }).then((res) => {
      if (res.status === 200) {
        printHTML(res.data);
        openModal({
          content: 'Documento enviado correctamente',
          onAccept: () => {
            if (tabs.allTabs.length > 0) {
              deleteTab();
              closeModal();
            } else window.location.reload();
          },
        });
      } else
        openModal({
          content: 'Error enviando documento',
          onAccept: closeModal(),
        });
    });
  };

  const openModal = ({ title, content, onAccept, onCancel, fullScreen = false }) =>
    setModal({ open: true, title, content, onAccept, onCancel, fullScreen });

  const closeModal = () => setModal((m) => ({ ...m, open: false }));

  const setTabs = (dt) =>
    setDataTab((t) => {
      const final = { ...t, ...dt };
      localStorage.setItem('tabs', JSON.stringify(final));
      return final;
    });

  const addTab = () => {
    setDataTab((t) => {
      const { selected, allTabs, newName } = t;
      let final = { selected, allTabs };
      if (newName)
        final = {
          selected,
          allTabs: [...allTabs, { name: newName, doc: basePosDocument }],
        };
      return final;
    });
  };

  const deleteTab = () => {
    setDataTab((t) => {
      const { selected, allTabs } = t;
      const newTabs = allTabs.map((at, i) => i !== selected && at).filter((at) => at);
      setDocumento(newTabs.length > 0 ? newTabs.at(0).doc : basePosDocument);
      const final = { selected: 0, allTabs: newTabs };
      return final;
    });
  };

  const changeTab = (_, tab) => {
    const { selected } = tabs;
    const { allTabs } = JSON.parse(localStorage.getItem('tabs'));
    const prevTab = { ...allTabs.at(selected), doc: documento };
    const thisTab = allTabs.at(tab);
    setDocumento(thisTab.doc);
    const final = {
      selected: tab,
      allTabs: allTabs.map((at, index) => (index === selected ? prevTab : at)),
    };
    setTabs(final);
  };

  useEffect(() => {
    const { selected, allTabs } = tabs;
    if (selected !== undefined && allTabs && allTabs.length > 0) {
      const thisTab = { ...allTabs.at(selected), doc: documento };
      const newAllTabs = allTabs.map((tab, index) => (index === selected ? thisTab : tab));
      const newTabs = { selected, allTabs: newAllTabs };
      setTabs(newTabs);
    }
  }, [documento]);

  useEffect(() => {
    localStorage.setItem('tabs', JSON.stringify(tabs));
  }, [tabs]);

  const handleBlurIdentificacion = async () => {
    const { identificacionAdquiriente } = documento;
    const data = await consultarAdquirente(identificacionAdquiriente);
    if (data) {
      setDocumento((d) => ({ ...d, ...data }));
      setBasePosDocument((d) => ({ ...d, ...data }));
    }
  };

  const consultarAdquirente = async (identificacionAdquiriente) => {
    const { codigoEmisor } = userAttributes;
    const { data } = await consultarAdquirentePorIdentificacion({
      id: codigoEmisor,
      identificacion: identificacionAdquiriente,
    });
    if (data) {
      const { dsNombre, dsIdentificacion, cdTipoIdentificacion, dsEmail, dsDireccion, dsTelefono } =
        data;
      return {
        nombresAdquiriente: dsNombre,
        identificacionAdquiriente: dsIdentificacion,
        telefonoAdquiriente: dsTelefono,
        emailAdquiriente: dsEmail,
        direccionAdquiriente: dsDireccion,
        tipoIdentificacionAdquiriente: cdTipoIdentificacion,
      };
    }
  };

  const handleChangeDescuentos = (name, value) => {
    const { detalles } = documento;
    if (detalles.length > 0) {
      const valueNumeric = !value ? 0 : parseInt(value);
      setDocumento((d) => ({ ...d, [name]: value }));
      if (name === 'descuentoTotal') {
        if (valueNumeric >= parseInt(documento.totalBruto)) {
          openModal({
            content: (
              <p>
                El valor del descuento no puede ser superior al valor bruto del documento ($
                {documento.totalBruto})
              </p>
            ),
            onAccept: closeModal,
          });
          setDocumento((d) => ({ ...d, [name]: 1 }));
        }
        if (valueNumeric < documento.totalBruto) {
          setDocumento((d) => ({
            ...d,
            porcentajeDescuento: null,
            netoMostrar: d.valorNeto - valueNumeric,
          }));
        }
      }
      if (name === 'porcentajeDescuento') {
        if (valueNumeric > 30) setDocumento((d) => ({ ...d, [name]: 30 }));
        if (valueNumeric <= 30) {
          setDocumento((d) => ({
            ...d,
            descuentoTotal: null,
            netoMostrar: d.valorNeto - (d.valorNeto * value) / 100,
          }));
        }
      }
    }
  };

  useEffect(() => {
    setDocumento((d) => ({ ...d, totalCambio: d.totalPagado - d.netoMostrar }));
  }, [documento.netoMostrar, documento.totalPagado]);

  const handleChangePago = (data) => {
    const { pago } = documento;
    const newPago = { ...pago, ...data };
    setDocumento((d) => ({ ...d, pago: newPago }));
  };

  const closeBotonera = (detalles) => {
    setDocumento((d) => ({ ...d, detalles }));
    closeModal();
  };

  return (
    <div className='space-header'>
      <div className='form-row' style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div className='form-group col-md-3'>
          <label className='titulos_label'>Nombre de la cuenta</label>
          <Input
            value={tabs.newName}
            onChange={({ target }) => setTabs({ newName: target.value })}
          />
        </div>
        <div className='form-group col-md-auto'>
          <Button onClick={addTab}>Agregar Cuenta</Button>
        </div>
        <div className='form-group col-md-auto'>
          <Button onClick={deleteTab}>Eliminar Cuenta</Button>
        </div>
      </div>
      {tabs.allTabs.length > 0 && (
        <AppBar position='static'>
          <Tabs
            value={tabs.selected}
            onChange={changeTab}
            variant='scrollable'
            scrollButtons='auto'
          >
            {tabs.allTabs.map(({ name }, index) => (
              <Tab label={name} id={`Tab${index}`} key={index} />
            ))}
          </Tabs>
        </AppBar>
      )}
      <div className='from-row style-movil'>
        <div className='col-lg-8'>
          <div className={`form-row ${classes.borderedContainer}`}>
            <div className='form-group col-lg-6'>
              <label className='titulos_label'>Forma de pago</label>
              <Select
                options={state.formasPago}
                value={(state.formasPago || []).find(
                  (fp) => fp.value === (documento.pago || {}).formaPago,
                )}
                onChange={({ value }) => handleChangePago({ formaPago: value })}
              />
            </div>
            <div className='form-group col-lg-6'>
              <label className='titulos_label'>Medio de pago</label>
              <Select
                options={state.mediosPago}
                value={(state.mediosPago || []).find(
                  (fp) => fp.value === (documento.pago || {}).medioPago,
                )}
                onChange={({ value }) => handleChangePago({ medioPago: value })}
              />
            </div>
            <div className='form-group col-lg-4'>
              <label className='titulos_label'>Nombre*</label>
              <Input
                onChange={handleChange}
                name='nombresAdquiriente'
                value={documento.nombresAdquiriente || ''}
                readOnly
              />
            </div>
            <div className='form-group col-lg-4'>
              <label className='titulos_label'>Tipo Identificación</label>
              <Select
                onChange={(e) =>
                  setDocumento((s) => ({ ...s, tipoIdentificacionAdquiriente: e.value }))
                }
                options={state.tipoIdentificacion}
                name='tipoIdentificacionAdquiriente'
                value={(state.tipoIdentificacion || []).find(
                  ({ value }) => value === documento.tipoIdentificacionAdquiriente,
                )}
              />
            </div>
            <div className='form-group col-lg-4'>
              <label className='titulos_label'>Identificación*</label>
              <Input
                onBlur={handleBlurIdentificacion}
                onChange={handleChangeNumber}
                name='identificacionAdquiriente'
                value={documento.identificacionAdquiriente || ''}
              />
            </div>
            <div className='form-group col-lg-6'>
              <label className='titulos_label'>Teléfono*</label>
              <Input
                onChange={handleChange}
                name='telefonoAdquiriente'
                value={documento.telefonoAdquiriente || ''}
                require
                readOnly
              />
            </div>
            <div className='form-group col-lg-6'>
              <label className='titulos_label'>Email*</label>
              <Input
                onChange={handleChange}
                name='emailAdquiriente'
                value={documento.emailAdquiriente || ''}
                require
              />
            </div>
            <div className='form-group col-12'>
              <label className='titulos_label'>Observacion*</label>
              <textarea
                className='form-control'
                onChange={(e) => {
                  if (e.target.value.length <= 200) handleChange(e);
                }}
                name='dsObservacion'
                value={documento.dsObservacion || ''}
              />
            </div>
          </div>

          <div
            className={`form-row justify-content-center align-items-center ${classes.borderedContainer}`}
          >
            <SelectProductos
              className='form-group col-lg-7'
              label='Código - Producto'
              idEmisor={userAttributes.codigoEmisor}
              onChange={handleChangeProducto}
              value={producto.nmId}
            />
            <InputM
              label='Cantidad'
              className='form-group col-lg-2'
              value={producto.cantidad}
              name='cantidad'
              onChange={handleChangeDetalle}
              validacion='only-num'
            />
            <InputM
              label='Precio Unitario'
              className='form-group col-lg-3'
              value={producto.precioUnitario}
              name='precioUnitario'
              onChange={handleChangeDetalle}
              validacion='only-num-decimals'
              currency
            />
            <FormularioOtroImpuesto
              className='form-row col-12'
              otroImpuesto={producto}
              handleChangeInput={handleChangeDetalle}
              handleChangeSelect={({ value }) =>
                setProducto((d) => ({ ...d, otroImpuesto: value }))
              }
            />

            <div className='form-group col-md-auto'>
              <Button
                onClick={() =>
                  openModal({
                    content: (
                      <Botonera
                        classes={classes}
                        closeModal={closeBotonera}
                        codigoEmisor={userAttributes.codigoEmisor}
                        oldDetalles={documento.detalles}
                      />
                    ),
                    fullScreen: true,
                    title: 'Productos',
                  })
                }
              >
                <b>VER PRODUCTOS</b>
              </Button>
            </div>
            <div className='form-group col-md-auto'>
              <Button onClick={addDetalle}>Agregar</Button>
            </div>
          </div>
        </div>
        <div className='col-lg-4 align-items-center justify-content-center'>
          <BeneficiosComprador
            lg={12}
            className={classes.borderedContainer}
            documento={documento}
            setDocumento={setDocumento}
          />
          <InformacionCaja
            lg={12}
            className={classes.borderedContainer}
            documento={documento}
            setDocumento={setDocumento}
          />
          <Detalles classes={classes} documento={documento} setDocumento={setDocumento} />
          <div className='form-row justify-content-center'>
            <div className='form-group col-lg-5'>
              <label className='titulos_label'>Descuento en pesos</label>
              <CurrencyInput
                className='input-money'
                name='descuentoTotal'
                value={documento.descuentoTotal || ''}
                decimalsLimit={2}
                onValueChange={(value, name) => handleChangeDescuentos(name, value)}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
            <div className='form-group col-lg-5'>
              <label className='titulos_label'>Descuento en %</label>
              <Input
                value={documento.porcentajeDescuento || ''}
                name='porcentajeDescuento'
                onChange={(e) => handleChangeDescuentos(e.target.name, e.target.value)}
              />
            </div>
            <div className='form-group col-lg-4'>
              <label className='titulos_label'>Total Pagado</label>
              <CurrencyInput
                className='input-money'
                name='totalPagado'
                value={documento.totalPagado || ''}
                decimalsLimit={2}
                onValueChange={(value, name) => handleChangeTotalPagado(name, value)}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
            <div className='form-group col-lg-4'>
              <label className='titulos_label'>Total Cambio</label>
              <CurrencyInput
                className='input-money'
                name='totalPagado'
                readOnly
                value={documento.totalCambio || 0}
                decimalsLimit={2}
                decimalSeparator=','
                groupSeparator='.'
                prefix='$'
              />
            </div>
          </div>
          <div
            className='form-row col-12 align-items-center justify-content-center'
            style={{ marginTop: '30px' }}
          >
            <Button className={classes.buttonSended} onClick={validarEnvioDocumento}>
              <EnviarFactura /> Imprimir Cuenta
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={modal.open}
        title={modal.title}
        content={modal.content}
        onAccept={modal.onAccept}
        onCancel={modal.onCancel}
        fullWidth={modal.fullWidth}
        maxWidth={modal.maxWidth}
        fullScreen={modal.fullScreen}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentoPos));
