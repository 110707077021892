/* eslint-disable */
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { SetOpenModalAddProducto } from "reducers/actions/maestrosActions";
import { SetProductos } from "reducers/actions/maestrosActions";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  buttonAdd: {
    backgroundColor: "#92C63E",
    color: theme.palette.common.white,
  },
});

class TablaToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      texto: "",
      productosStateStatic: [],
      recorrido: false,
    };
  }

  handleClickFiltro = () => {
    //this.props.setOpenModalFiltroFactura(true);
  };

  handleClickAdd = () => {
    const { setOpenModalAddProducto } = this.props;
    setOpenModalAddProducto(true);
  };

  handleChange = (event) => {
    const { productos } = this.props;
    const { recorrido } = this.state;
    this.setState(
      {
        productosStateStatic: !recorrido
          ? productos
          : this.state.productosStateStatic,
        texto: event.target.value,
        recorrido: true,
      },
      () => {
        this.filtrarDatos();
      }
    );
  };

  filtrarDatos = () => {
    const { productosStateStatic, texto } = this.state;
    const { setProductos } = this.props;

    if (texto === "") {
      setProductos(productosStateStatic);
      return;
    }

    let productos = productosStateStatic.filter((item) => {
      if (
        item.dsDescripcion.toLowerCase().indexOf(texto.toLowerCase()) > -1 ||
        item.dsCodigo.toLowerCase().indexOf(texto.toLowerCase()) > -1
      ) {
        return { ...item };
      }
    });

    setProductos(productos);
  };
  render() {
    const { numSelected, classes, handleChangeFilter } = this.props;
    return (
      <Fragment>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <div className={classes.title} />
          <div className={classes.actions}>
            <Button
              className={classes.buttonAdd}
              onClick={this.handleClickAdd}
              aria-label="Agregar"
            >
              <Add />
              Agregar
            </Button>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <input
            style={{ width: "400px" }}
            maxLength="30"
            autoComplete="off"
            // value={texto}
            type="text"
            className="form-control form-control-md"
            onChange={handleChangeFilter}
            placeholder="Busqueda por nombre..."
          />
          <div className={classes.spacer} />
          <div className={classes.actions} style={{ display: "none" }}>
            <Tooltip onClick={this.handleClickFiltro} title="Filtro de lista">
              <IconButton aria-label="Filtro de lista">
                Filtrar
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </Fragment>
    );
  }
}

TablaToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

function mapStateToProps(state, props) {
  return {
    productos: state.maestros.productos,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setOpenModalAddProducto: (item) => dispatch(SetOpenModalAddProducto(item)),
  setProductos: (item) => dispatch(SetProductos(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(toolbarStyles)(TablaToolbar));
