import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import TablaGenerica from "components/Tablas/TablaGenerica";
import { exportTableToExcel } from "utils/ExportarExcelUtil";
import ExportExcelIco from "static/images/excel.png";
import { SetSpinnerModal } from "reducers/actions/facturaActions";
import {
  Button,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { consultarReporteInventario } from "network/Api/AjusteInventario";

const styles = {
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: "0px 10px",
  },
  filtersContainer: { width: "10%", marginLeft: "10px" },
};

const ID_TABLE = "detallesDocumentosTable";

class MovimientoInventario extends Component {
  state = {
    detalles: [],
    consulta: true,
    feInicio: moment()
      .subtract(parseInt(moment().format("DD")) - 1, "days")
      .format("YYYY-MM-DD"),
    feFin: moment().format("YYYY-MM-DD"),
    codigoArtConsulta: "",
    detallesFiltrados: [],
  };

  componentDidUpdate() {
    if (this.state.consulta) this.consultarDetalles();
  }

  consultarDetalles = () => {
    const { setSpinnerModal, userAttributes } = this.props;
    if (userAttributes.codigoEmisor) {
      setSpinnerModal(true);
      consultarReporteInventario(this.getBodyConsulta()).then((response) => {
        let detalles = response.data.map((detalle) => ({
          ...detalle,
          fecha: new Date(detalle.fechaMovimiento),
          feEmision: moment(detalle.fechaMovimiento).format("DD/MM/YYYY"),
          documento: detalle.prefijoDocumento
            ? `${detalle.prefijoDocumento}-${detalle.numeroDocumento}`
            : "",
          tipoDocumento:
            detalle.tipoDocumento === "A"
              ? "AJUSTE"
              : detalle.tipoDocumento === "C"
              ? "COMPRA"
              : detalle.tipoDocumento,
        }));
        this.setState({
          detalles: detalles,
          detallesFiltrados: detalles,
          consulta: false,
        });
        setSpinnerModal(false);
      });
    }
  };

  getBodyConsulta = () => {
    const { userAttributes } = this.props;
    const { feInicio, feFin, codigoArtConsulta } = this.state;
    return {
      id: userAttributes.codigoEmisor,
      inicio: feInicio,
      fin: feFin,
      articulo: codigoArtConsulta,
    };
  };

  filter = (palabra) => {
    const { detalles } = this.state;
    let arrayFiltrado = [];
    detalles.forEach((item) => {
      Object.keys(item).some((key) => {
        if (
          item[key]
            .toString()
            .toLowerCase()
            .includes(palabra.toLowerCase())
        ) {
          arrayFiltrado.push(item);
          return true;
        }
        return false;
      });
    });
    this.setState({ detallesFiltrados: arrayFiltrado });
  };

  handleChange = (event) =>
    this.setState({ [event.target.id]: event.target.value });

  render() {
    const { detallesFiltrados, feFin, feInicio } = this.state;
    return (
      <div>
        <div style={{ display: "flex", alignContent: "center" }}>
          <div style={styles.filtersContainer}>
            <label>Fecha Inicio</label>
            <input
              onChange={this.handleChange}
              type="date"
              value={feInicio}
              max={feFin}
              id="feInicio"
              className="form-control form-control-md"
            />
          </div>
          <div style={styles.filtersContainer}>
            <label>Fecha Fin</label>
            <input
              onChange={this.handleChange}
              type="date"
              value={feFin}
              max={moment().format("YYYY-MM-DD")}
              min={feInicio}
              id="feFin"
              className="form-control form-control-md"
            />
          </div>
          <div style={{ ...styles.filtersContainer, width: "20%" }}>
            <label>Codigo Articulo</label>
            <input
              type="text"
              className="form-control form-control-md"
              id="codigoArtConsulta"
              onChange={this.handleChange}
            />
          </div>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => this.consultarDetalles()}
              color="primary"
              variant="contained"
            >
              Consultar
            </Button>
          </div>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => exportTableToExcel(ID_TABLE, "Detalles Facturas")}
            >
              <img src={ExportExcelIco} alt="Exportar a excel" />
            </Button>
          </div>
        </div>
        <div style={{ margin: "20px 0px" }}>
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <label className="text-muted" style={{ padding: "7px" }}>
              Buscar
            </label>
            <input
              className="form-control"
              style={{ width: "30%" }}
              onChange={(event) => this.filter(event.target.value)}
            />
          </div>
        </div>
        {detallesFiltrados ? (
          <TablaGenerica
            headers={[
              "Fecha Emision",
              "Código Ítem",
              "Descripción",
              "Precio Unitario",
              "% IVA",
              "% INC",
              "Cantidad",
              "Descuento",
              "Valor Total",
              "Tipo Movimiento",
              "N° Documento",
            ]}
            keys={[
              "feEmision",
              "codigoItem",
              "descripcionItem",
              "valorUnitario",
              "porcentajeIva",
              "porcentajeInc",
              "cantidadItem",
              "valorDescuento",
              "valorTotal",
              "tipoDocumento",
              "documento",
            ]}
            rowsPerPage={10}
            orderBy={"feEmision"}
            detalles={detallesFiltrados}
          />
        ) : (
          <div className="form-row col-md-12 d-flex">
            <div className="form-group col-md-12 col-xl-2">
              <div
                style={{ textAlign: "center" }}
                class="spinner-border text-primary"
              />
            </div>
          </div>
        )}
        <Table style={{ display: "none" }} id={ID_TABLE}>
          <TableHead>
            <TableRow>
              <TableCell>Fecha Emision</TableCell>
              <TableCell>Codigo Item</TableCell>
              <TableCell>Descripcion</TableCell>
              <TableCell>Precio Unitario</TableCell>
              <TableCell>% IVA</TableCell>
              <TableCell>% INC</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Descuento</TableCell>
              <TableCell>Valor Total</TableCell>
              <TableCell>Tipo Movimiento</TableCell>
              <TableCell>Prefijo</TableCell>
              <TableCell>Numero documento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detallesFiltrados.map((item, key) => (
              <TableRow key={key}>
                <TableCell>{item.feEmision}</TableCell>
                <TableCell>{item.codigoItem}</TableCell>
                <TableCell>{item.descripcionItem}</TableCell>
                <TableCell>{item.valorUnitario}</TableCell>
                <TableCell>{item.porcentajeIva}</TableCell>
                <TableCell>{item.porcentajeInc}</TableCell>
                <TableCell>{item.cantidadItem}</TableCell>
                <TableCell>{item.valorDescuento}</TableCell>
                <TableCell>{item.valorTotal}</TableCell>
                <TableCell>{item.tipoDocumento}</TableCell>
                <TableCell>{item.documento}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovimientoInventario);
