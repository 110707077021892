import React from 'react';
import './index.css';
import LogoPortal from '../../static/images/logoNoBackground.png';
import { Link } from 'react-router-dom';
import Parrafo from './Parrafo';

const Home = () => {
  return (
    <React.Fragment>
      <div className='wrapper'>
        <div className='column-left'>
          <Parrafo isLoged={false} classStyle={'container-text'} />
        </div>
        <div className='column-right'>
          <div>
            <div>
              <div className='container-brand'>
                <img src={LogoPortal} className='nd-body-brand-home container-logo-main' alt='' />
              </div>
              <div className='container-buttons'>
                <Link className={{ textDecoration: 'none' }} to={{ pathname: '/login' }}>
                  <button
                    className='nd-button-act'
                    onClick={() => window.location.pathname === '/login'}
                  >
                    Iniciar sesión
                  </button>
                </Link>
              </div>
              <div className='container-buttons'>
                <Link
                  className={{ textDecoration: 'none' }}
                  to={{
                    pathname: '/nuevo-emisor',
                  }}
                >
                  <button className='nd-button-act'>Registrarse para emitir documentos</button>
                </Link>
              </div>
              <div className='container-buttons'>
                <Link className={{ textDecoration: 'none' }} to={{ pathname: '/nuevo-socio' }}>
                  <button className='nd-button-act'>Registrarse para ser socio</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
