/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { subirArchivo } from 'utils/UploadS3';
import moment from 'moment';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { GetApp, Create, EmailRounded } from '@material-ui/icons';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import LabelTitulos from 'components/layout/labelTitulos';
import { SetSpinnerModal } from 'reducers/actions/facturaActions';
import {
  actualizarVerificado,
  registrarFirma,
  actualizarFirma,
  notificarFirma,
} from 'network/Api/CertFirma';
import InputFile from 'components/Inputs/File';
import Input from 'components/Inputs/Input';
import ButtonDescargaArchivos from 'components/organisms/ButtonDescargaArchivos';
import Icon from 'components/molecules/Icon';

let style = {
  inputs: { width: '100%', margin: '2px' },
  inputDate: { width: '40%' },
  inputDivs: { display: 'flex' },
  toolTipLabel: { fontSize: '.9rem' },
  tableCellCenter: { textAlign: 'center' },
};

const URL_BUCKET = 'https://s3.us-east-2.amazonaws.com/portalfactura.com/public/';

class FirmasTable extends Component {
  state = {
    firmaSelected: false,
    isOpen: false,
    feInicio: '',
    feFin: '',
    nmIdFirma: '',
    // Campos cedula
    fotocopiaCedulaFile: null,
    fotocopiaCedulaUrl: '',
    fotocopiaCedulaLabel: 'Clic para adjuntar',
    fotocopiaCedulaSubiendo: false,
    // cartaNotariada, docCamaraComercio
    docCamaraComercioCartaNotariadaFile: null,
    docCamaraComercioCartaNotariadaUrl: '',
    docCamaraComercioCartaNotariadaLabel: 'Clic para adjuntar',
    docCamaraComercioCartaNotariadaSubiendo: false,
    // Campos Rut
    rutFile: null,
    rutUrl: '',
    rutLabel: 'Clic para adjuntar',
    rutSubiendo: false,
    dsRutContrasena: '',

    emisorState: undefined,

    inputDivs: { display: 'flex' },
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', () => this.updateWindowDimensions());
  }

  componentDidUpdate() {
    this.limpiarFormularioCambioEmiosr();
  }

  limpiarFormularioCambioEmiosr = () => {
    const { nitEmisor } = this.props;
    const { emisorState } = this.state;
    if (nitEmisor !== emisorState) {
      this.setState({ emisorState: nitEmisor });
      this.clearForm();
    }
  };

  updateWindowDimensions = () => {
    window.innerWidth <= 800
      ? this.setState({
          inputDivs: { display: 'flex', flexDirection: 'column' },
        })
      : this.setState({ inputDivs: { display: 'flex' } });
  };

  formatearFecha = (fecha) => moment(fecha).format('DD/MM/YYYY');

  handleEstado = (estado) => {
    switch (estado) {
      case 'I':
        return 'Inactiva';
      case 'A':
        return 'Activa';
      default:
        return 'Estado Inesperado';
    }
  };

  actualizar = (emisor, firma) => {
    const { handleConsultarFirmas, setSpinnerModal } = this.props;
    let result = this.validarFormulario(emisor);
    this.setState({ errores: result });
    if (!result) {
      setSpinnerModal(true);
      actualizarFirma({
        ...firma,
        ...this.getBodyNuevaActualizarFirma(emisor),
      }).then(() => {
        handleConsultarFirmas(emisor);
        this.clearForm();
        setSpinnerModal(false);
      });
    } else {
      this.setState({ isOpen: true });
    }
  };

  guardarFirma = (emisor) => {
    const { handleConsultarFirmas, setSpinnerModal } = this.props;
    let result = this.validarFormulario(emisor);
    if (!result) {
      setSpinnerModal(true);
      registrarFirma(this.getBodyNuevaActualizarFirma(emisor)).then(() => {
        this.clearForm();
        setSpinnerModal(false);
        handleConsultarFirmas(emisor);
      });
    } else {
      this.setState({ isOpen: true, errores: result });
    }
  };

  getBodyNuevaActualizarFirma = (nmIdEmisor) => {
    const {
      fotocopiaCedulaUrl,
      docCamaraComercioCartaNotariadaUrl,
      feInicio,
      feFin,
      nmIdFirma,
      snEnviada,
      rutUrl,
      dsRutContrasena,
    } = this.state;
    return {
      nmId: nmIdFirma,
      feInicioFirma: feInicio,
      dsFotocopiaCedula: fotocopiaCedulaUrl,
      dsUrlCartaNotariadaCamComercio: docCamaraComercioCartaNotariadaUrl,
      feFinFirma: feFin,
      snEnviada: snEnviada,
      dsRutUrl: rutUrl,
      dsContrasenaRut: dsRutContrasena,
      emisor: {
        nmId: nmIdEmisor,
      },
    };
  };

  validarFormulario = (emisor) => {
    if (!emisor) return 'Porfavor seleccione un emisor';
  };

  clearForm = () =>
    this.setState({
      nmIdFirma: '',
      feInicio: '',
      feFin: '',
      fotocopiaCedulaFile: null,
      fotocopiaCedulaUrl: '',
      fotocopiaCedulaLabel: 'Clic para adjuntar',
      docCamaraComercioCartaNotariadaFile: null,
      docCamaraComercioCartaNotariadaUrl: '',
      docCamaraComercioCartaNotariadaLabel: 'Clic para adjuntar',
      rutFile: null,
      rutUrl: '',
      rutLabel: 'Clic para adjuntar',
      firmaSelected: false,
      dsRutContrasena: '',
    });

  handleIniciarEdicionFirma = (firma) => {
    this.setState({
      nmIdFirma: firma.nmId,
      firmaSelected: true,
      feInicio: moment(firma.feInicioFirma).format('YYYY-MM-DD'),
      feFin: moment(firma.feInicioFirma).add('1', 'year').format('YYYY-MM-DD'),
      fotocopiaCedulaLabel: firma.dsFotocopiaCedula
        ? firma.dsFotocopiaCedula.replace(
            'https://s3.us-east-2.amazonaws.com/portalfactura.com/public/',
            '',
          )
        : '',
      docCamaraComercioCartaNotariadaLabel: firma.dsUrlCartaNotariadaCamComercio
        ? firma.dsUrlCartaNotariadaCamComercio.replace(
            'https://s3.us-east-2.amazonaws.com/portalfactura.com/public/',
            '',
          )
        : '',
      modeloConfianzaLabel: firma.dsUrlModeloConfianza
        ? firma.dsUrlModeloConfianza.replace(
            'https://s3.us-east-2.amazonaws.com/portalfactura.com/public/',
            '',
          )
        : '',
      fotocopiaCedulaUrl: firma.dsFotocopiaCedula,
      docCamaraComercioCartaNotariadaUrl: firma.dsUrlCartaNotariadaCamComercio,
      modeloConfianzaUrl: firma.dsUrlModeloConfianza,
      snEnviada: firma.snEnviada,
    });
  };

  handleCancelarEdicion = () => this.clearForm();

  handleChange = (event) => {
    let type = event.target.type;
    switch (type) {
      case 'file':
        this.handleChangeFile(event);
        break;
      default:
        this.setState({ [event.target.id]: event.target.value });
        break;
    }
  };

  handleChangeFechas = (event) => {
    let date = moment(event.target.value).format('YYYY-MM-DD');
    this.setState({
      feInicio: date,
      feFin: moment(date).add('1', 'year').format('YYYY-MM-DD'),
    });
  };

  handleChangeFile = (event) => {
    const { nitEmisor } = this.props;
    if (nitEmisor) {
      const baseName = event.target.id;
      const file = event.target.files[0];
      if (file.type === 'application/pdf' || file.size > 5000000) {
        this.setState({
          [`${baseName}Label`]: file.name,
          [`${baseName}File`]: file,
          [`${baseName}Subiendo`]: true,
        });
        this.saveFile(baseName, file, `${baseName}Url`, nitEmisor, [`${baseName}Subiendo`]);
      } else {
        this.setState({
          isOpen: true,
          errores: 'El unico formato valido para el archivo es PDF o el archivo pesa mas de 5Mb',
        });
      }
    } else {
      this.setState({ isOpen: true, errores: 'Selecciona un emisor' });
    }
  };

  saveFile = async (nombre, file, nombreUrl, nitEmisor, nombreSubiendo) => {
    const { emisorId } = this.props;
    const result = await subirArchivo(file, emisorId);
    this.setState({
      [nombreUrl]: result.urlComplete,
      [nombreSubiendo]: false,
    });
  };

  enviarEmail = ({ nmId, emisor }) => {
    const { handleConsultarFirmas, setSpinnerModal } = this.props;
    setSpinnerModal(true);
    notificarFirma({ nmId }).then((_) => {
      handleConsultarFirmas(emisor.nmId);
      setSpinnerModal(false);
    });
  };

  verificarFirma = (firma) => {
    const { handleConsultarFirmas } = this.props;
    actualizarVerificado({ nmId: firma.nmId }).then((_) =>
      handleConsultarFirmas(firma.emisor.nmId),
    );
  };

  render() {
    const { firmas, isEmisor, emisorId, urlRutEmisor } = this.props;
    const {
      firmaSelected,
      fotocopiaCedulaLabel,
      docCamaraComercioCartaNotariadaLabel,
      dsRutContrasena,
      rutLabel,
      feFin,
      feInicio,
      errores,
      isOpen,
      inputDivs,
      fotocopiaCedulaSubiendo,
      docCamaraComercioCartaNotariadaSubiendo,
      rutSubiendo,
    } = this.state;
    return (
      <div>
        <Dialog open={isOpen} aria-labelledby='form-dialog-title'>
          <DialogTitle>
            <LabelTitulos tipo='encabezado' texto='Errores en las validaciones' />
          </DialogTitle>
          <DialogContent>{errores}</DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ isOpen: false })}>Cerrar</Button>
          </DialogActions>
        </Dialog>
        <div className='form-row col-md-12'>
          <div className='form-group col-3'>
            <InputFile
              styleContainer={style.inputs}
              label='RUT'
              id='rut'
              inputText={rutLabel}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group col-2'>
            <Input
              label='Contraseña RUT'
              id='dsRutContrasena'
              value={dsRutContrasena}
              placeholder='Ingresar contraseña'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group col-3'>
            <InputFile
              styleContainer={style.inputs}
              label='Fotocopia Cédula'
              id='fotocopiaCedula'
              inputText={fotocopiaCedulaLabel}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group col-4'>
            <InputFile
              styleContainer={style.inputs}
              label='Documento de Camara y Comercio'
              id='docCamaraComercioCartaNotariada'
              inputText={docCamaraComercioCartaNotariadaLabel}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className='form-row col-md-12'>
          <div className='form-group col-6' />
          <div className='form-group col-6'>
            {firmaSelected ? (
              fotocopiaCedulaSubiendo || docCamaraComercioCartaNotariadaSubiendo || rutSubiendo ? (
                <div style={{ textAlign: 'center' }} className='spinner-border text-primary' />
              ) : (
                <div>
                  <button
                    className='btn btn-primary'
                    onClick={() => this.actualizar(emisorId)}
                    style={{
                      margin: '15px',
                      marginTop: '0px',
                      height: '2.43rem',
                    }}
                  >
                    Actualizar Firma
                  </button>
                  <button
                    className='btn btn-primary'
                    style={{
                      margin: '15px',
                      marginTop: '0px',
                      height: '2.43rem',
                    }}
                    onClick={this.handleCancelarEdicion}
                  >
                    Cancelar
                  </button>
                </div>
              )
            ) : fotocopiaCedulaSubiendo ||
              docCamaraComercioCartaNotariadaSubiendo ||
              rutSubiendo ? (
              <div style={{ textAlign: 'center' }} className='spinner-border text-primary' />
            ) : (
              <button
                className='btn btn-primary'
                onClick={() => this.guardarFirma(emisorId)}
                style={{
                  margin: '15px',
                  marginTop: '0px',
                  height: '2.43rem',
                }}
              >
                Guardar Nueva Firma
              </button>
            )}
          </div>
        </div>
        <hr />
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          <h4>Busqueda de firmas</h4>
        </div>
        <div style={{ with: '100%' }}>
          <div style={inputDivs}>
            <div style={style.inputs}>
              <div className='form-row'>
                <div className='form-group col-xl-3'>
                  <label className='text-muted'>Fecha de Inicio</label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    id='feInicio'
                    value={feInicio}
                    max={moment().format('YYYY-MM-DD')}
                    onChange={this.handleChangeFechas}
                    style={{ width: '100%', height: '2.43rem' }}
                  />
                </div>
                <div className='form-group col-xl-3'>
                  <label className='text-muted'>Fecha Fin</label>
                  <input
                    type='date'
                    className='form-control form-control-sm'
                    id='feFin'
                    value={feFin}
                    style={{ width: '100%', height: '2.43rem' }}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class='col-xs-6 col-md-3'>
            <ButtonDescargaArchivos
              text={'Descargar el RUT'}
              fileName={urlRutEmisor}
              icon={<Icon icon='downloadFile' />}
            />
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha de Inicio</TableCell>
              <TableCell>Fecha de Vencimiento</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell style={style.tableCellCenter}>Descargar cédula</TableCell>
              <TableCell style={style.tableCellCenter}>Descargar Doc Camara y Comercio</TableCell>
              {!isEmisor && <TableCell style={style.tableCellCenter}>Estado Correo</TableCell>}
              <TableCell style={style.tableCellCenter}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {firmas ? (
              firmas.map((firma) => (
                <TableRow key={firma.nmId}>
                  <TableCell>{this.formatearFecha(firma.feInicioFirma)}</TableCell>
                  <TableCell>
                    {firma.feFinFirma ? this.formatearFecha(firma.feFinFirma) : 'No definida'}
                  </TableCell>
                  <TableCell>{this.handleEstado(firma.cdEstado)}</TableCell>
                  <TableCell style={style.tableCellCenter}>
                    <ButtonDescargaArchivos
                      fileName={firma.dsFotocopiaCedula}
                      icon={<Icon icon='downloadFile' height='25' width='25' />}
                    />
                  </TableCell>
                  <TableCell style={style.tableCellCenter}>
                    <ButtonDescargaArchivos
                      fileName={firma.dsUrlCartaNotariadaCamComercio}
                      icon={<Icon icon='downloadFile' height='25' width='25' />}
                    />
                  </TableCell>
                  {!isEmisor && (
                    <TableCell style={style.tableCellCenter}>
                      {firma.snEnviada === 'S' ? 'Enviado' : 'No Enviado'}
                    </TableCell>
                  )}
                  <TableCell style={style.tableCellCenter}>
                    {!isEmisor && (
                      <Button
                        disabled={!firma.dsFotocopiaCedula || !firma.dsUrlCartaNotariadaCamComercio}
                        onClick={() => this.verificarFirma(firma)}
                        style={{
                          backgroundColor: firma.cdVerificado === 'S' ? '#2ecc71' : '#e74c3c',
                        }}
                      >
                        <CheckRoundedIcon />
                      </Button>
                    )}
                    {!isEmisor && (
                      <Button
                        disabled={!firma.dsFotocopiaCedula || !firma.dsUrlCartaNotariadaCamComercio}
                        onClick={() => this.enviarEmail(firma)}
                      >
                        <EmailRounded />
                      </Button>
                    )}
                    <Button
                      disabled={isEmisor && firma.cdEstado === 'A'}
                      onClick={() => this.handleIniciarEdicionFirma(firma)}
                    >
                      <Create />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <div className='form-row col-md-12 d-flex'>
                <div className='form-group col-md-12 col-xl-2'>
                  <div style={{ textAlign: 'center' }} className='spinner-border text-primary' />
                </div>
              </div>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirmasTable);
