import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Typography from 'components/atoms/Typography';
import Button from 'components/atoms/Button';

function Modal({
  open = false,
  content = <></>,
  onAccept,
  onCancel,
  title = 'PortalFactura',
  fullWidth,
  maxWidth = 'sm',
  extraButtons = [],
  fullScreen,
  onAcceptText = 'Aceptar',
  onAcceptProps = { variant: 'text' },
}) {
  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} fullScreen={fullScreen}>
      {title && (
        <DialogTitle style={{ textAlign: 'center' }}>
          <Typography variant='labelEncabezado'>{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {onAccept && (
          <Button onClick={onAccept} {...onAcceptProps}>
            {onAcceptText}
          </Button>
        )}
        {extraButtons.map(({ onClick, name }) => (
          <Button variant='text' onClick={onClick}>
            {name}
          </Button>
        ))}
        {onCancel && (
          <Button variant='text' onClick={onCancel}>
            Cerrar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
