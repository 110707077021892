import * as React from "react";
import { Button } from "@material-ui/core";
import { exportTableToExcel } from "utils/ExportarExcelUtil";
import ExportExcelIco from "static/images/excel.png";
import Table from "components/Tablas/GenericaOrdenadaColumna";
import { dateString } from "utils/DateUtils";

const ButtonDescargaExcel = ({ gastos }) => {
  return (
    <>
      <Button onClick={() => exportTableToExcel("tablaExcel", "gastosEnviados")}>
        <img alt="exportarExcel" src={ExportExcelIco} />
      </Button>
      <Table
        id="tablaExcel"
        orderBy={"feDocumento"}
        withPagination={false}
        showTable={false}
        loading={false}
        headers={[
          { label: "Fecha Doc", id: "feGeneracion" },
          { label: "Nombre Emisor", id: "dsNombreEmisor" },
          { label: "Prefijo", id: "dsPrefijo" },
          { label: "Numero", id: "dsNumero" },
          { label: "Estado Documento", id: "cdEstado" },
        ]}
        tableStyle={{ display: "none" }}
        keys={["feGeneracion", "dsNombreEmisor", "dsPrefijo", "dsNumero", "cdEstado"]}
        detalles={(gastos || []).map((gasto) => ({ ...gasto, feGeneracion: dateString(gasto.feGeneracion).format("DD/MM/YYYY HH:mm:ss") }))}
      />
    </>
  );
};

export default ButtonDescargaExcel;
