import React, { useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from 'components/molecules/Input';
import Typography from 'components/atoms/Typography';

function InformacionCaja({ setDocumento, documento, ...rest }) {
  const info = useMemo(
    () => documento.informacionCajaVenta || {},
    [documento.informacionCajaVenta],
  );

  useEffect(() => {
    setInformacion({ subTotal: documento.totalBruto });
  }, [documento.totalBruto]);

  const setInformacion = (data) => {
    setDocumento((doc) => {
      const { informacionCajaVenta } = doc;
      return {
        ...doc,
        codigoEmpleado: info.cajero,
        informacionCajaVenta: { ...informacionCajaVenta, ...data },
      };
    });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setInformacion({ [name]: value });
  };

  return (
    <Grid container {...rest}>
      <Grid item lg={12}>
        <Typography style={{ fontWeight: 'bold' }}>Información de Caja</Typography>
      </Grid>
      <Grid container lg={12}>
        <Input
          className='form-group col-lg-6'
          label='Placa Caja*'
          name='placaCaja'
          value={info.placaCaja}
          onChange={handleChange}
        />
        <Input
          className='form-group col-lg-6'
          label='Ubicación Caja*'
          name='ubicacionCaja'
          value={info.ubicacionCaja}
          onChange={handleChange}
        />
        <Input
          className='form-group col-lg-6'
          label='Código empleado*'
          name='cajero'
          value={info.cajero}
          onChange={handleChange}
        />
        <Input
          className='form-group col-lg-6'
          label='Tipo Caja*'
          name='tipoCaja'
          value={info.tipoCaja}
          onChange={handleChange}
        />
        <Input
          className='form-group col-lg-12'
          label='Código Venta*'
          name='codigoVenta'
          value={info.codigoVenta}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
}

export default InformacionCaja;
