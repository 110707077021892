import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import Login from 'screens/login/Login';
import MainPage from '../components/MainPage/Home';
import ForgotPassword from 'screens/login/ForgotPassword';
import ChangePassword from 'screens/login/changePassword';
import ProtectedRoute from 'screens/login/auth/ProtectedRoute';
import Saldo from '../components/saldo/saldo';
import FacturaV2 from '../components/FacturaV2/generarFactura';
import Registrarse from 'screens/RegistrarUsuario';
import NotFound from './NotFound';
import TerminarRegistro from 'screens/TerminarRegistro';
import ResultadoRecarga from '../components/saldo/resultado-recarga';
import Adquirentes from 'screens/Adquirentes';
import Productos from 'screens/Productos';
import Ventas from 'screens/Ventas/Ventas';
import FacturasEnviadasSevicioCliente from 'screens/DocumentosEnviadosServicioCliente';
import PreguntasFrecuentes from '../components/landingPage/preguntasFrecuentes';
import GestionFirmaDigital from '../components/ServicioCliente/GestionFirmaDigital/GestionFirmaDigital';
import GestionarFirmaDigitalEmisor from 'screens/GestionarFirmasEmisor';
import GestionarResolucionesEmisor from 'screens/GestionResolucionEmisor';
import GestionarResoluciones from '../components/ServicioCliente/GestionResoluciones/GestionarResoluciones';
import HomeDashboard from 'screens/Home';
import MovimientosInventario from 'screens/MovimientoInventario';
import ResultadoRecargaSaldo from 'screens/Recargar/ResultadoRecargaSaldo';
import CargaMasiva from 'screens/dashboard/Emisor/CargaMasiva/CargaMasiva';
import RecargarSaldo from 'screens/Recargar/Recarga';
import Notificaciones from 'screens/Notificaciones';
import GeneracionPin from 'screens/GenerarPinDistribuidor';
import EstadosPin from 'screens/EstadoPinesDistribuidor';
import Parqueadero from 'screens/Parqueadero';
import ReporteParqueadero from 'screens/Parqueadero/Reporte';
import EstadisticasServicioCliente from 'screens/dashboard/ServicioCliente/Estadisticas/Estadisticas';
import EstadisticasEmisor from 'screens/dashboard/Emisor/Reportes/Estadistidas';
import Empleado from 'screens/Empleado';
import SoportePago from 'screens/SoportePago';
import ConfiguracionEmisor from 'screens/ConfiguracionEmisor';
import Anualidad from 'screens/dashboard/ServicioCliente/Anualidad';
import NuevoEmisor from 'screens/NuevoEmisor';
import Ayudas from 'screens/dashboard/Emisor/Ayuda';
import GestionarAyudas from 'screens/dashboard/ServicioCliente/Ayuda';
import FacturasEnviadas from 'screens/DocumentosEnviados';
import DocumentosNominaEnviados from 'screens/DocumentosNominaEnviados';
import DocumentoPos from 'screens/EnvioDocumentoPos';
import TirillaDocumentoPos from 'screens/Tirilla';
import DocumentoPosEnviados from 'screens/DocumentoPosEnviados';
import GestionarAnualidad from 'screens/dashboard/ServicioCliente/GestionarAnualidad';
import FacturasErroneas from 'screens/DocumentosErroneos';
import HistorialCajaMenor from 'screens/HistorialSaldoCaja';
import DocumentoExcel from 'screens/DocumentoExcel';
import CotizacionDocumento from 'screens/CotizacionDocumento';
import ConsultasCotizacion from 'screens/ConsultasCotizacion';
import Gastos from 'screens/Gastos';
import DocumentoSoporteAdquisicion from 'screens/DocumentoSoporteAdquisicion';
import ReporteGastosEmail from 'screens/ReporteGastosEmail';
import Asesor from 'screens/Asesor';
import SolicitarTurno from 'screens/SolicitarTurno';
import AsociarTiquetera from 'screens/AsociarTiquetera';
import DocumentoExpress from 'screens/DocumentoExpress';
import ConsultarDocumentoExpress from 'screens/ConsultarDocumentoExpress';
import CrearEmisorExpress from 'screens/CrearEmisorExpress';
import Solicitudes from 'screens/Solicitud';
import GestionarSolicitudes from 'screens/GestionarSolicitudes';
import MisSolicitudes from 'screens/MisSolicitudes';
import NuevaEmision from 'components/Documento/NuevaEmision/generarFactura';
import RegistroAdquirente from 'screens/RegistroAdquirente';
import InformeVentasPos from 'screens/InformeVentasPos';
import HistorialTransacciones from 'screens/HistorialTransacciones';
import Aliados from 'screens/Aliados';
import NuevoSocio from 'screens/NuevoSocio';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={MainPage} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/recarga' component={ResultadoRecargaSaldo} />
        <Route exact path='/preguntas-frecuentes' component={PreguntasFrecuentes} />
        <Route exact path='/nuevo-emisor' component={NuevoEmisor} />
        <Route exact path='/resultado-recarga' component={ResultadoRecarga} />
        <Route exact path='/recordar-password' component={ForgotPassword} />
        <Route
          exact
          path='/emision-documento'
          component={withAuthenticator(ProtectedRoute(NuevaEmision), false, [<Login />])}
        />
        <Route
          exact
          path='/facturaV2'
          component={withAuthenticator(ProtectedRoute(FacturaV2), false, [<Login />])}
        />
        <Route
          exact
          path='/facturas-enviadas'
          component={withAuthenticator(ProtectedRoute(FacturasEnviadas), false, [<Login />])}
        />
        <Route
          exact
          path='/adquirentes'
          component={withAuthenticator(ProtectedRoute(Adquirentes), false, [<Login />])}
        />
        <Route
          exact
          path='/productos'
          component={withAuthenticator(ProtectedRoute(Productos), false, [<Login />])}
        />
        <Route
          exact
          path='/ayudas'
          component={withAuthenticator(ProtectedRoute(Ayudas), false, [<Login />])}
        />
        <Route
          exact
          path='/nuevaAyuda'
          component={withAuthenticator(ProtectedRoute(GestionarAyudas), false, [<Login />])}
        />
        <Route
          exact
          path='/registrarse'
          component={withAuthenticator(ProtectedRoute(Registrarse), false, [<Login />])}
        />
        <Route
          exact
          path='/cambiar-password'
          component={withAuthenticator(ProtectedRoute(ChangePassword), false, [<Login />])}
        />
        <Route
          exact
          path='/saldo'
          component={withAuthenticator(ProtectedRoute(Saldo), false, [<Login />])}
        />
        <Route
          exact
          path='/gestionarFirma'
          component={withAuthenticator(ProtectedRoute(GestionFirmaDigital), false, [<Login />])}
        />
        <Route
          exact
          path='/gestionarFirmaEmisor'
          component={withAuthenticator(ProtectedRoute(GestionarFirmaDigitalEmisor), false, [
            <Login />,
          ])}
        />
        <Route exact path='/terminar_registro' component={TerminarRegistro} />
        <Route
          exact
          path='/ventas'
          component={withAuthenticator(ProtectedRoute(Ventas), false, [<Login />])}
        />
        <Route
          exact
          path='/facturasErroneas'
          component={withAuthenticator(ProtectedRoute(FacturasErroneas), false, [<Login />])}
        />
        <Route
          exact
          path='/recargar_saldo'
          component={withAuthenticator(ProtectedRoute(RecargarSaldo), false, [<Login />])}
        />
        <Route
          exact
          path='/configuracion_emisor'
          component={withAuthenticator(ProtectedRoute(ConfiguracionEmisor), false, [<Login />])}
        />
        <Route
          exact
          path='/facturas-enviadas-servicio-cliente'
          component={withAuthenticator(ProtectedRoute(FacturasEnviadasSevicioCliente), false, [
            <Login />,
          ])}
        />
        <Route
          exact
          path='/gestionarResolucionesEmisor'
          component={withAuthenticator(ProtectedRoute(GestionarResolucionesEmisor), false, [
            <Login />,
          ])}
        />
        <Route
          exact
          path='/gestionarResoluciones'
          component={withAuthenticator(ProtectedRoute(GestionarResoluciones), false, [<Login />])}
        />
        <Route
          exact
          path='/movimientoInventario'
          component={withAuthenticator(ProtectedRoute(MovimientosInventario), false, [<Login />])}
        />
        <Route
          exact
          path='/Home'
          component={withAuthenticator(ProtectedRoute(HomeDashboard), false, [<Login />])}
        />
        <Route
          exact
          path='/parqueadero'
          component={withAuthenticator(ProtectedRoute(Parqueadero), false, [<Login />])}
        />
        <Route
          exact
          path='/ReporteParqueadero'
          component={withAuthenticator(ProtectedRoute(ReporteParqueadero), false, [<Login />])}
        />
        <Route
          exact
          path='/Notificaciones'
          component={withAuthenticator(ProtectedRoute(Notificaciones), false, [<Login />])}
        />
        <Route
          exact
          path='/CargaMasiva'
          component={withAuthenticator(ProtectedRoute(CargaMasiva), false, [<Login />])}
        />
        <Route
          exact
          path='/GenerarPin'
          component={withAuthenticator(ProtectedRoute(GeneracionPin), false, [<Login />])}
        />
        <Route
          exact
          path='/EstadosPin'
          component={withAuthenticator(ProtectedRoute(EstadosPin), false, [<Login />])}
        />
        <Route
          exact
          path='/EstadisticasServicio'
          component={withAuthenticator(ProtectedRoute(EstadisticasServicioCliente), false, [
            <Login />,
          ])}
        />
        <Route
          exact
          path='/EstadisticasEmisor'
          component={withAuthenticator(ProtectedRoute(EstadisticasEmisor), false, [<Login />])}
        />
        <Route
          exact
          path='/Empleado'
          component={withAuthenticator(ProtectedRoute(Empleado), false, [<Login />])}
        />
        <Route
          exact
          path='/SoporteNomina'
          component={withAuthenticator(ProtectedRoute(SoportePago), false, [<Login />])}
        />
        <Route
          exact
          path='/anualidad'
          component={withAuthenticator(ProtectedRoute(Anualidad), false, [<Login />])}
        />
        <Route
          exact
          path='/gestionaranualidad'
          component={withAuthenticator(ProtectedRoute(GestionarAnualidad), false, [<Login />])}
        />
        <Route
          exact
          path='/informenomina'
          component={withAuthenticator(ProtectedRoute(DocumentosNominaEnviados), false, [
            <Login />,
          ])}
        />
        <Route
          exact
          path='/documentopos'
          component={withAuthenticator(ProtectedRoute(DocumentoPos), false, [<Login />])}
        />
        <Route exact path='/tirilla' component={ProtectedRoute(TirillaDocumentoPos)} />
        <Route
          exact
          path='/documentosposenviados'
          component={withAuthenticator(ProtectedRoute(DocumentoPosEnviados), false, [<Login />])}
        />
        <Route
          exact
          path='/historialcaja'
          component={withAuthenticator(ProtectedRoute(HistorialCajaMenor), false, [<Login />])}
        />
        <Route
          exact
          path='/documentoexcel'
          component={withAuthenticator(ProtectedRoute(DocumentoExcel), false, [<Login />])}
        />
        <Route
          exact
          path='/cotizacion'
          component={withAuthenticator(ProtectedRoute(CotizacionDocumento), false, [<Login />])}
        />
        <Route
          exact
          path='/consultarcotizacion'
          component={withAuthenticator(ProtectedRoute(ConsultasCotizacion), false, [<Login />])}
        />
        <Route
          exact
          path='/gastos'
          component={withAuthenticator(ProtectedRoute(Gastos), false, [<Login />])}
        />
        <Route
          exact
          path='/documentosoporte'
          component={withAuthenticator(ProtectedRoute(DocumentoSoporteAdquisicion), false, [
            <Login />,
          ])}
        />
        <Route
          exact
          path='/reportegastosemail'
          component={withAuthenticator(ProtectedRoute(ReporteGastosEmail), false, [<Login />])}
        />
        <Route
          exact
          path='/asesor'
          component={withAuthenticator(ProtectedRoute(Asesor), false, [<Login />])}
        />
        <Route exact path='/solicitarturno' component={SolicitarTurno} />
        <Route
          exact
          path='/asociar-tiquetera'
          component={withAuthenticator(ProtectedRoute(AsociarTiquetera), false, [<Login />])}
        />
        <Route
          exact
          path='/documento-express'
          component={withAuthenticator(ProtectedRoute(DocumentoExpress), false, [<Login />])}
        />
        <Route exact path='/documentoexpress' component={ConsultarDocumentoExpress} />
        <Route
          exact
          path='/crear-emisor-express'
          component={withAuthenticator(ProtectedRoute(CrearEmisorExpress), false, [<Login />])}
        />
        <Route
          exact
          path='/solicitudes'
          component={withAuthenticator(ProtectedRoute(Solicitudes), false, [<Login />])}
        />
        <Route
          exact
          path='/gestionar-solicitudes'
          component={withAuthenticator(ProtectedRoute(GestionarSolicitudes), false, [<Login />])}
        />
        <Route
          exact
          path='/mis-solicitudes'
          component={withAuthenticator(ProtectedRoute(MisSolicitudes), false, [<Login />])}
        />
        <Route exact path='/registro-adquirente' component={RegistroAdquirente} />
        <Route
          exact
          path='/info-ventas-pos'
          component={withAuthenticator(ProtectedRoute(InformeVentasPos), false, [<Login />])}
        />
        <Route
          exact
          path='/historial-transacciones'
          component={withAuthenticator(ProtectedRoute(HistorialTransacciones), false, [<Login />])}
        />
        <Route
          exact
          path='/aliados'
          component={withAuthenticator(ProtectedRoute(Aliados), false, [<Login />])}
        />
        <Route 
        exact 
        path='/nuevo-socio' 
        component={NuevoSocio} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}
export default App;
