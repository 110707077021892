import * as React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Table from 'components/Tablas/GenericaOrdenadaColumna';
import RegistrarGasto from './RegistrarGasto';
import { consultarGastos } from 'network/Api/Gasto';
import FiltroConsultas from './FiltroConsultas';
import { now, dateString } from 'utils/DateUtils';
import ActualizarEstado from './ActualizarEstadoEvento';
import { SetSpinnerModal } from 'reducers/actions/facturaActions';
import Modal from 'components/modal/ModalConfirmacion';
import ButtonDescargaArchivos from 'components/organisms/ButtonDescargaArchivos';
import Icon from 'components/molecules/Icon';

const Gastos = ({ userAttributes, setSpinnerModal }) => {
  const [state, setData] = React.useState({
    feInicio: now().format('YYYY-MM-01'),
    feFin: now().format('YYYY-MM-DD'),
    openModal: false,
  });
  const setState = (data) => setData((s) => ({ ...s, ...data }));

  React.useEffect(() => {
    if (userAttributes.codigoEmisor) consultar();
  }, [userAttributes]);

  const consultar = () => {
    const { feInicio, feFin, prefijo, numero, nombreEmisor, cufe } = state;
    const { codigoEmisor } = userAttributes;
    setState({ loading: true });
    consultarGastos({
      emisor: codigoEmisor,
      feInicio,
      feFin,
      prefijo,
      numero,
      nombreEmisor,
      cufe,
    }).then(({ data }) => setState({ gastos: data, loading: false }));
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const openModal = (mensaje) =>
    setState({
      openModal: true,
      mensaje,
      onModalAccept: () => setState({ openModal: false }),
    });

  const openModalOptions = ({
    mensaje,
    onAccept,
    onCancel = () => setState({ openModal: false }),
  }) =>
    setState({
      openModal: true,
      mensaje,
      onModalAccept: onAccept,
      onModalCancel: onCancel,
    });

  return (
    <Grid container xs={12}>
      <RegistrarGasto
        emisor={userAttributes.codigoEmisor}
        consultarGastos={consultar}
        loading={setSpinnerModal}
        openModal={openModal}
        openModalOptions={openModalOptions}
      />
      <FiltroConsultas handleChange={handleChange} consultarGastos={consultar} state={state} />
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Table
            orderBy={'feDocumento'}
            loading={state.loading}
            headers={[
              { label: 'Fecha documento', id: 'feProceso' },
              { label: 'Nombre emisor', id: 'dsNombreEmisor' },
              { label: 'Prefijo', id: 'dsPrefijo' },
              { label: 'Número', id: 'dsNumero' },
              { label: 'CUFE', id: 'dsCufe' },
              { label: 'Estado', id: 'estadosDocumento' },
              { label: 'Descargar XML', id: 'btnDescarga' },
            ]}
            keys={[
              'feProceso',
              'dsNombreEmisor',
              'dsPrefijo',
              'dsNumero',
              'dsCufe',
              'estadosDocumento',
              'btnDescarga',
            ]}
            detalles={(state.gastos || []).map((gasto) => ({
              ...gasto,
              feProceso: dateString(gasto.feProceso).format('DD/MM/YYYY HH:mm:ss'),
              estadosDocumento: (
                <ActualizarEstado
                  gasto={gasto}
                  emisor={userAttributes.codigoEmisor}
                  consultarGastos={consultar}
                  loading={setSpinnerModal}
                  openModal={openModal}
                />
              ),
              btnDescarga: (
                <ButtonDescargaArchivos
                  fileName={gasto.dsUrlXml}
                  icon={<Icon icon='xls' height='35' width='35' />}
                />
              ),
            }))}
          />
        </Grid>
      </Grid>
      <Modal
        open={state.openModal}
        content={<div>{state.mensaje}</div>}
        onAccept={state.onModalAccept}
        onCancel={state.onModalCancel}
      />
    </Grid>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

const mapDispatchToProps = (dispatch) => ({
  setSpinnerModal: (item) => dispatch(SetSpinnerModal(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gastos);
