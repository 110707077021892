import * as React from 'react';
import Modal from 'components/modal/ModalConfirmacion';
import Input from 'components/Inputs/Input';
import { generarNotaCreditoDocumento } from 'network/Api/Documento';
import { generarNotaCreditoSoporte } from 'network/Api/DocumentoSoporte';

const NotaModal = ({ onClose, nmId, onConsulta, openModal, tipo, prefijoFactura, tipoNota }) => {
  const [dsPrefijo, setState] = React.useState();
  const [dsNumeroFactura, setNumero] = React.useState();

  const enviarNotaCredito = () => {
    if (dsPrefijo !== prefijoFactura) {
      if (dsNumeroFactura > 0) {
        if (tipo === 'FACTURACION')
          generarNotaCreditoDocumento({
            nmId,
            dsPrefijo,
            dsNumeroFactura,
            tipoDocumentoElectronico: tipoNota,
          }).then(() => onConsulta());
        if (tipo === 'SOPORTE')
          generarNotaCreditoSoporte({ nmId, dsPrefijo, dsNumeroFactura }).then(() => onConsulta());
        onClose();
        setState('');
        setNumero('');
        openModal('Enviando documento en unos momentos podra consultarlo');
      }
    } else openModal('El prefijo de la Nota debe ser diferente al de la factura');
  };

  return (
    <Modal
      open={nmId}
      title={`Datos ${tipoNota === 'NOTA_DEBITO' ? 'Nota Debito' : 'Nota Crédito'}`}
      content={
        <>
          <Input
            label='Prefijo'
            onChange={({ target }) => setState(target.value.toUpperCase())}
            value={dsPrefijo}
          />
          <Input
            label='Número'
            onChange={({ target }) => setNumero(target.value)}
            data-validation='only-num'
            value={dsNumeroFactura}
          />
        </>
      }
      onCancel={onClose}
      onAccept={enviarNotaCredito}
    />
  );
};

export default NotaModal;
