import * as React from "react";
import Input from "components/Inputs/Input";
import LabelTitulos from "components/layout/labelTitulos";
import { transferirSaldoCajaMenor } from "network/Api/SaldoDinero";
import Modal from "components/modal/ModalConfirmacion";

const TransferirSaldo = ({ userAttributes }) => {
  const [state, setData] = React.useState({});
  const [modal, setModal] = React.useState({ open: false });

  const setState = (data) => setData((s) => ({ ...s, ...data }));

  const openModal = (o) => setModal((m) => ({ ...m, ...o, open: true }));

  const closeModal = () => setModal((m) => ({ ...m, open: false }));

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState({ [name]: value });
  };

  const transferirSaldo = async (e) => {
    e.preventDefault();
    const error = validarCampos();
    if (!error) {
      const { codigoEmisor } = userAttributes;
      const { nmValor, dsIdentificacionReceptor, dsCelular } = state;
      const { data } = await transferirSaldoCajaMenor({ emisor: codigoEmisor, nmValor, dsIdentificacionReceptor, dsCelular });
      if (data === "SIN_SALDO") openModal({ title: "Error validaciones", content: "Cuenta sin suficiente saldo para transferir" });
      if (data === "RECEPTOR_INEXISTENTE")
        openModal({
          title: "Error validaciones",
          content: `El emisor con identificacion ${dsIdentificacionReceptor} no existe, por favor ingresar un numero de celular para enviar un PIN de registro`,
        });
      if (data === "PADRE") {
        setData({});
        openModal({ title: "Exitoso", content: "El emisor ya cuenta con un referido" });
      }
      if (data === "RECEPTOR_INEXISTENTE") {
        setData({});
        openModal({
          title: "Exitoso",
          content: `Se envio un mensaje al numero ${dsCelular} con un PIN para completar el registro`,
        });
      }
      if (data === "OK") {
        setData({});
        openModal({ title: "Exitoso", content: "Saldo transferido exitosamente" });
      }
    } else openModal({ title: "Error validaciones", content: error });
  };

  const validarCampos = () => {
    const { nmValor, dsIdentificacionReceptor } = state;
    if (!nmValor || (nmValor <= 0 || nmValor > 1000000)) return "El valor es obligatorio y debe ser superior a 0 e inferior o igual a 1.000.000";
    if (!dsIdentificacionReceptor) return "La identificacion es obligatoria";
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
      <div className="card card-signin">
        <div className="card-body">
          <LabelTitulos texto="Transferir Saldo" />
          <div className="form-row col-md-12">
            <div className="form-group col-md-12 col-xl-12">
              <Input label="Valor a transferir" value={state.nmValor} onChange={handleChange} name="nmValor" />
            </div>
            <div className="form-group col-md-12 col-xl-12">
              <Input
                label="Identificación del emisor"
                value={state.dsIdentificacionReceptor}
                onChange={handleChange}
                name="dsIdentificacionReceptor"
                data-validation="only-num"
              />
            </div>
            <div className="form-group col-md-12 col-xl-12">
              <Input label="Celular del emisor" value={state.dsCelular} onChange={handleChange} name="dsCelular" />
            </div>
          </div>
          <div className="form-row col-md-12">
            <button className="btn btn-lg btn-primary btn-block text-uppercase" onClick={transferirSaldo}>
              Transferir
            </button>
          </div>
        </div>
      </div>
      <Modal {...modal} onAccept={closeModal} />
    </div>
  );
};

export default TransferirSaldo;
