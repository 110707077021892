import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "components/Factura/styles.css";
import ProductosDetalle from "./ProductosDetalle";

const styles = () => ({
  root: {
    flexGrow: 2,
    maxWidth: "100%",
  },
  fontFamily: {
    fontFamily: "Quicksand",
  },
});

function Productos({ classes }) {
  return (
    <div className={classes.root}>
      <form className={classes.fontFamily} onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <ProductosDetalle />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default withStyles(styles)(Productos);
