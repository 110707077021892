import * as React from "react";
import "./style.css";
import { moneyFormat, percentFormat } from "utils/TextFormattingUtils";
import { connect } from "react-redux";
import QRCode from "qrcode";

const VisualizacionDocumento = ({
  documentoJson = {},
  userAttributes,
  response,
}) => {
  const [qr, setQr] = React.useState();

  const getVacio = (value) => value || "";

  const getValorTributo = (tributo) => {
    let valor = 0;
    documentoJson.impuestosRetenciones.forEach((impuestoRetencion) => {
      let subtotal = impuestoRetencion.subtotales[0].tributo;
      if (subtotal === tributo) valor = impuestoRetencion.valorTotal;
    });
    return valor;
  };

  React.useEffect(() => {
    QRCode.toDataURL(documentoJson.qrCode || "", (err, url) => setQr(url));
  }, []);

  return (
    <div className="body">
      <div className="header_custom">
        <h3 className="title_custom_visual">
          Documento Soporte en Adquisiciones Efectuadas a No Obligados a
          Facturar
        </h3>
        <figure className="wrap_img">
          <img src={qr} alt="codigo qr" className="img" />
        </figure>
      </div>
      <div className="content_custom_visual">
        <div className="wrap_data">
          <div>
            <ul className="data_list">
              <li style={{ fontWeight: "bold" }}>Información del Vendedor</li>
              <li>{`${getVacio(documentoJson.nombresVendedor)} ${getVacio(
                documentoJson.segundoNombre
              )} ${getVacio(documentoJson.primerApellido)} ${getVacio(
                documentoJson.segundoApellido
              )}`}</li>
              <li>
                {documentoJson.tipoIdentificacionVendedor}:{" "}
                {documentoJson.identificacionVendedor}
              </li>
              <li>Dirección: {documentoJson.direccionVendedor}</li>
              <li>Teléfono: {documentoJson.telefonoVendedor}</li>
              <li>Correo: {documentoJson.emailVendedor}</li>
              <li>
                {documentoJson.ciudadVendedor.dsNombre} -{" "}
                {documentoJson.ciudadVendedor.departamento.dsNombre}
              </li>
              <li>
                Responsabilidad fiscal:{" "}
                {documentoJson.responsabilidadesFiscales}
              </li>
              <li>Procedencia: {documentoJson.tipoOperacion}</li>
            </ul>
            <ul className="data_list">
              <li style={{ fontWeight: "bold" }}>Información del Comprador</li>
              <li>{`${getVacio(userAttributes.nombresEmisor)} ${getVacio(
                userAttributes.segundoNombre
              )} ${getVacio(userAttributes.primerApellido)} ${getVacio(
                userAttributes.segundoApellido
              )}`}</li>
              <li>
                {userAttributes.tipoIdentificacion}:{" "}
                {userAttributes.identificacionEmisor}
              </li>
              <li>Dirección: {userAttributes.direccion}</li>
              <li>Teléfono: {userAttributes.phone_number}</li>
              <li>Correo: {userAttributes.email}</li>
              <li>
                {userAttributes.ciudadEmisor &&
                  userAttributes.ciudadEmisor.dsNombre}{" "}
                -{" "}
                {userAttributes.ciudadEmisor &&
                  userAttributes.ciudadEmisor.departamento.dsNombre}
              </li>
            </ul>
          </div>
          <div className="data_factura">
            <p>CUDS:</p>
            <p>
              {(response || {}).cuds ||
                (response || {}).cufe ||
                (response || {}).cude}
            </p>
            <p>Fecha de certificación: {(response || {}).fecha}</p>
            <p>
              Documento de Soporte:{" "}
              {documentoJson.dsPrefijo + "-" + documentoJson.dsNumeroFactura}
            </p>
            <p className="data_left">
              Fecha Emisión: {documentoJson.fechaEmision}
            </p>
            <p className="data_left">
              Forma de Pago: {documentoJson.pago.formaPago}
            </p>
            <p className="data_left">
              Medio de Pago: {documentoJson.pago.medioPago}
            </p>
          </div>
        </div>
        <table className="table">
          <tr className="table_header">
            <th>Nro</th>
            <th>Codígo</th>
            <th>Descripcíon</th>
            <th>U.M.</th>
            <th>Cantidad</th>
            <th>Valor Unitario</th>
            <th>% IVA</th>
            <th>% INC</th>
            <th>Descuento</th>
            <th>Valor Total</th>
          </tr>
          {documentoJson.detalles.map((detalle, index) => (
            <tr className="table_row">
              <td>{index + 1}</td>
              <td>{detalle.codigoArticulo}</td>
              <td>{detalle.descripcionArticulo}</td>
              <td>{detalle.unidadMedida}</td>
              <td>{detalle.cantidad}</td>
              <td>{moneyFormat(detalle.precioUnitario)}</td>
              <td>{percentFormat(detalle.porcentajeIva)}</td>
              <td>{percentFormat(detalle.porcentajeConsumo)}</td>
              <td>{moneyFormat(detalle.valorTotalDescuento)}</td>
              <td>{moneyFormat(detalle.valorTotalArticulo)}</td>
            </tr>
          ))}
        </table>
        <div className="total_factura">
          <div className="wrap_columns">
            <div className="col_custom">
              <div className="row_custom">
                <div className="row_title">Rete Fuente:</div>
                <div className="row_value">
                  {moneyFormat(getValorTributo("RETE_RENTA"))}
                </div>
              </div>
              <div className="row_custom">
                <div className="row_title">Descuento:</div>
                <div className="row_value">
                  {moneyFormat(documentoJson.descuentoTotal)}
                </div>
              </div>
              <div className="row_custom">
                <div className="row_title">Total IVA:</div>
                <div className="row_value">
                  {moneyFormat(getValorTributo("IVA"))}
                </div>
              </div>
              <div className="row_custom">
                <div className="row_title">Total INC:</div>
                <div className="row_value">
                  {moneyFormat(getValorTributo("INC"))}
                </div>
              </div>
            </div>
            <div className="col_custom">
              <div className="row_custom">
                <div className="row_title">Rete ICA:</div>
                <div className="row_value">
                  {moneyFormat(getValorTributo("RETE_ICA"))}
                </div>
              </div>
              <div className="row_custom">
                <div className="row_title">Total Bruto:</div>
                <div className="row_value">
                  {moneyFormat(documentoJson.valorBruto)}
                </div>
              </div>
              <div className="row_custom">
                <div className="row_title">Rete IVA:</div>
                <div className="row_value">
                  {moneyFormat(getValorTributo("RETE_IVA"))}
                </div>
              </div>
              <div className="row_custom">
                <div className="row_title">Total a Pagar:</div>
                <div className="row_value">
                  {moneyFormat(documentoJson.totalPagar)}
                </div>
              </div>
            </div>
          </div>
          <div className="total">
            <div className="total_title">Total en letras:</div>
            <div className="total_value">{documentoJson.valorNetoLetras}</div>
          </div>
        </div>
      </div>
      <div className="footer_doc">
        {documentoJson.resolucionObject && (
          <p>
            {`AUTORIZACION DE NUMERACION ${
              documentoJson.resolucionObject.dsResolucionDian
            } Fecha: ${
              documentoJson.resolucionObject.feInicio
            } Factura ${documentoJson.resolucionObject.dsPrefijo +
              documentoJson.resolucionObject.nmInicio} al ${documentoJson
              .resolucionObject.dsPrefijo +
              documentoJson.resolucionObject.nmFin} con fecha de vencimiento: ${
              documentoJson.resolucionObject.feVencimiento
            }`}
          </p>
        )}
        <p>Observaciones: {documentoJson.dsObservacion}</p>
        <p>Documento generador en WWW.PORTALFACTURA.COM</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state, _) => ({
  userAttributes: state.factura.userAttributes,
});

export default connect(mapStateToProps)(VisualizacionDocumento);
