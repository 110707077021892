import React, { Component } from 'react';
import { registrarAdquirentePos } from 'network/Api/Adquirente';
import './styles.css';
import ReCAPTCHA from 'react-google-recaptcha';

const regEmail = /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/;

class RegistroAdquirente extends Component {
  constructor(props) {
    super(props);
    this.state = { cdTipoIdentificacion: 'CEDULA_CIUDADANIA' };
  }

  // Función para manejar cambios en la selección
  handleSelectChange = (event) => {
    this.setState({
      selectedOption: event.target.value,
    });
  };

  componentDidMount() {
    const url = new URLSearchParams(window.location.search);
    if (url.has('id')) this.setState({ token: url.get('id') });
  }

  showAlert = () => {
    const errores = this.validarCampos();
    if (errores.length === 0) {
      const {
        dsNombre,
        dsIdentificacion,
        dsTelefono,
        dsEmail,
        dsDireccion,
        cdTipoIdentificacion,
        token,
        captcha,
        dsPrimerApellido,
      } = this.state;
      registrarAdquirentePos(
        {
          dsNombre,
          dsIdentificacion,
          dsTelefono,
          dsEmail,
          dsDireccion,
          cdTipoIdentificacion,
          captcha,
          dsPrimerApellido,
        },
        token,
      ).then(({ data }) => {
        if (data) alert('El usuario ha sido registrado');
        else alert('El usuario ha ya existe');
        this.reloadPage();
      });
    } else alert(errores.join(' - '));
  };

  validarCampos = () => {
    const errores = [];
    const {
      dsNombre = '',
      dsIdentificacion = '',
      dsEmail = '',
      captcha,
      dsPrimerApellido = '',
      cdTipoIdentificacion,
    } = this.state;
    if (dsNombre.length > 40 || dsNombre.length < 3)
      errores.push('El nombre debe ser mayor a 3 caracteres');
    if (dsIdentificacion.length > 20 || dsIdentificacion.length < 5)
      errores.push('El identificacion debe ser mayor a 5 caracteres');
    if (!dsEmail.match(regEmail)) errores.push('El email es invalido');
    if (!captcha) errores.push('El Captcha es obligatorio');
    if (cdTipoIdentificacion !== 'NIT') {
      if (dsPrimerApellido.length > 40 || dsPrimerApellido.length < 3)
        errores.push('El apellido debe ser mayor a 3 caracteres');
    }
    return errores;
  };

  // Función para recargar la página
  reloadPage = () => {
    window.location.reload();
  };

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleChangeNumber = (event) => {
    const { value, name, maxLength } = event.target;
    const prevVal = this.state[name] || '';
    this.setState({ [name]: maxLength >= value.length ? value : prevVal });
  };

  onChangeCaptcha = (captcha) => this.setState({ captcha });

  render() {
    const { dsNombre, dsIdentificacion, dsEmail, cdTipoIdentificacion, dsPrimerApellido } =
      this.state;
    return (
      <form className='from-row col-md-12' onSubmit={(e) => e.preventDefault()}>
        <div className='form-row col-md-12 d-flex justify-content-end' />
        <h1 className='titulo_principal' style={{ marginTop: '30px' }}>
          Registro de Cliente
        </h1>
        <div className='form-row col-lg-12 tarjeta-detalle' style={{ marginTop: '30px' }}>
          <div className='form-row col-lg-12'>
            <div className='form-group col-xl-2'>
              <label className='titulos_label'>Nombres*</label>
              <input
                className='form-control'
                type='text'
                maxLength={40}
                name='dsNombre'
                value={dsNombre}
                onChange={this.handleChange}
              />
            </div>
            <div className='form-group col-xl-2'>
              <label className='titulos_label'>Apellidos</label>
              <input
                className='form-control'
                type='text'
                maxLength={40}
                name='dsPrimerApellido'
                value={dsPrimerApellido}
                onChange={this.handleChange}
              />
            </div>
            <div className='form-group col-xl-4'>
              <label className='titulos_label'>Tipo Identificación*</label>
              <select
                className='form-control'
                name='cdTipoIdentificacion'
                value={cdTipoIdentificacion}
                onChange={this.handleChange}
              >
                <option value='CEDULA_CIUDADANIA'>Cédula de ciudadanía</option>
                <option value='NIT'>NIT</option>
                <option value='CEDULA_EXTRANJERIA'>Cédula de extranjería</option>
              </select>
            </div>
            <div className='form-group col-xl-4'>
              <label className='titulos_label'>Identificación*</label>
              <input
                className='form-control'
                type='number'
                maxLength={20}
                name='dsIdentificacion'
                value={dsIdentificacion}
                onChange={this.handleChangeNumber}
              />
            </div>
            <div className='form-group col-xl-4'>
              <label className='titulos_label'>Email*</label>
              <input
                className='form-control'
                type='email'
                name='dsEmail'
                maxLength={100}
                value={dsEmail}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <ReCAPTCHA
          sitekey='6LcnrgYpAAAAAC2F1RM8cSk702ds3vDoRcmEuQbh'
          onChange={this.onChangeCaptcha}
        />
        <button className='buttonSended' onClick={this.showAlert}>
          Registrarse
        </button>
      </form>
    );
  }
}

export default RegistroAdquirente;
