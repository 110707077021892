export const fileDownload = ({ type = 'application/pdf', blob, name }) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const file = new Blob([blob], { type });
  const url = URL.createObjectURL(file);
  a.href = url;
  a.id = name;
  a.download = name;
  a.click();
  document.body.removeChild(a);
};

export const printHTML = (html) => {
  const popupWin = window.open();
  popupWin.document.open();
  popupWin.document.write(html);
  popupWin.document.close();
  popupWin.onload = () => {
    popupWin.print();
  };
};
