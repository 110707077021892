/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  SetDatosDocumento,
  SetModalFacturasReferencia,
  SetFacturasReferencia,
  SetModalDocumentosAdicionales,
  SetDocumentosAdicionales,
  SetIsFacturaExportacion,
  SetConfirmacionModalState,
  SetSpinnerModal,
} from "reducers/actions/facturaActions";
import { SetOpenModalGenerico } from "reducers/actions/UtilsActions";
import { handleKeyPressNumeros, handleKeyPressTexto, handleKeyPressTextoNumeros, ordenarArray } from "utils/funcionesUtiles";
import * as ApiResolucion from "network/Api/Resolucion";
import { consultarDocumentoExistente } from "network/Api/Documento";
import { getDateFromArray } from "utils/DateUtils";
import moment from "moment";
import { consultarUltimosDiezDocumentos } from "network/Api/Documento";
import 'bootstrap/dist/css/bootstrap.min.css';

const styles = {
  fontFamily: { whiteSpace: "nowrap" },
  verFacturas: {
    whiteSpace: "nowrap",
    color: "#128BCC",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
  },
};

let modal;
let sinPrefijo = "SIN PREFIJO";

let headersUltimosDocumentos = ["Resolución", "Tipo Documento", "Prefijo", "Numero Documento", "Nombre del adquirente", "Fecha de elaboración"];
let keysUltimosDocumentos = ["resolucion", "tipoDocAdquirente", "prefijoDocumento", "numeroDocumento", "nombresAdquiriente", "feProceso"];

const erroresConsecutivos = {
  SIN_RESOLUCION: "La resolucion se encuentra sin consecutivos",
  FINALIZANDO_RESOLUCION: "La resolucion se encuentra a 10 o menos documentos de finalizarse",
  RESOLUCION_VENCIDA: "La resolucion se encuentra vencida",
};

class DatosDocumento extends Component {

  state = {
    disableControl: true,
    // tipo: "VENTA",
    tipoDocumentoElectronico: "VENTA",
    dsPrefijo: "",
    dsNumeroFactura: "",
    dsPrefijoReferencia: "",
    dsNumeroReferencia: "",
    dsResolucionDian: "",
    listaPrefijos: [],
    unicoPrefijo: false,
    numeroInicial: 0,
    numeroFinal: 0,
    prefijoDisable: true,
    ultimosDocumentos: { lista: [] },
    openDocumentoAdicionalModal: false,
  };

  handleChange = (event) => {
    let value = event.target.value.toUpperCase().replace(/ /g, "");
    const { prefijoDisable } = this.state;
    if (event.target.id == "dsPrefijo") {
      this.setState({ dsPrefijo: value }, () => this.props.dispatch(SetDatosDocumento({ ...this.state })));
    } else {
      if (!prefijoDisable) {
        this.setState({ dsNumeroFactura: value ? parseInt(value).toString() : "" }, () => this.props.dispatch(SetDatosDocumento({ ...this.state })));
      } else {
        this.ValidarRangoFacturacion(value).then((response) => {
          if (response) {
            this.setState({ dsNumeroFactura: value ? parseInt(value).toString() : "" }, () => {
              this.props.dispatch(SetDatosDocumento({ ...this.state }));
            });
          } else {
            modal = {
              open: true,
              text: `El número de factura está por fuera del rango asignado en la resolución de facturación.`,
              onClick: () => this.props.dispatch(SetConfirmacionModalState({ open: false })),
            };
            this.props.dispatch(SetConfirmacionModalState(modal));
          }
        });
      }
    }
  };

  ValidarRangoFacturacion = async (numero) => {
    const { numeroFinal, numeroInicial } = this.state;
    let response = this.ValidarTipoDocumento();
    if (response) {
      return true;
    } else {
      numero = parseFloat(numero);
      let numInicial = numeroInicial;
      let numFinal = numeroFinal;
      numInicial = parseFloat(numInicial);
      numFinal = parseFloat(numFinal);
      if (numero > numeroFinal) {
        return false;
      } else {
        return true;
      }
    }
  };

  ValidarRangoFacturacionOnBlur = () => {
    const { numeroFinal, numeroInicial, dsNumeroFactura } = this.state;
    let response = this.ValidarTipoDocumento();
    if (!response) {
      let numero = dsNumeroFactura;
      let numInicial = numeroInicial;
      let numFinal = numeroFinal;
      numero = parseFloat(numero);
      numInicial = parseFloat(numInicial);
      numFinal = parseFloat(numFinal);
      if (numero > numeroFinal || numero < numeroInicial) {
        modal = {
          open: true,
          text: `El número de factura está por fuera del rango asignado en la resolución de facturación.`,
          onClick: () => {
            this.setState({ dsNumeroFactura: "" }, () => {
              this.props.dispatch(SetConfirmacionModalState({ open: false }));
            });
          },
        };
        this.props.dispatch(SetConfirmacionModalState(modal));
      }
    }
  };

  ValidarTipoDocumento = () => {
    const { tipoDocumentoElectronico } = this.state;
    if (tipoDocumentoElectronico == "NOTA_DEBITO" || tipoDocumentoElectronico == "NOTA_CREDITO") {
      return true;
    } else {
      return false;
    }
  };

  consultarDocumentoExistente = () => {
    const { dsPrefijo, dsNumeroFactura } = this.state;
    const body = {
      emisorId: this.props.userAttributes.codigoEmisor,
      prefijo: dsPrefijo,
      numDoc: dsNumeroFactura,
    };
    consultarDocumentoExistente(body).then((res) => {
      if (res.data) {
        modal = {
          open: true,
          text: `El número de factura ya se encuentra procesado en nuestro sistema.`,
          onClick: () => {
            this.setState({ dsNumeroFactura: "" }, () => {
              this.props.dispatch(SetConfirmacionModalState({ open: false }));
            });
          },
        };
        this.props.dispatch(SetConfirmacionModalState(modal));
      }
    });
  };

  handleKeyPress = (event) => {
    if (!handleKeyPressNumeros(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTexto = (event) => {
    if (!handleKeyPressTexto(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressTextoNumeros = (event) => {
    if (!handleKeyPressTextoNumeros(event)) {
      event.preventDefault();
    }
  };

  handleChangeTipoDocto = (value) => {
    let codigo = value.value;
    const { listaFacturasReferencia } = this.props;
    const { resolucionPrincipal } = this.state;
    this.setState(
      {
        disableControl: true,
        tipo: codigo,
        tipoDocumentoElectronico: codigo,
        dsNumeroFactura: "",
      },
      async () => {
        switch (codigo) {
          case "NOTA_DEBITO":
            this.setState(
              {
                disableControl: false,
                unicoPrefijo: true,
                prefijoDisable: false,
                dsPrefijo: "",
                dsNumeroFactura: "",
              },
              () => this.props.dispatch(SetDatosDocumento({ ...this.state }))
            );
            if (listaFacturasReferencia.length > 0) {
              if (listaFacturasReferencia[0].tipo != "NOTA_DEBITO") {
                await this.props.dispatch(SetFacturasReferencia([]));
              }
            }
            await this.props.dispatch(SetDocumentosAdicionales([]));
            this.props.dispatch(
              SetModalFacturasReferencia({
                open: true,
                tipoDocumento: this.state.tipo,
              })
            );
            await this.props.dispatch(SetIsFacturaExportacion(false));
            break;
          case "NOTA_CREDITO":
            this.setState(
              {
                disableControl: false,
                unicoPrefijo: true,
                prefijoDisable: false,
                dsPrefijo: "",
                dsNumeroFactura: "",
              },
              () => this.props.dispatch(SetDatosDocumento({ ...this.state }))
            );
            if (listaFacturasReferencia.length > 0) {
              if (listaFacturasReferencia[0].tipo != "NOTA_CREDITO") {
                await this.props.dispatch(SetFacturasReferencia([]));
              }
            }
            await this.props.dispatch(SetDocumentosAdicionales([]));
            this.props.dispatch(
              SetModalFacturasReferencia({
                open: true,
                tipoDocumento: this.state.tipo,
              })
            );
            await this.props.dispatch(SetIsFacturaExportacion(false));
            break;
          case "EXPORTACION":
            this.setState(
              {
                dsPrefijoReferencia: "",
                dsNumeroReferencia: "",
                dsNumeroFactura: "",
                unicoPrefijo: false,
                prefijoDisable: true,
                dsPrefijo: resolucionPrincipal.dsPrefijo,
                dsResolucionDian: resolucionPrincipal.dsResolucionDian,
                resolucionDian: resolucionPrincipal.dsResolucionDian,
              },
              () => {
                this.props.dispatch(SetDatosDocumento({ ...this.state }));
              }
            );
            await this.props.dispatch(SetDocumentosAdicionales([]));
            await this.props.dispatch(SetFacturasReferencia([]));
            await this.props.dispatch(SetIsFacturaExportacion(true));
            break;
          default:
            this.setState(
              {
                dsPrefijoReferencia: "",
                dsNumeroReferencia: "",
                dsNumeroFactura: "",
                unicoPrefijo: false,
                prefijoDisable: true,
                dsPrefijo: resolucionPrincipal.dsPrefijo,
                dsResolucionDian: resolucionPrincipal.dsResolucionDian,
                resolucionDian: resolucionPrincipal.dsResolucionDian,
              },
              () => {
                this.props.dispatch(SetDatosDocumento({ ...this.state }));
              }
            );
            await this.props.dispatch(SetDocumentosAdicionales([]));
            await this.props.dispatch(SetFacturasReferencia([]));
            await this.props.dispatch(SetIsFacturaExportacion(false));
        }
      }
    );
  };

  handleChangeResolucionEmisor = (selectedOption) => {
    const { resolucionesConsultadas } = this.state;
    let codigo = selectedOption.value;
    let resoluciones = resolucionesConsultadas.filter((item) => item.dsResolucionDian === codigo)[0];
    this.setState(
      {
        dsResolucionDian: resoluciones.dsResolucionDian,
        resolucionDian: resoluciones.dsResolucionDian,
      },
      () => {
        this.props.dispatch(SetDatosDocumento({ ...this.state }));
      }
    );
    if (resoluciones.prefijos.length > 1) {
      resoluciones.prefijos.map((item) => {
        if (item.dsPrefijo === "" || item.dsPrefijo === null) {
          item.dsPrefijo = sinPrefijo;
        }
      });
      this.setState({
        listaPrefijos: resoluciones.prefijos,
        unicoPrefijo: false,
        dsPrefijo: "",
        dsPrefijoReferencia: "",
      });
    } else {
      let prefijo = resoluciones.prefijos[0].dsPrefijo;
      let numeroInicial = resoluciones.prefijos[0].nmInicio;
      let numeroFinal = resoluciones.prefijos[0].nmFin;
      this.setState(
        {
          listaPrefijos: resoluciones.prefijos,
          unicoPrefijo: true,
          dsPrefijo: prefijo,
          numeroInicial: numeroInicial,
          numeroFinal: numeroFinal,
          snConsecutivoAutomatico: resoluciones.prefijos[0].snConsecutivoAutomatico,
        },
        () => {
          this.props.dispatch(SetDatosDocumento({ ...this.state }));
        }
      );
    }
  };

  handleChangePrefijo = (selectedOption) => {
    const { dsResolucionDian, resolucionesConsultadas } = this.state;
    let codigo = selectedOption.value;
    let prefijo;
    resolucionesConsultadas.map((item) => {
      if (item.dsResolucionDian === dsResolucionDian) {
        prefijo = item.prefijos.filter((item) => item.dsPrefijo === codigo);
      }
    });
    if (codigo === sinPrefijo) codigo = "";
    this.setState(
      {
        dsPrefijo: codigo,
        dsNumeroFactura: "",
        numeroInicial: prefijo[0].nmInicio,
        numeroFinal: prefijo[0].nmFin,
        snConsecutivoAutomatico: prefijo[0].snConsecutivoAutomatico,
      },
      () => this.props.dispatch(SetDatosDocumento({ ...this.state }))
    );
  };

  handleClickAbrirModal = () => {
    const { tipo } = this.state;
    switch (tipo) {
      case "NOTA_DEBITO":
        this.props.dispatch(SetModalFacturasReferencia({ open: true, tipoDocumento: tipo }));
        break;
      case "NOTA_CREDITO":
        this.props.dispatch(SetModalFacturasReferencia({ open: true, tipoDocumento: tipo }));
        break;
      case "CONTINGENCIA_FACTURADOR":
        this.props.dispatch(SetModalDocumentosAdicionales({ open: true, tipoDocumento: tipo }));
        break;
    }
  };

  handleOpenUltimosDocumentos = () => {
    const { userAttributes } = this.props;
    const { ultimosDocumentos } = this.state;
    this.props.dispatch(SetSpinnerModal(true));
    consultarUltimosDiezDocumentos({
      nmId: userAttributes.codigoEmisor,
    }).then((result) => {
      this.props.dispatch(SetSpinnerModal(false));
      if (result.status === 200) {
        let resultUltimosDocumentos = result.data.map((element) => {
          let documento = JSON.parse(element.jsonDocumento);
          return {
            ...element,
            resolucion: documento.dsResolucionDian,
            nombresAdquiriente: documento.nombresAdquiriente,
          };
        });
        ultimosDocumentos.lista = resultUltimosDocumentos;
        this.setState({ ultimosDocumentos });
      } else {
        this.mensajes(false, result.data.msn, this.mensajes, true);
      }
    });
    this.props.dispatch(SetOpenModalGenerico(true));
  };

  mensajes = (close, texto, onClick, parametro) => {
    let modal;
    if (close) {
      modal = { open: false };
      this.props.dispatch(SetConfirmacionModalState(modal));
      return;
    }

    if (parametro != null) {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(parametro),
        cancelarVisible: true,
      };
    } else {
      modal = {
        open: true,
        text: texto,
        onClick: () => onClick(),
        cancelarVisible: true,
      };
    }

    this.props.dispatch(SetConfirmacionModalState(modal));
  };

  componentDidUpdate() {
    this.handleConsultarRangos();
    this.validarConsecutivos();
  }

  validarConsecutivos = () => {
    const { dsPrefijo, dsResolucionDian, consultado } = this.state;
    const { userAttributes, dispatch } = this.props;
    if (dsResolucionDian && userAttributes && !consultado) {
      this.setState({ consultado: true }, () => {
        ApiResolucion.validarConsecutivos({
          resolucion: dsResolucionDian,
          prefijo: dsPrefijo,
          emisor: userAttributes.codigoEmisor,
        }).then(({ data }) => {
          if (data.length > 0) {
            const redirect = data.filter((error) => error === "SIN_RESOLUCION" || error === "RESOLUCION_VENCIDA").length > 0;
            dispatch(
              SetConfirmacionModalState({
                open: true,
                text: data.map((error) => erroresConsecutivos[error]).join(","),
                onClick: redirect
                  ? () => (window.location.href = "/gestionarResolucionesEmisor")
                  : () => this.setState({ dsNumeroFactura: "" }, () => dispatch(SetConfirmacionModalState({ open: false }))),
                cancelarVisible: true,
              })
            );
          }
        });
      });
    }
  };

  handleConsultarRangos = () => {
    const { userAttributes } = this.props;
    if (userAttributes.codigoEmisor && !this.state.resolucionesConsultadas) {
      ApiResolucion.getResolucionesVigentes(userAttributes.codigoEmisor).then((res) => {
        let resoluciones = res.data;
        if (resoluciones) {
          let textoResolucionesVencidas = "";
          resoluciones.forEach((resolucion) => {
            resolucion.prefijos.forEach((prefijo) => {
              getDateFromArray(prefijo.feVencimiento);
              if (moment().add(30, "days") >= getDateFromArray(prefijo.feVencimiento)) {
                textoResolucionesVencidas +=
                  `El prefijo ${prefijo.dsPrefijo} de la resolucion ${prefijo.dsResolucionDian} esta proximo a vencerse` + " - ";
              }
            });
          });
          if (textoResolucionesVencidas)
            this.props.dispatch(
              SetConfirmacionModalState({
                open: true,
                text: textoResolucionesVencidas,
                onClick: () => this.props.dispatch(SetConfirmacionModalState({ open: false })),
              })
            );
        }
        resoluciones &&
          resoluciones.forEach((resolucion) =>
            resolucion.prefijos.forEach((prefijo) => ((prefijo.dsPrefijo == prefijo.dsPrefijo) === "" ? sinPrefijo : prefijo.dsPrefijo))
          );
        this.setState(
          {
            resolucionesConsultadas: resoluciones,
            resolucionPrincipal:
              resoluciones &&
              resoluciones.map((resolucion) => resolucion.prefijos.filter((prefijo) => prefijo.snPrincipal === "S")[0]).filter((item) => item)[0],
          },
          () =>
            this.state.resolucionPrincipal &&
            this.setState(
              {
                defaultValueDsResolucion: {
                  label: this.state.resolucionPrincipal.dsResolucionDian,
                  value: this.state.resolucionPrincipal.dsResolucionDian,
                },
                defaultValueDsPrefijo: {
                  label: this.state.resolucionPrincipal.dsPrefijo,
                  value: this.state.resolucionPrincipal.dsPrefijo,
                },
              },
              () => {
                this.handleChangeResolucionEmisor({
                  value: this.state.resolucionPrincipal.dsResolucionDian,
                });
                this.setState(
                  {
                    dsPrefijo: this.state.resolucionPrincipal.dsPrefijo,
                  },
                  () => {
                    const urlParams = new URLSearchParams(window.location.search);
                    this.handleChangeTipoDocto({
                      value: urlParams.get("tipo"),
                    });
                  }
                );
              }
            )
        );
      });
    }
  };

  render() {
    const { tiposDocumentosValidacionPrevia, open, listaFacturasReferencia } = this.props;

    const {
      dsPrefijo,
      dsNumeroFactura,
      listaPrefijos,
      unicoPrefijo,
      prefijoDisable,
      ultimosDocumentos,
      openDocumentoAdicionalModal,
      resolucionesConsultadas,
      resolucionPrincipal,
      snConsecutivoAutomatico,
      tipoDocumentoElectronico,
      defaultValueDsResolucion,
      defaultValueDsPrefijo,
    } = this.state;
    const urlParams = new URLSearchParams(window.location.search);

    let tiposDocumentoSelect = [];
    tiposDocumentosValidacionPrevia.map((item) => {
      tiposDocumentoSelect.push({ value: item.idTipo, label: item.nombre });
    });
    tiposDocumentoSelect = ordenarArray(tiposDocumentoSelect);

    let listaPrefijosSelect = ordenarArray(
      listaPrefijos.map((item) => ({
        value: item.dsPrefijo,
        label: item.dsPrefijo,
      }))
    );
    let listaResoluciones =
      resolucionesConsultadas &&
      ordenarArray(
        resolucionesConsultadas.map((resolucion) => ({
          value: resolucion.dsResolucionDian,
          label: resolucion.dsResolucionDian,
        }))
      );

    if (resolucionPrincipal && !this.state.primeraCarga) {
      this.handleChangeResolucionEmisor({
        value: resolucionPrincipal.dsResolucionDian,
      });
      this.setState(
        {
          dsResolucionDian: resolucionPrincipal.resolucionDian,
          resolucionDian: resolucionPrincipal.resolucionDian,
          dsPrefijo: resolucionPrincipal.dsPrefijo ? resolucionPrincipal.dsPrefijo : "",
          dsNumeroFactura: "",
          numeroInicial: resolucionPrincipal.nmInicio,
          numeroFinal: resolucionPrincipal.nmFin,
          primeraCarga: true,
          snConsecutivoAutomatico: resolucionPrincipal.snConsecutivoAutomatico,
        },
        () => this.props.dispatch(SetDatosDocumento({ ...this.state }))
      );
    }

   

    return (
      <div className="justify-content-center align-items-center spacing-head" autoComplete="off" style={{marginTop: "50px"}}>
        <div className="row col-10">
          <div className="col-lg-4" style={{ textAlign: "center" }}>
          <label style={styles.fontFamily} className="titulos_label">Resolución *</label>
            {listaResoluciones && defaultValueDsResolucion ? (
              <Select
                defaultValue={defaultValueDsResolucion}
                onChange={this.handleChangeResolucionEmisor.bind(this)}
                options={listaResoluciones}
                placeholder="Seleccione un tipo"
              />
            ) : (
              <div className="col-lg-4">
                <div style={{ textAlign: "center" }} class="spinner-border text-primary" />
              </div>
            )}
            </div>
          <div className="col-lg-4" style={{ textAlign: "center" }}>
          <label style={styles.fontFamily} className="titulos_label">Prefijo</label>
          {!unicoPrefijo ? (
            <div>
              {listaResoluciones && defaultValueDsPrefijo ? (
                <Select
                  defaultValue={defaultValueDsPrefijo}
                  onChange={this.handleChangePrefijo.bind(this)}
                  options={listaPrefijosSelect}
                  placeholder="Seleccione un prefijo"
                  isDisabled={unicoPrefijo}
                />
              ) : (
                <div className="col-lg-4">
                  <div style={{ textAlign: "center" }} class="spinner-border text-primary" />
                </div>
              )}
            </div>
          ) : (
            <div className="form-group">
              <input
                maxLength="10"
                autoComplete="off"
                type="text"
                id="dsPrefijo"
                className="form-control form-control-md"
                value={dsPrefijo}
                onKeyPress={this.handleKeyPressTextoNumeros}
                onChange={this.handleChange}
                disabled={prefijoDisable}
                required
              />
            </div>
          )}
          </div>
          <div className="col" style={{ textAlign: "center" }}>
          <label style={styles.fontFamily} className="titulos_label">Número Documento *</label>
          <div className="form-group">
            <input
              maxLength="50"
              autoComplete="off"
              onBlur={this.ValidarRangoFacturacionOnBlur && this.consultarDocumentoExistente}
              disabled={snConsecutivoAutomatico === "S" && (tipoDocumentoElectronico === "VENTA" || tipoDocumentoElectronico === "EXPORTACION")}
              type="text"
              id="dsNumeroFactura"
              className="form-control form-control-md"
              value={dsNumeroFactura}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              required
            />
          </div>
          </div>
        </div>
      </div>  
      
    );
  }
}

function mapStateToProps(state, props) {
  return {
    open: state.factura.openModalFacturasReferencia,
    openModalDocumentosAdicionales: state.factura.openModalDocumentosAdicionales,
    tiposDocumentosValidacionPrevia: state.factura.tiposDocumentosValidacionPrevia,
    listaDetalleFactura: state.factura.listaDetalleFactura,
    listaFacturasReferencia: state.factura.listaFacturasReferencia,
    listaDocumentosAdicionales: state.factura.listaDocumentosAdicionales,
    userAttributes: state.factura.userAttributes,
  };
}

export default connect(mapStateToProps)(DatosDocumento);
