import * as React from "react";
import { connect } from "react-redux";
import Button from 'components/atoms/Button';
import Input from "components/Inputs/Input";
import CheckBox from 'components/molecules/Checkbox';
import Select from "components/Inputs/Select";
import LabelTitulos from "components/layout/labelTitulos";
import { consultarCategoriasProductosPorEmisor as consultarCategoriasEmisor } from "network/Api/Producto";

const tipoOperacionesAjuste = [
  { value: "A", label: "Ajuste de Inventario" },
  { value: "C", label: "Compra" },
];

let porcentajesIva = [];
let porcentajesInc = [];
let unidadesMedida = [];
let preciosReferencias = [];

const Formulario = ({
  handleClose,
  productoUpdate = { nmPorcentajeIva: 19, nmPorcentajeInc: 0 },
  rawPorcentajesIVA = [],
  rawPorcentajesINC = [],
  rawUnidadesMedida = [],
  rawPreciosReferencia = [],
  handleSave,
  openModal,
  nmIdEmisor,
  handleSaveMovimiento,
}) => {
  const [producto, setDataProducto] = React.useState(productoUpdate);
  const [categorias, setCategorias] = React.useState([]);

  React.useEffect(() => {
    porcentajesIva = getList(rawPorcentajesIVA);
    porcentajesInc = getList(rawPorcentajesINC);
    unidadesMedida = getList(rawUnidadesMedida);
    preciosReferencias = getList(rawPreciosReferencia);
    if (nmIdEmisor) consultarCategorias();
  }, [
    rawPorcentajesIVA,
    rawPorcentajesINC,
    rawUnidadesMedida,
    rawPreciosReferencia,
    nmIdEmisor,
  ]);

  const consultarCategorias = async () => {
    const { data = [] } = await consultarCategoriasEmisor(nmIdEmisor);
    setCategorias(data.filter((d) => d).map((d) => ({ label: d, value: d })));
  };

  const getList = (array) =>
    array.map(({ codigo: value, nombre: label }) => ({ label, value }));

  const setProducto = (data) => setDataProducto((p) => ({ ...p, ...data }));

  const handleChange = ({ target }) => {
    const { name, value = "", checked } = target;
    if (name === "manejoInventario")
      setProducto({ [name]: checked ? "S" : "N" });
    else if (name === "dsCodigo")
      setProducto({ [name]: value.replace(/ /g, "") });
    else setProducto({ [name]: value });
  };

  const handleChangeSelect = ({ value }, name) => {
    setProducto({ [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const errores = validarProducto();
    if (errores.length === 0) {
      handleSave(producto);
    } else
      openModal({
        open: true,
        text: errores.join(", "),
        onClick: () => {
          openModal({ open: false });
        },
      });
  };

  const validarProducto = () => {
    const { nmUnidadMedida, nmValorUnitario } = producto;
    let errores = [];
    if (!nmUnidadMedida) errores.push("Debe seleccionar una unidad de medida");
    if (!nmValorUnitario) errores.push("Debe ingresar un valor unitario");
    return errores;
  };

  const findSelect = (options, value) =>
    options.find((option) => option.value === value);

  return (
    <form onSubmit={onSubmit}>
      <div className="form-row col-md-12">
        <div className="form-group col-md-12">
          <LabelTitulos texto="Información producto" />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-4">
          <Input
            label="Código *"
            value={producto.dsCodigo}
            name="dsCodigo"
            onChange={handleChange}
            maxLength="25"
            data-validation="only-num-text"
            required
          />
        </div>
        <div className="form-group col-md-4">
          <Input
            label="Descripción *"
            value={producto.dsDescripcion}
            name="dsDescripcion"
            onChange={handleChange}
            maxLength="298"
            required
          />
        </div>
        <div className="form-group col-md-2">
          <Input
            label="Valor Unitario *"
            value={producto.nmValorUnitario}
            name="nmValorUnitario"
            onChange={handleChange}
            data-validation="only-num-decimals"
          />
        </div>
        <div className="form-group col-md-2 d-flex justify-content-center align-items-center">
          <CheckBox
            label="Iva Incluido"
            name="snIvaIncluido"
            checked={producto.snIvaIncluido}
            onChange={({ target }) =>
              setProducto({ snIvaIncluido: target.checked })
            }
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-2">
          <Select
            label="Porcentaje IVA"
            name="nmPorcentajeIva"
            placeholder="% IVA"
            options={porcentajesIva}
            value={findSelect(porcentajesIva, producto.nmPorcentajeIva)}
            onChange={(e) => handleChangeSelect(e, "nmPorcentajeIva")}
          />
        </div>
        <div className="form-group col-md-2">
          <Select
            label="Porcentaje INC"
            name="nmPorcentajeInc"
            placeholder="% INC"
            options={porcentajesInc}
            value={findSelect(porcentajesInc, producto.nmPorcentajeInc)}
            onChange={(e) => handleChangeSelect(e, "nmPorcentajeInc")}
          />
        </div>
        <div className="form-group col-md-4">
          <Select
            label="Unidad de medida *"
            name="nmUnidadMedida"
            placeholder="Seleccione una unidad"
            options={unidadesMedida}
            value={findSelect(unidadesMedida, producto.nmUnidadMedida)}
            onChange={(e) => handleChangeSelect(e, "nmUnidadMedida")}
          />
        </div>
        <div className="form-group col-md-2">
          <Input
            label="Nueva Categoria"
            value={producto.cdCategoria}
            name="cdCategoria"
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-2">
          <Select
            label="Categoría Existente"
            placeholder="Seleccione una categoria"
            name="cdCategoria"
            options={categorias}
            value={findSelect(categorias, producto.cdCategoria)}
            onChange={(e) => handleChangeSelect(e, "cdCategoria")}
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-4 text-center  d-flex justify-content-center align-items-center">
          <label>Si el producto es de exportación ingrese:</label>
        </div>
        <div className="form-group col-md-4">
          <Input
            label="Marca"
            value={producto.nmMarca}
            name="nmMarca"
            onChange={handleChange}
            maxLength="100"
            data-validation="only-num-text"
          />
        </div>
        <div className="form-group col-md-4">
          <Input
            label="Modelo"
            value={producto.nmModelo}
            name="nmModelo"
            onChange={handleChange}
            maxLength="100"
            data-validation="only-num-text"
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-4 text-center d-flex justify-content-center align-items-center">
          <label>Si el producto es un obsequio ingrese:</label>
        </div>
        <div className="form-group col-md-4">
          <Select
            label="Tipo precio referencia"
            name="dsTipoPrecioref"
            placeholder="Tipo precio referencia"
            options={preciosReferencias}
            value={findSelect(preciosReferencias, producto.dsTipoPrecioref)}
            onChange={(e) => handleChangeSelect(e, "dsTipoPrecioref")}
          />
        </div>
        <div className="form-group col-md-4">
          <Input
            label="Precio referencia"
            value={producto.dsPrecioReferencia}
            name="dsPrecioReferencia"
            onChange={handleChange}
            maxLength="15"
            data-validation="only-num-decimals"
          />
        </div>
      </div>
      {productoUpdate.nmId && (
        <div className="form-row col-md-12">
          <div className="form-group col-md-12">
            <LabelTitulos texto="Movimiento Inventario" />
          </div>
        </div>
      )}
      <div className="form-row col-md-12">
        <div className="form-group col-md-4 d-flex justify-content-center align-items-center">
          <CheckBox
            label="Seleccione si el producto maneja inventario:"
            name="manejoInventario"
            checked={producto.manejoInventario === "S"}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-4">
          <Select
            label="Seleccione la operación"
            name="dsTipoMovimiento"
            placeholder="Seleccione la operacion que desea realizar"
            isDisabled={producto.manejoInventario !== "S"}
            options={tipoOperacionesAjuste}
            value={findSelect(tipoOperacionesAjuste, producto.dsTipoMovimiento)}
            onChange={(e) => handleChangeSelect(e, "dsTipoMovimiento")}
          />
        </div>
        <div className="form-group col-md-4">
          <Input
            label="Cantidad"
            value={producto.nmCantidad}
            name="nmCantidad"
            onChange={handleChange}
            disabled={producto.manejoInventario !== "S"}
            data-validation="only-num-decimals"
          />
        </div>
      </div>
      <div className="form-row col-md-12">
        <div className="form-group col-md-8" />
        <div className="form-group col-md-2">
          {productoUpdate.nmId && (
            <Button
              onClick={handleSaveMovimiento}
              disabled={producto.manejoInventario !== "S"}
            >
              Registrar Movimiento
            </Button>
          )}
        </div>
        <div className="form-group col-md-1">
          <Button type="submit">
            {productoUpdate.nmId ? "Actualizar" : "Guardar"}
          </Button>
        </div>
        <div className="form-group col-md-1">
          <Button onClick={handleClose}>Cerrar</Button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, props) => ({
  rawPorcentajesIVA: state.factura.tiposPorcentajesIVA,
  rawPorcentajesINC: state.factura.tiposPorcentajesICO,
  rawUnidadesMedida: state.factura.tiposUnidadMedida,
  rawEstandarProducto: state.factura.tiposEstandarProducto,
  rawPreciosReferencia: state.factura.tiposPrecioReferencia,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Formulario);
