import { serviceRoutes, get, post } from "./index";

export async function consultarReporteInventario({
  id,
  articulo,
  inicio,
  fin,
}) {
  return await get(
    serviceRoutes.consultarReporteInventario,
    `?id=${id}&articulo=${articulo}&inicio=${inicio}&fin=${fin}`
  );
}

export const registrarAjusteInventario = async (body) =>
  await post(serviceRoutes.registrarAjusteInventario, body);
