import React, { useEffect, useState } from 'react';
import { consultarCiudades } from 'network/Api/Ciudad';
import Select from 'components/molecules/Select';

function SelectCiudades({ label = 'Ciudad', value, onChange, ...rest }) {
  const [ciudades, setCiudades] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    consultarCiudades().then(({ data }) => {
      setCiudades(data);
      setOptions(
        data.map((c) => ({
          label: `${c.dsNombre} - ${c.departamento.dsNombre} - ${c.departamento.pais.dsNombre}`,
          value: c.nmId,
        })),
      );
    });
  }, []);

  const onChangeCiudad = (event) => {
    const ciudad = ciudades.find((c) => c.nmId === event.value);
    onChange(event, ciudad);
  };

  return options.length > 0 ? (
    <Select label={label} options={options} value={value} onChange={onChangeCiudad} {...rest} />
  ) : (
    <Select disabled label={label} isLoading />
  );
}

export default SelectCiudades;
