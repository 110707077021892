import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Paper, Button, Tooltip, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import TablaSort from 'components/Tablas/GenericaOrdenadaColumna';
import {
  consultarDocumentosEnviados,
  consultarDocumentoDomina,
  generarReporteExcel,
  reenviarEmailDocumento,
} from 'network/Api/Documento';
import { moneyFormat } from 'utils/TextFormattingUtils';
import { formatDate } from 'utils/DateUtils';
import iconPdf from 'icons/pdf.png';
import sendIcon from 'icons/send.png';
import ExportExcelIco from 'static/images/excel.png';
import ModalForm from 'components/modal/ModalConfirmacion';
import Input from 'components/Inputs/Input';
import { TIPOS_DATOS } from 'utils/ValidarDatos';
import moment from 'moment';
import Select from 'components/Inputs/Select';
import {
  consultarDocumentosSoporte,
  reenviarDocumentoSoporte,
  generarExcelDocumentosSoporte,
} from 'network/Api/DocumentoSoporte';
import VisualizacionDocumento from './VisualizacionDocumento';
import DocSoporte from 'static/images/VerDocSoporte.png';
import NotaModal from './NotaModal';
import Modal from 'components/modal/ModalConfirmacion';

const optionsFiltro = [
  { label: 'Documentos Soporte', value: 'SOPORTE' },
  { label: 'Documentos Facturacion', value: 'FACTURACION' },
  { label: 'Documentos POS Electronico', value: 'POS' },
];

function DocumentosEnviados({ emisorFacturasEnviadas, emisor, identificacion, token }) {
  const [state, setState] = useState({
    feFin: moment().format('YYYY-MM-01'),
    feInicio: moment().format('YYYY-MM-DD'),
    loadingTable: false,
    tipoConsulta: 'FACTURACION',
    prefijo: '',
    numero: '',
  });
  const [modal, setModal] = useState({});

  useEffect(() => {
    if (emisorFacturasEnviadas.codigoEmisor || emisor) consultarDocumentos(false);
  }, [emisorFacturasEnviadas, emisor]);

  useEffect(() => {
    if (emisorFacturasEnviadas.codigoEmisor || emisor) consultarDocumentos(true);
  }, [state.tipoConsulta]);

  useEffect(() => {
    setState({
      tipoConsulta: new URLSearchParams(window.location.search).get('tipo'),
      feFin: moment().format('YYYY-MM-01'),
      feInicio: moment().format('YYYY-MM-DD'),
      openModalFilter: true,
    });
  }, []);

  function consultarDocumentos(openModalFilter) {
    setState((s) => ({
      ...s,
      openModalFilter,
      documentos: null,
      loadingTable: true,
    }));
    const body = {
      emisor: emisorFacturasEnviadas.codigoEmisor || emisor,
      feFin: `${state.feInicio} 23:59:59`,
      feInicio: `${state.feFin} 00:00:00`,
      adquirente: state.identificacion || '',
      prefijo: state.prefijo,
      numero: state.numero,
      isPos: state.tipoConsulta === 'POS',
    };
    (state.tipoConsulta !== 'SOPORTE'
      ? consultarDocumentosEnviados(body)
      : consultarDocumentosSoporte(body)
    ).then((r) =>
      setState((s) => ({
        ...s,
        documentos: state.tipoConsulta !== 'SOPORTE' ? r : r.data,
        loadingTable: false,
      })),
    );
  }

  function getEstado(code) {
    switch (code) {
      case '000':
        return 'Pendiente';
      case '001':
        return 'En proceso';
      case '002':
        return 'Enviado DIAN';
      case '003':
        return 'Enviado adquirente';
      case '005':
        return 'Email leido por adquirente';
      case '006':
        return 'Aprobado por adquirente';
      case '007':
        return 'Rechazado por adquirente';
      case '00401':
        return 'Error en las validaciones del documento';
      case '702':
        return 'Error';
      case '703':
        return 'Saldo insuficiente';
      case '00402':
        return 'Error en la conexión al servicio de la DIAN';
      case '00403':
        return 'Rechazado por DIAN';
      case '00404':
        return 'Documento no se encuentra en domina';
      case '00405':
        return 'Filtro de busqueda invalido';
      case '00406':
        return 'Error enviando E-mail.';
      case 'EXITOSO':
        return 'Enviado correctamente';
      case 'ERRORDIAN':
        return 'Error dian';
      case 'ERROR':
        return 'Error validaciones';
      default:
        return 'Desconocido';
    }
  }

  function getTipoDocumento(type) {
    switch (type) {
      case 'NOTA_DEBITO':
        return 'Nota débito';
      case 'NOTA_CREDITO':
        return 'Nota crédito';
      case 'VENTA':
        return 'Factura de venta';
      case 'EXPORTACION':
        return 'Factura de exportación';
      case 'SOPORTE_ADQUISICION':
        return 'Soporte Adquisicion';
      case 'NOTA_CREDITO_SOPORTE_ADQUISICION':
        return 'Nota Credito Soporte Adquisicion';
      default:
        return 'Otro';
    }
  }

  function isPdfAndEmail(estado) {
    return (
      estado === '002' ||
      estado === '003' ||
      estado === '005' ||
      estado === '006' ||
      estado === 'EXITOSO'
    );
  }

  function openFilterModal() {
    setState((s) => ({ ...s, openModalFilter: true }));
  }

  const descargarDocumento = async (id) => {
    const { data } = await consultarDocumentoDomina(id);
    const { pdf } = data;
    if (pdf.includes('http')) window.open(pdf);
    else
      setState((s) => ({
        ...s,
        openMessage: true,
        contentMessage:
          'Estamos generando tu factura electrónica, en unos minutos la enviaremos a los correos definidos.',
      }));
  };

  function reenviarCorreo() {
    const { emailValue, dsNumeroFactura, dsPrefijo } = state;
    reenviarEmailDocumento({
      body: { dsPrefijo, dsNumeroFactura, dsEmail: emailValue },
      emisor: emisorFacturasEnviadas.codigoEmisor || emisor,
    }).then(({ data }) => {
      if (data.estado.codigo === 'EXITOSO')
        setState((s) => ({ ...s, openModal: false, emailValue: '' }));
      else alert('Error reenviando correos');
    });
  }

  function handleChange(event) {
    const label = event.target.name;
    const value = event.target.value;
    switch (label) {
      default:
        setState((s) => ({ ...s, [label]: value }));
        break;
    }
  }

  const reenviarDocumentosSoporte = (documento) => {
    setState((s) => ({ ...s, disableReenvio: true }));
    reenviarDocumentoSoporte(documento.nmId).then((res) => {
      consultarDocumentos(false);
      setState((s) => ({ ...s, disableReenvio: false }));
    });
  };

  const descargarExcel = () => {
    const body = {
      emisor: emisorFacturasEnviadas.codigoEmisor || emisor,
      feFin: `${state.feInicio} 23:59:59`,
      feInicio: `${state.feFin} 00:00:00`,
      adquirente: state.identificacion || '',
      prefijo: state.prefijo,
      numero: state.numero,
    };
    (state.tipoConsulta !== 'SOPORTE'
      ? generarReporteExcel(body)
      : generarExcelDocumentosSoporte(body)
    ).then((blob) => descargar({ blob }));
  };

  const descargar = ({ blob }) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const pdf = new Blob([blob], { type: 'application/xlsx' });
    const url = URL.createObjectURL(pdf);
    a.href = url;
    a.id = `excelDocumento`;
    a.download = `documentosEnviados.xlsx`;
    a.click();
  };

  const openModal = (content) =>
    setModal({
      open: true,
      content,
      onAccept: closeModal,
    });

  const closeModal = () => setModal((m) => ({ ...m, open: false }));

  const getTipoNota = (tipoDocumento) => {
    if (tipoDocumento === 'SOPORTE_ADQUISICION') return 'NOTA_CREDITO_SOPORTE';
    if (tipoDocumento === 'VENTA') return 'NOTA_CREDITO';
    if (tipoDocumento === 'EXPORTACION') return 'NOTA_CREDITO';
    if (tipoDocumento === 'NOTA_CREDITO') return 'NOTA_DEBITO';
  };

  return (
    <>
      <div
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip title='Filtro de lista'>
          <Button aria-label='Filtro de lista' onClick={openFilterModal}>
            <Typography variant='h6'>Filtro</Typography>
            <FilterListIcon />
          </Button>
        </Tooltip>
        <Tooltip title='Descargar Excel'>
          <Button aria-label='Descargar Excel' onClick={descargarExcel}>
            <img alt='exportarExcel' src={ExportExcelIco} />
          </Button>
        </Tooltip>
      </div>
      <Paper>
        <TablaSort
          loading={state.loadingTable}
          order={'desc'}
          orderBy={'fechaEmision'}
          headers={[
            { id: 'btnDownload' },
            { id: 'btnReenviar' },
            { id: 'tipoDocumento', label: 'Tipo de documento' },
            { id: 'prefijo', label: 'Prefijo documento' },
            { id: 'numeroDocumento', label: 'No. documento' },
            { id: 'idAdquirente', label: 'Identificación adquirente' },
            { id: 'nombreAdquirente', label: 'Nombre adquirente' },
            { id: 'fechaEmision', label: 'Fecha Emisión' },
            { id: 'valorFactura', label: 'Valor Neto Factura' },
            { id: 'valorImpuestos', label: 'Valor Impuestos' },
            { id: 'estadoDocumento', label: 'Estado documento' },
            { id: 'btnNotacredito', label: 'Generar Nota' },
          ]}
          keys={[
            'btnDownload',
            'btnReenviar',
            'tipoDocumento',
            'prefijo',
            'numeroDocumento',
            'idAdquirente',
            'nombreAdquirente',
            'fechaEmision',
            'valorFactura',
            'valorImpuestos',
            'estadoDocumento',
            'btnNotacredito',
          ]}
          detalles={
            Array.isArray(state.documentos) &&
            state.documentos.map((documento) => {
              const jsonDocumento = JSON.parse(documento.dsJsonDocumento);
              const errores = (
                (JSON.parse(documento.dsResponse || '{}').estado || {}).errores || []
              ).join(', ');
              return {
                btnDownload:
                  state.tipoConsulta !== 'SOPORTE' ? (
                    isPdfAndEmail(documento.cdEstado) && (
                      <Button onClick={() => descargarDocumento(documento.nmId)}>
                        <img src={iconPdf} alt='' width='50' height='50' />
                      </Button>
                    )
                  ) : (
                    <Button
                      onClick={() =>
                        setState((s) => ({
                          ...s,
                          openModalVisualizacion: true,
                          documentoJson: documento.dsJsonDocumento,
                          response: JSON.parse(documento.dsResponse),
                        }))
                      }
                    >
                      <img src={DocSoporte} alt='' width='50' height='50' />
                    </Button>
                  ),
                btnReenviar:
                  isPdfAndEmail(documento.cdEstado) && state.tipoConsulta !== 'SOPORTE' ? (
                    <Button
                      onClick={() =>
                        setState((s) => ({
                          ...s,
                          dsPrefijo: documento.dsPrefijo,
                          dsNumeroFactura: documento.dsNumeroFactura,
                          openModal: true,
                        }))
                      }
                    >
                      <img src={sendIcon} alt='' width='50' height='50' />
                    </Button>
                  ) : (
                    state.tipoConsulta === 'SOPORTE' &&
                    documento.cdEstado !== 'EXITOSO' && (
                      <Button
                        onClick={() => reenviarDocumentosSoporte(documento)}
                        disabled={state.disableReenvio}
                      >
                        Reenviar
                      </Button>
                    )
                  ),
                tipoDocumento: getTipoDocumento(
                  jsonDocumento.tipoDocumentoElectronico || jsonDocumento.tipo,
                ),
                prefijo: documento.dsPrefijo,
                numeroDocumento: documento.dsNumeroFactura || documento.dsNumero,
                idAdquirente:
                  jsonDocumento.identificacionAdquiriente || jsonDocumento.identificacionVendedor,
                nombreAdquirente: `${jsonDocumento.nombresAdquiriente || jsonDocumento.nombresVendedor || ''} ${
                  jsonDocumento.segundoNombre || ''
                } ${jsonDocumento.primerApellido || ''} ${jsonDocumento.segundoApellido || ''}`,
                fechaEmision: formatDate(jsonDocumento.fechaEmision),
                valorFactura: moneyFormat(jsonDocumento.valorNeto),
                estadoDocumento: `${getEstado(documento.cdEstado)} ${errores ? '- ' + errores : ''}`,
                valorImpuestos: moneyFormat(
                  jsonDocumento.valorBrutoMasTributos - jsonDocumento.valorBruto,
                ),
                btnNotacredito: documento.cdEstado === 'EXITOSO' &&
                  (jsonDocumento.tipoDocumentoElectronico === 'VENTA' ||
                    jsonDocumento.tipoDocumentoElectronico === 'SOPORTE_ADQUISICION' ||
                    jsonDocumento.tipoDocumentoElectronico === 'NOTA_CREDITO' ||
                    jsonDocumento.tipoDocumentoElectronico === 'EXPORTACION') && (
                    <Button
                      onClick={() =>
                        setState((s) => ({
                          ...s,
                          nmIdNota: documento.nmId,
                          prefijoFactura: documento.dsPrefijo,
                          tipoNota: getTipoNota(jsonDocumento.tipoDocumentoElectronico),
                        }))
                      }
                      variant='contained'
                      color='primary'
                      disabled={
                        jsonDocumento.tipoDocumentoElectronico !== 'SOPORTE_ADQUISICION' &&
                        documento.snNotaGenerada
                      }
                    >
                      {(jsonDocumento.tipoDocumentoElectronico === 'VENTA' ||
                        jsonDocumento.tipoDocumentoElectronico === 'EXPORTACION' ||
                        jsonDocumento.tipoDocumentoElectronico === 'SOPORTE_ADQUISICION') &&
                        'Anular Documento'}
                      {jsonDocumento.tipoDocumentoElectronico === 'NOTA_CREDITO' &&
                        'Anular Nota Credito'}
                    </Button>
                  ),
              };
            })
          }
        />
      </Paper>
      <ModalForm
        open={state.openModal}
        fullWidth={true}
        title='Enviar a:'
        onAccept={TIPOS_DATOS.email.regExp.test(state.emailValue) && reenviarCorreo}
        onCancel={() => setState({ ...state, openModal: false })}
        content={
          <Input
            label='Email'
            value={state.emailValue || ''}
            error={!TIPOS_DATOS.email.regExp.test(state.emailValue) && 'Email Invalido'}
            name='emailValue'
            onChange={handleChange}
          />
        }
      />
      <ModalForm
        open={state.openModalFilter}
        fullWidth={true}
        title={'Filtrar búsqueda'}
        onAccept={() => consultarDocumentos(false)}
        onCancel={() => setState({ ...state, openModalFilter: false })}
        content={
          <div>
            <Select
              label='Tipo de documento'
              options={optionsFiltro}
              value={optionsFiltro.find(({ value }) => value === state.tipoConsulta)}
              onChange={({ value }) => setState((s) => ({ ...s, tipoConsulta: value }))}
            />
            <div>
              <Input
                label='Identificacion adquirente'
                value={state.identificacion}
                name={'identificacion'}
                margin={'5px'}
                onChange={handleChange}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Input
                label='Prefijo'
                margin={'5px'}
                name={'prefijo'}
                value={state.prefijo}
                onChange={handleChange}
              />
              <Input
                label='Numero'
                margin={'5px'}
                name={'numero'}
                value={state.numero}
                onChange={handleChange}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <Input
                label='Fecha incial'
                type='date'
                margin={'5px'}
                name={'feFin'}
                value={state.feFin}
                onChange={handleChange}
              />
              <Input
                label='Fecha final'
                type='date'
                margin={'5px'}
                name={'feInicio'}
                min={state.feFin}
                max={moment().add(10, 'days').format('YYYY-MM-DD')}
                value={state.feInicio}
                onChange={handleChange}
              />
            </div>
          </div>
        }
      />
      <ModalForm
        open={state.openModalVisualizacion}
        fullWidth={true}
        title={''}
        content={
          <VisualizacionDocumento
            documentoJson={state.documentoJson ? JSON.parse(state.documentoJson) : {}}
            response={state.response}
          />
        }
        maxWidth='xl'
        onCancel={() => setState((s) => ({ ...s, openModalVisualizacion: false }))}
      />
      <ModalForm
        open={state.openMessage}
        content={state.contentMessage}
        onCancel={() => setState((s) => ({ ...s, openMessage: false }))}
      />
      <NotaModal
        nmId={state.nmIdNota}
        onConsulta={() => consultarDocumentos(false)}
        onClose={() => setState((s) => ({ ...s, nmIdNota: null, tipoNota: null }))}
        openModal={openModal}
        tipo={state.tipoConsulta}
        prefijoFactura={state.prefijoFactura}
        tipoNota={state.tipoNota}
      />
      <Modal {...modal} />
    </>
  );
}

const mapStateToProps = (state, props) => {
  if (state.factura.emisorFacturasEnviadas) {
    return {
      emisorFacturasEnviadas: state.factura.emisorFacturasEnviadas,
    };
  }
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentosEnviados);
