/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from 'components/atoms/Button';
import { connect } from 'react-redux';
import { numberFormat, handleKeyPressDecimal, handleKeyPressNumeros } from 'utils/funcionesUtiles';
import DetalleFacturaAgregar from 'components/templates/ModalAgregarDetalle';
import {
  SetConfirmacionModalState,
  SetModalAddDetalleFactura,
  SetTotalesFactura,
  SetDetalleFactura,
} from 'reducers/actions/facturaActions';
import Add from '@material-ui/icons/AddCircle';
import {
  impuestosAdicionalesRetefuente,
  impuestosAdicionalesICA,
  impuestosAdicionalesReteIva,
} from 'components/FacturaV2/Utiles/ImpuestosAdicionalesEncabezado';
import { convertirALetras } from 'utils/valorEnLetras';
import Checkbox from 'components/molecules/Checkbox';
import {
  acumularCargosDescuentos,
  calcularTotal,
  acumularImpuestosRetenciones,
  calcularTotalNoGravado,
} from 'utils/DocumentoUtils';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 1,
    overflowX: 'scroll',
  },
  table: {
    minWidth: 600,
  },
  head: {
    backgroundColor: '#128BCC',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  headText: {
    color: theme.palette.common.white,
  },
  bodyTotales: {
    fontWeight: 'bold',
    backgroundColor: '#128bcc',
    color: theme.palette.common.black,
  },
  bodyDetalles: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  buttonAdd: {
    backgroundColor: 'rgb(18, 139, 204)',
    color: theme.palette.common.white,
  },
});

const porcentajeReteIva = 15;
let valorIvaTemp = 0;

function ccyFormat(num) {
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return numberFormat(value);
}

function formatearDecimales(num) {
  if (num == '') {
    num = 0;
  }
  num = parseFloat(num);
  let value = `${num.toFixed(2)}`;
  return value;
}

class DetalleFactura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      idItemBorrar: 0,
      porcentajeRetefuente: 0,
      valorRetefuente: 0,
      valorICA: 0,
      aplicarReteIva: false,
      valorReteIva: 0,
    };
    this.handleClickModal = this.handleClickModal.bind(this);
  }

  handleClickBorrar = (event) => {
    this.setState({ items: this.props.rows, idItemBorrar: event }, () => {
      let item = this.state.items.filter(function (item) {
        return item.id === event;
      });
      let modal = {
        open: true,
        text: `¿Borrar item ${item[0].codigoArticulo} ${item[0].descripcionArticulo} ?`,
        onClick: this.handleClickModal,
      };
      this.props.dispatch(SetConfirmacionModalState(modal));
    });
  };

  handleClickModal = () => {
    let { items, idItemBorrar } = this.state;
    let newItems = items.filter(function (item) {
      return item.id !== idItemBorrar;
    });
    this.props.dispatch(SetDetalleFactura(newItems));
    this.props.dispatch(SetConfirmacionModalState({ open: false }));
  };

  handleClickAdd = () => {
    const { datosDocumento } = this.props;
    if (Object.keys(datosDocumento).length > 0) {
      this.props.dispatch(SetModalAddDetalleFactura(true));
    }
  };

  handleChange = (event) => {
    const { rows } = this.props;
    if (rows.length == 0) return;
    if (event.target.value < 0) event.target.value = 0;
    if (event.target.value > 100) event.target.value = 100;
    if (event.target.value.split('.')[1] && event.target.value.split('.')[1].length > 2) return;
    this.setState({ porcentajeRetefuente: event.target.value });
  };

  handleChangeValorICA = (event) => {
    const { rows } = this.props;
    if (rows.length == 0) return;
    if (event.target.value < 0) event.target.value = 0;
    if (event.target.value > 100) event.target.value = 100;
    if (event.target.value.split('.')[1] && event.target.value.split('.')[1].length > 3) return;
    this.setState({ valorICA: event.target.value });
  };

  handleChangeReteIva = (event) => {
    const { rows } = this.props;
    if (rows.length == 0) {
      return;
    }
    if (event.target.checked) {
      let valorIva = parseFloat(event.target.value);
      let valorReteIva = (valorIva * porcentajeReteIva) / 100;
      this.setState({
        valorReteIva: valorReteIva,
        aplicarReteIva: event.target.checked,
      });
    } else {
      this.setState({ valorReteIva: 0, aplicarReteIva: event.target.checked });
    }
  };

  handleKeyPressDecimal = (event) => {
    if (!handleKeyPressDecimal(event)) {
      event.preventDefault();
    }
  };

  handleKeyPressNumeros = (event) => {
    if (!handleKeyPressNumeros(event)) {
      event.preventDefault();
    }
  };

  calcularReteIva = (valor_iva) => {
    const { rows } = this.props;
    if (this.state.aplicarReteIva === true) {
      if (rows.length == 0) {
        this.setState({ valorReteIva: 0, aplicarReteIva: false });
      } else {
        if (valorIvaTemp != valor_iva) {
          valorIvaTemp = valor_iva;
          let valorIva = parseFloat(valor_iva);
          let valorReteIva = (valorIva * porcentajeReteIva) / 100;
          this.setState({ valorReteIva: valorReteIva });
        }
      }
    }
  };

  render() {
    const { classes, rows, datosAdicionalesDocumento } = this.props;
    const { porcentajeRetefuente, valorICA, aplicarReteIva, valorReteIva } = this.state;

    const valorBruto = calcularTotal(rows, 'valorTotalBruto');
    const valorIva = calcularTotal(rows, 'valorIva');
    const valorIco = calcularTotal(rows, 'impuestoConsumo');
    const valorDescuento = calcularTotal(rows, 'descuentoTotal');
    const valorImpuestos = calcularTotal(rows, 'valorTotalImpuestosRetenciones');
    const otroImpuestoTotal = calcularTotal(rows, 'otroImpuestoTotal');
    const valorNoGravado = calcularTotalNoGravado(rows);
    this.calcularReteIva(valorIva);

    const cargosDescuentos = acumularCargosDescuentos(rows);
    const impuestosRetenciones = acumularImpuestosRetenciones(rows);

    const valorBaseImponible = formatearDecimales(valorBruto - valorDescuento - valorNoGravado);

    const valorParaRetencion = valorBruto - valorDescuento;
    const valorRetefuente = valorParaRetencion * (porcentajeRetefuente / 100);

    if (porcentajeRetefuente != 0) {
      impuestosRetenciones.push(
        impuestosAdicionalesRetefuente(valorParaRetencion, valorRetefuente, porcentajeRetefuente),
      );
    }

    if (valorICA != 0) {
      impuestosRetenciones.push(impuestosAdicionalesICA(valorBruto - valorDescuento, valorICA));
    }

    if (valorReteIva != 0) {
      impuestosRetenciones.push(
        impuestosAdicionalesReteIva(valorIva, valorReteIva, porcentajeReteIva),
      );
    }

    const valorTotal = valorBruto - valorDescuento + valorImpuestos;

    let valorEnLetras = convertirALetras(valorTotal, datosAdicionalesDocumento.moneda);

    const valorBrutoMasTributos = valorBruto + valorImpuestos;
    if (rows.length > 0) {
      this.props.dispatch(
        SetTotalesFactura({
          valorNeto: formatearDecimales(valorTotal),
          valorBrutoMasTributos: formatearDecimales(valorBrutoMasTributos),
          porcentajeIva: 0,
          dsPorcentajeReteIca: rows[0].porcentajeIca,
          porcentajeConsumo: 0,
          valorBaseImponible: valorBaseImponible,
          valorTotalImpuestoConsumo: formatearDecimales(valorIco),
          dsRetencionIca: formatearDecimales(valorICA),
          valorBruto: formatearDecimales(valorBruto),
          valorIva: valorIva,
          valorOtrosImpuestos: 0,
          valorNoGravado: formatearDecimales(valorDescuento + valorNoGravado),
          valorNetoLetras: valorEnLetras,
          dsRetencionFuente: formatearDecimales(valorRetefuente),
          descuentoTotal: formatearDecimales(valorDescuento),
          cargoTotal: formatearDecimales(0),
          dsRetencionIva: formatearDecimales(valorReteIva),
          impuestosRetenciones,
          cargosDescuentos,
          otroImpuestoTotal,
        }),
      );
    }

    return (
      <Fragment>
        <div className='form-group col-12'>
          <h4 className='titulo_principal'>Agregar nuevo ítem a la factura</h4>
        </div>
        <div style={{ marginTop: '30px' }}>
          <Button onClick={this.handleClickAdd} aria-label='Agregar'>
            <Add />
            Agregar
          </Button>
        </div>
        <Paper className={classes.root} style={{ marginTop: '30px' }}>
          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell className={classes.headText}>Código</TableCell>
                <TableCell className={classes.headText}>Descripción</TableCell>
                <TableCell className={classes.headText} align='right'>
                  Cantidad
                </TableCell>
                <TableCell className={classes.headText} align='right'>
                  Valor Unitario
                </TableCell>
                <TableCell className={classes.headText} align='right'>
                  %IVA
                </TableCell>
                <TableCell className={classes.headText} align='right'>
                  %INC
                </TableCell>
                <TableCell className={classes.headText} align='right'>
                  Descuento
                </TableCell>
                <TableCell className={classes.headText} align='right'>
                  Valor Total
                </TableCell>
                <TableCell className={classes.headText} align='right'>
                  Dia sin IVA
                </TableCell>
                <TableCell className={classes.headText} />
              </TableRow>
            </TableHead>

            <TableBody>
              {rows &&
                rows.map((row) => (
                  <TableRow className={classes.bodyDetalles} key={row.id}>
                    <TableCell>{row.codigoArticulo}</TableCell>
                    <TableCell>{row.descripcionArticulo}</TableCell>
                    <TableCell align='right'>{ccyFormat(row.cantidad)}</TableCell>
                    <TableCell align='right'>${ccyFormat(row.precioUnitario)}</TableCell>
                    <TableCell align='right'>{row.porcentajeIva}%</TableCell>
                    <TableCell align='right'>{row.porcentajeConsumo}%</TableCell>
                    <TableCell align='right'>${ccyFormat(row.valorTotalDescuento)}</TableCell>
                    <TableCell align='right'>${ccyFormat(row.valorTotalArticuloDetalle)}</TableCell>
                    <TableCell align='right'>
                      <Checkbox checked={row.esSinIva} disabled={true} />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          this.handleClickBorrar(row.id);
                        }}
                        aria-label='Delete'
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={12} />
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <div
          className='row col-md-12 justify-content-center align-items-center'
          style={{ marginTop: '30px' }}
        >
          <div
            className='row col-lg-10 col-xs-12 justify-content-center align-items-center'
            style={{ border: 'solid 2px #d1d1d1', display: 'flex', backgroundColor: '#F1F1F1' }}
          >
            <div
              className='form-row col-md-12 justify-content-center align-items-center'
              style={{ marginTop: '30px' }}
            >
              <div className='col-lg-2 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    Total Descuento
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>${ccyFormat(valorDescuento)}</div>
              </div>

              <div className='col-lg-2 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    Total bruto
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>${ccyFormat(valorBruto)}</div>
              </div>
              <div className='col-lg-2 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    Rete Iva (15%)
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>
                  <input
                    checked={aplicarReteIva}
                    value={valorIva}
                    onChange={this.handleChangeReteIva}
                    type='checkbox'
                    class='custom-control-input check'
                    id='aplicarReteIva'
                    style={{ Color: '#92C63E' }}
                  />
                  <label class='custom-control-label' for='aplicarReteIva'>
                    ${valorReteIva == '' ? '0.00' : ccyFormat(valorReteIva)}
                  </label>
                </div>
              </div>
              <div className='col-lg-2 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    Total IVA
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>${ccyFormat(valorIva)}</div>
              </div>
              <div className='col-lg-2 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    Total INC
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>${ccyFormat(valorIco)}</div>
              </div>
            </div>

            <div className='form-row col-md-12 justify-content-center align-items-center separador-line'>
              <div className='col-lg-3 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    <h4 className='titulo_principal'>Total neto</h4>
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>${ccyFormat(valorTotal)}</div>
              </div>

              <div className='col-lg-3 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    <h4 className='titulo_principal'>Total en letras</h4>
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>{convertirALetras(valorTotal)}</div>
              </div>
            </div>
            <div
              className='col-md-12 justify-content-center align-items-center'
              style={{ marginTop: '30px', marginBottom: '30px', display: 'flex' }}
            >
              <div className='col-lg-3 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    Porcentaje Rete ICA
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>
                  <input
                    value={valorICA}
                    min='0'
                    max='100'
                    placeholder='%'
                    onKeyPress={this.handleKeyPressDecimal}
                    onChange={this.handleChangeValorICA}
                    id='valorICA'
                    className='form-control'
                  />
                </div>
              </div>

              <div className='col-lg-3 text-center'>
                <div className='col-12'>
                  <label style={styles.fontFamily} className='titulos_label'>
                    Porcentaje Retefuente
                  </label>
                </div>
                <div className='col-lg-12 col-xs-12'>
                  <input
                    value={porcentajeRetefuente}
                    min='0'
                    max='100'
                    placeholder='%'
                    onKeyPress={this.handleKeyPressDecimal}
                    onChange={this.handleChange}
                    id='txtPorcentajeRetefuente'
                    className='form-control'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DetalleFacturaAgregar
          open={this.props.open}
          emisor={this.props.userAttributes}
          isExportacion={this.props.isFacturaExportacion}
          handleClose={() => this.props.dispatch(SetModalAddDetalleFactura(false))}
          addDetalle={(detalle) => {
            const { dispatch, rows } = this.props;
            dispatch(SetDetalleFactura([...rows, { ...detalle, id: rows.length }]));
          }}
        />
      </Fragment>
    );
  }
}

DetalleFactura.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    rows: state.factura.listaDetalleFactura,
    datosDocumento: state.factura.datosDocumento,
    datosCliente: state.factura.datosCliente,
    datosAdicionalesDocumento: state.factura.datosAdicionalesDocumento,
    isFacturaExportacion: state.factura.isFacturaExportacion,
    userAttributes: state.factura.userAttributes,
    open: state.factura.openModalDetalleFactura,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DetalleFactura));
